/***************************************************
 *  advancedInteriorPrompt.js
 *  Generates an interior prompt based on style, building levels, a specific floor,
 *  and the building type (to adjust the interior "Saudi style").
 ***************************************************/

// Global interior styles for different design preferences (fallback)
const interiorStyles = {
  modern: [
    "minimalist open-plan office space with glass partitions and sleek furniture",
    "contemporary workspace with clean lines and integrated technology",
    "modern office interior with collaborative zones and breakout areas",
  ],
  traditional: [
    "classic Arabian office design with ornate details and wooden panels",
    "traditional workspace with Islamic geometric patterns and warm colors",
    "heritage-inspired office interior with carved wooden elements",
  ],
  mixed: [
    "fusion office design combining modern amenities with traditional elements",
    "contemporary workspace with Arabian architectural details",
    "hybrid office interior balancing modern function with cultural aesthetics",
  ],
};

// Type-specific interior styles for various building types.
// Feel free to adjust or extend these arrays to suit your requirements.
const typeInteriorStyles = {
  سكني: {
    // Residential
    modern: [
      "modern residential interior with open-plan living areas, sleek furnishings, and integrated technology",
      "contemporary apartment interior with minimalist design, clean lines, and a bright ambiance",
    ],
    traditional: [
      "traditional residential interior with warm tones, intricate details, and culturally inspired decor",
      "heritage-inspired home interior with classic furnishings and artisanal accents",
    ],
    mixed: [
      "residential interior blending modern minimalism with traditional decorative elements",
    ],
  },
  تجاري: {
    // Commercial
    modern: [
      "modern office interior with glass partitions, sleek furniture, and high-tech elements",
      "contemporary corporate workspace with clean lines, open areas, and collaborative zones",
    ],
    traditional: [
      "traditional office interior with classic design elements, rich wood finishes, and cultural details",
    ],
    mixed: [
      "office interior combining modern efficiency with traditional design accents",
    ],
  },
  "تجاري سكني": {
    // Mixed-use
    modern: [
      "modern mixed-use interior with distinct zones for commercial and residential spaces",
      "contemporary mixed-use interior featuring sleek office areas and comfortable residential layouts",
    ],
    traditional: [
      "mixed-use interior combining traditional residential warmth with classic commercial style",
    ],
    mixed: [
      "interior blending modern workspaces with traditional living areas",
    ],
  },
  "مول تجاري": {
    // Shopping Mall
    modern: [
      "modern shopping mall interior with expansive retail spaces, sleek lighting, and dynamic storefronts",
    ],
    traditional: [
      "shopping mall interior with traditional design elements and cultural decorative motifs",
    ],
    mixed: [
      "mall interior blending modern architectural elements with traditional cultural accents",
    ],
  },
  فلل: {
    // Villas
    modern: [
      "modern villa interior featuring open-plan layouts, minimalist furnishings, and luxurious finishes",
    ],
    traditional: [
      "traditional villa interior with rich textures, classic decor, and cultural heritage accents",
    ],
    mixed: [
      "villa interior that harmoniously blends modern design with traditional aesthetics",
    ],
  },
  إداري: {
    // Administrative
    modern: [
      "modern administrative interior with formal workspaces, sleek design, and advanced technology",
    ],
    traditional: [
      "traditional administrative interior with classic design elements and a professional ambiance",
    ],
    mixed: [
      "administrative interior that fuses modern functionality with traditional style",
    ],
  },
  فندقي: {
    // Hotel
    modern: [
      "modern hotel interior with a sleek lobby, contemporary furnishings, and upscale finishes",
    ],
    traditional: [
      "traditional hotel interior featuring luxurious decor, rich textures, and cultural details",
    ],
    mixed: [
      "hotel interior blending modern luxury with traditional hospitality elements",
    ],
  },
  أخرى: {
    // Other / Custom
    modern: [
      "modern interior with unique design elements and innovative furnishings",
    ],
    traditional: [
      "traditional interior with cultural accents and classic decorative details",
    ],
    mixed: [
      "interior that creatively blends modern and traditional design elements",
    ],
  },
};

/**
 * Helper function to select an interior style based on preference and building type.
 *
 * @param {string} stylePreference - "modern", "traditional", or "mixed"
 * @param {string} [type] - The building type (e.g., "سكني", "تجاري", etc.)
 * @returns {string} A randomly chosen interior style from the type-specific array if available,
 *                   otherwise from the global interiorStyles.
 */
function getInteriorStyle(stylePreference, type = "") {
  const pref = stylePreference.toLowerCase();
  const typeKey = type.trim();
  if (
    typeKey &&
    typeInteriorStyles[typeKey] &&
    typeInteriorStyles[typeKey][pref]
  ) {
    const arr = typeInteriorStyles[typeKey][pref];
    return arr[Math.floor(Math.random() * arr.length)];
  }
  // Fallback to the global interiorStyles mapping.
  if (interiorStyles[pref]) {
    return interiorStyles[pref][
      Math.floor(Math.random() * interiorStyles[pref].length)
    ];
  }
  return interiorStyles.modern[
    Math.floor(Math.random() * interiorStyles.modern.length)
  ];
}

/**
 * Helper function to get an ordinal suffix for a given number.
 *
 * @param {number} number - The number for which to get the ordinal suffix.
 * @returns {string} The ordinal suffix (e.g., "st", "nd", "rd", "th").
 */
function getOrdinalSuffix(number) {
  const j = number % 10;
  const k = number % 100;
  if (j === 1 && k !== 11) return "st";
  if (j === 2 && k !== 12) return "nd";
  if (j === 3 && k !== 13) return "rd";
  return "th";
}

/**
 * Generates an interior prompt for a specified floor in a building.
 *
 * @param {string} style - The design style ("modern", "traditional", or "mixed").
 * @param {number} levels - The total number of floors in the building.
 * @param {number} floorNumber - The specific floor number for the interior prompt.
 * @param {string} [type] - The building type (e.g., "سكني", "تجاري", etc.)
 * @returns {string} The generated interior prompt.
 * @throws {Error} If floorNumber exceeds the total number of levels.
 */
function generateInteriorPrompt(style, levels, floorNumber, type = "") {
  if (floorNumber > levels) {
    throw new Error(
      `Floor number ${floorNumber} exceeds building height of ${levels} floors`
    );
  }

  // Get a random interior style description, taking the building type into account.
  const interiorStyle = getInteriorStyle(style, type);

  // Arrays for random selection of viewing angles, features, and lighting conditions.
  const viewingAngles = [
    "wide-angle view of the entire floor",
    "perspective view from the entrance",
    "view from the reception area",
    "panoramic view of the office space",
    "view towards the window wall",
  ];

  const interiorFeatures = [
    "with modern workstations and collaborative spaces",
    "with private offices and meeting rooms",
    "with open-plan layout and breakout areas",
    "with executive offices and conference facilities",
    "with flexible workspace configuration",
  ];

  const lightingConditions = [
    "with abundant natural light from floor-to-ceiling windows",
    "with modern LED lighting systems",
    "with balanced artificial and natural lighting",
    "with indirect ambient lighting",
    "with dramatic accent lighting features",
  ];

  // Randomly select one item from each array.
  const randomAngle =
    viewingAngles[Math.floor(Math.random() * viewingAngles.length)];
  const randomFeature =
    interiorFeatures[Math.floor(Math.random() * interiorFeatures.length)];
  const randomLighting =
    lightingConditions[Math.floor(Math.random() * lightingConditions.length)];

  // Generate a random seed for uniqueness.
  const uniqueSeed = Math.floor(Math.random() * 10000);

  return `Ultra-realistic architectural interior photograph of floor ${floorNumber} in a ${levels}-story building.
  
  INTERIOR DESCRIPTION:
  ${interiorStyle} ${randomFeature}
  
  VIEWING SPECIFICATIONS:
  ${randomAngle}
  ${randomLighting}
  Professional architectural photography
  Show complete floor layout
  Maintain realistic proportions and scale
  
  ENVIRONMENT:
  Located in Riyadh business district
  ${floorNumber}${getOrdinalSuffix(
    floorNumber
  )} floor of ${levels}-story building
  Expansive floor plate with city views
  High-end commercial finish quality
  
  CRITICAL REQUIREMENTS:
  - Must show a complete, furnished interior appropriate for a ${
    type || "generic"
  } building
  - Interior must reflect ${style} style architecture
  - Include appropriate cultural and functional elements
  - Maintain professional commercial atmosphere
  
  Unique generation seed: ${uniqueSeed}`;
}

module.exports = { generateInteriorPrompt };
