import axios from "axios";

// const baseUrl = "http://localhost:7000/api/";
export const baseUrl = "https://invesmentbackend.vercel.app/api/";

const axiosClient = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

export default axiosClient;
