import SingnIn from "./pages/signin/SignIn";
import SignUp from "./pages/signup/SignUp";
import RealStateInvestmentAi from "./pages/RealStateInvestmentAi/RealStateInvestmentAi";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
// import html2pdf from "html2pdf.js";
import PdfApp from "./pages/components/pdf";
import DownloadPage from "./pages/converter";
// import useKonvaWrapper from "./konva";
import { useAuth } from "./Hooks/authContext";
import { ErrorPage } from "./pages/error";
import FeasibilityStudies from "./pages/FeasibilityStudies/FeasibilityStudies";
import OurPlans from "./pages/ourPlans/OurPlans";
import SettingsPage from "./pages/settings/Settings";
// import LandingPage from "./pages/landingpage/LandingPage";
import RealStateInvestmentAiNew from "./pages/RealStateInvestmentAi/RealStateInvestmentAiNew";
import LandingPageV2 from "./pages/landingpage/LandingPageV2";
import CustomMapps, { Iframe } from "./view/main";
import { useEffect } from "react";
import axios from "axios";
import { Redirect } from "./pages/redirect";
import { baseUrl } from "./apis/axiosClient";

import ForsaInvestments from "./newInvestment/forsaInvestments";

export default function App() {
  const { auth, setAuth, setAccount } = useAuth();
  async function loginApiKey(apiKey) {
    const res = await axios.post(
      `${baseUrl}auth/apiKeyLogin`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
      }
    );
    console.log(res);
    setAuth(res.data.token);
    setAccount(res.data.account);
  }
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const apiKey = params.get("apiKey");
    console.log(apiKey);
    if (apiKey) {
      loginApiKey(apiKey);
      // setAuth(true);
      // setAccount(apiKey);
    }
  }, []);
  return (
    <>
      <Routes>
        {!auth && (
          <>
            <Route path="/" element={<LandingPageV2 />} />
            <Route path="/CustomMapps" element={<Iframe />} />
            <Route path="/frame" element={<></>} />
            <Route path="/singnin" element={<SingnIn />} />
            <Route path="/signup" element={<SignUp />} />
          </>
        )}
        {auth && (
          <>
            <Route path="/" element={<LandingPageV2 />} />
            <Route
              path="/investmentAinew"
              element={<RealStateInvestmentAiNew />}
            />
            <Route path="/investmentAi" element={<RealStateInvestmentAi />} />
            <Route path="/ForsaInvestments" element={<ForsaInvestments />} />
            <Route path="/convert" element={<DownloadPage />} />
            <Route path="/convert/:id" element={<Redirect />} />
            <Route path="/test" element={<PdfApp />}></Route>
            {/* <Route path="/land" element={<LandOrProperty />}></Route> */}
            <Route path="/ourplans" element={<OurPlans />} />
            <Route
              path="/FeasibilityStudies"
              element={<FeasibilityStudies />}
            ></Route>
            <Route path="/settings" element={<SettingsPage />}></Route>
          </>
        )}
        <Route path="/*" element={<ErrorPage />}></Route>
      </Routes>
    </>
  );
}
