import React, { useState, useEffect } from "react";
import axios from "axios";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useMapContext } from "../../../Hooks/mapContext";
// import { useMapContext } from "../../Hooks/mapContext";
ChartJS.register(ArcElement, Tooltip, Legend);

const RecommendationsChart = ({ data }) => {
  const chartData = {
    labels: data.map((item) => item.type),
    datasets: [
      {
        label: "Land Use Recommendations",
        data: data.map((item) => item.accuracy.replace("%", "")),
        backgroundColor: ["#ff6384", "#36a2eb", "#cc65fe"],
      },
    ],
  };

  return (
    <div>
      <Pie data={chartData} />
    </div>
  );
};

const AnalysisUI = ({ lang, data }) => {
  const populationData =
    lang === "ar" ? data?.populationAr : data?.populationEn;

  return (
    <div className="p-6 max-w-4xl mx-auto bg-white shadow-lg rounded-lg">
      {/* Analysis Section */}
      <h2 className="text-2xl font-bold text-gray-800 mb-4">
        {lang === "ar" ? "تحليل المنطقة" : "Analysis of Land Area"}
      </h2>
      <div className="border-b border-gray-200 mb-6"></div>

      <div className="mb-6">
        <p className="text-gray-700 text-right">
          {lang === "ar" ? data?.analysisInAr : data?.analysisInEn}
        </p>
      </div>

      {/* Population Section */}
      <h2 className="text-2xl  font-bold text-gray-800 mb-4">
        {lang === "ar" ? "بيانات السكان" : "Population Data"}
      </h2>
      <div dir={lang === "ar" ? "rtl" : "ltr"} className="mb-6">
        <p className="text-gray-700">
          <strong>
            {lang === "ar" ? "إجمالي السكان" : "Total Population"}:
          </strong>{" "}
          {populationData?.total}
        </p>
        <p className="text-gray-700">
          <strong>
            {lang === "ar" ? "التركيبة السكانية" : "Demographics"}:
          </strong>{" "}
          {populationData?.demographics}
        </p>
        <p className="text-gray-700">
          <strong>{lang === "ar" ? "المهن" : "Occupations"}:</strong>{" "}
          {populationData?.occupations}
        </p>
        <p className="text-gray-700">
          <strong>{lang === "ar" ? "متوسط الراتب" : "Average Salary"}:</strong>{" "}
          {populationData?.averageSalary}
        </p>
      </div>

      {/* Recommendations Section */}
      <h2 className="text-2xl font-bold text-gray-800 mb-4">
        {lang === "ar"
          ? "توصيات أنواع المباني"
          : "Building Type Recommendations"}
      </h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 text-left text-gray-600">
                {lang === "ar" ? "نوع المبنى" : "Building Type"}
              </th>
              <th className="py-2 px-4 text-left text-gray-600">
                {lang === "ar" ? "النسبة" : "Accuracy"}
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.numbersAr.map((item, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border-b border-gray-200">
                  {item.type}
                </td>
                <td className="py-2 px-4 border-b border-gray-200">
                  <div className="flex items-center">
                    <div className="w-full bg-gray-200 rounded h-4">
                      <div
                        className="bg-blue-500 h-4 rounded"
                        style={{ width: `${item.accuracy}` }}
                      ></div>
                    </div>
                    <span className="ml-2 text-gray-700">{item?.accuracy}</span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

function fixAndConvertToObject(jsonString) {
  try {
    // Attempt to fix the JSON string by closing any unclosed brackets or braces
    const fixedString =
      jsonString.trim() + (jsonString.endsWith("}") ? "" : `"}`);
    console.log(fixedString);
    // Parse the fixed JSON string
    const parsedResponse = JSON.parse(fixedString);

    // Return the parsed and fixed object
    return parsedResponse;
  } catch (error) {
    console.error("Error parsing or fixing the JSON string:", error);
    return null; // Handle errors gracefully
  }
}

const LandAnalysis = ({ lang, photo }) => {
  const [data, setData] = useState(null); // To store the fetched data
  const [loading, setLoading] = useState(true); // To handle the loading state
  const [error, setError] = useState(null); // To handle errors

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://coral-app-8z265.ondigitalocean.app/analyze-map",
          {
            mapLink: photo,
          }
        );
        console.log(response, "response");
        const recommendations = response.data.recommendations; // Parse JSON
        console.log(recommendations);
        let r = fixAndConvertToObject(recommendations);
        console.log(r);
        setData(r); // Set the fetched data
        setLoading(false); // Data is loaded
      } catch (err) {
        setError(err.message);
        setLoading(false); // Error occurred
      }
    };

    fetchData(); // Fetch data on component mount
  }, [photo]);

  if (loading) return <p className="text-white text-2xl">Loading...</p>;
  if (error) return;

  return (
    <div
      style={{ height: "600px" }}
      className="w-[90%] h-[800px] overflow-hidden overflow-y-scroll bg-white flex flex-col justify-center items-center p-5 pt-[550px]  "
    >
      <div className="w-[260px] h-[260px]">
        <RecommendationsChart data={data.numbersAr} />
      </div>
      <div>
        <AnalysisUI lang={lang} data={data} />
      </div>
    </div>
  );
};

export default LandAnalysis;
