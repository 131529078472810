import axiosClient from "./axiosClient";

const getRemainingCases = async (token, id) =>
  axiosClient.get(`studyCase/remainingcases/${id}`, {
    headers: { token: token },
  });

const getAllCases = async (token) =>
  axiosClient.get(`studyCase/`, { headers: { token: token } });
const getCase = async (token, id) =>
  axiosClient.get(`studyCase/${id}`, { headers: { token: token } });
const updateCase = async (token, id, data) =>
  axiosClient.put(`studyCase/${id}`, { data }, { headers: { token: token } });

const createStudyCase = async (data, token) => {
  console.log(data, "api data is reciving");
  return axiosClient.post(`studyCase/`, JSON.stringify(data), {
    headers: { token: token },
  });
};

const StudyCasesApi = {
  getRemainingCases,
  getAllCases,
  getCase,
  createStudyCase,
  updateCase,
};

export default StudyCasesApi;
