/***************************************************
 *  getInteriorPrompt.js
 *  (Generating interior prompts for each building type)
 ***************************************************/
const { generateInteriorPrompt } = require("./advancedInteriorPrompt");

/**
 * Generate a final interior prompt that merges:
 * 1) A core interior description generated by generateInteriorPrompt()
 * 2) Additional context based on the building type.
 *
 * @param {Object} args
 * @param {string} args.type - e.g. "سكني", "تجاري", etc.
 * @param {number} args.floors - total number of floors in the building
 * @param {number} args.floorNumber - the floor number to show in the interior photo
 * @param {string} [args.city]
 * @param {string} [args.customPropertyType]
 * @param {string} [args.stylePreference] - "modern", "traditional", or "mixed"
 * @returns {string} The merged interior prompt
 */
function getInteriorPrompt({
  type,
  floors,
  floorNumber,
  city,
  customPropertyType,
  stylePreference = "modern",
}) {
  // Generate the core interior prompt using your advanced function.
  const interiorCorePrompt = generateInteriorPrompt(
    stylePreference,
    floors,
    floorNumber,
    type
  );

  // Define extra interior context based on building type:
  let extraInteriorDescription = "";

  switch (type) {
    case "سكني": // Residential
      extraInteriorDescription = `Interior Focus: Residential Home
City: ${city}
Description: Capture a warm and inviting residential interior that emphasizes comfort, natural lighting, and a blend of modern and traditional design elements.`;
      break;

    case "تجاري": // Commercial
      extraInteriorDescription = `Interior Focus: Commercial Office
City: ${city}
Description: Showcase a sleek, modern office interior with open workspaces, collaborative areas, and a professional ambiance.`;
      break;

    case "تجاري سكني": // Mixed-use
      extraInteriorDescription = `Interior Focus: Mixed-use Space
City: ${city}
Description: Highlight an interior that blends commercial efficiency with residential comfort, possibly combining workspaces with living areas.`;
      break;

    case "مول تجاري": // Shopping Mall
      extraInteriorDescription = `Interior Focus: Mall Interior
City: ${city}
Description: Emphasize spacious, well-lit interiors with inviting retail spaces and dynamic communal areas suitable for shoppers.`;
      break;

    case "فلل": // Villas
      extraInteriorDescription = `Interior Focus: Villa Interior
City: ${city}
Description: Capture a luxurious villa interior with elegant finishes, open layouts, and a harmonious mix of traditional and modern design.`;
      break;

    case "إداري": // Administrative
      extraInteriorDescription = `Interior Focus: Administrative Building
City: ${city}
Description: Present a formal, well-organized interior that includes clearly defined workspaces, meeting rooms, and a sense of professionalism.`;
      break;

    case "فندقي": // Hotel
      extraInteriorDescription = `Interior Focus: Hotel Lobby/Room
City: ${city}
Description: Showcase an upscale hotel interior with modern amenities, stylish furnishings, and an inviting atmosphere that reflects luxury.`;
      break;

    case "أخرى": // Other / Custom
      extraInteriorDescription = `Interior Focus: ${customPropertyType}
City: ${city}
Description: Capture a unique interior that reflects the distinctive characteristics of ${customPropertyType}. Focus on creative design solutions that blend cultural elements with modern functionality.`;
      break;

    default:
      extraInteriorDescription = `Interior Focus: Unspecified Type
City: ${city}
Description: Present a generic yet professional interior layout with clear spatial divisions and a harmonious design.`;
      break;
  }

  // Merge the core interior prompt with the extra type-based context.
  const finalInteriorPrompt = `${interiorCorePrompt}

ADDITIONAL INTERIOR CONTEXT (BASED ON BUILDING TYPE):
${extraInteriorDescription}

NOTE: The interior image should adhere to the design guidelines and maintain clarity in spatial layout.`;

  return finalInteriorPrompt;
}

module.exports = { getInteriorPrompt };
