/* eslint-disable no-unused-vars */
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "../components/dropdown-menu";
import { useLang } from "../Hooks/LangContext";
import { t } from "../lib/utils";
import { HandleBigNumbers } from "../UtilityFunctions/HandleBigNumbers";
import { Ellipsis } from "lucide-react";
import { Link } from "react-router-dom";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
  Legend,
  Bar,
  ReferenceDot,
} from "recharts";
import React, { useEffect, useState, useMemo } from "react";
import DemographicsPieCharts from "../components/DemographicsPieCharts";
import { useLocation, useNavigate } from "react-router";
import { usePopup } from "../Hooks/popup";
import { useMapContext } from "../Hooks/mapContext";

const BasicInfo = ({
  basicInfo,
  mixed = false,
  isCompound = false,
  isVillaCompound = false,
  isVilla = false,
}) => {
  const { lang } = useLang();

  // Define an array of colors (add or change as needed)
  const colorArray = [
    "#A998FA",
    "#6DCAF9",
    "#FACB5F",
    "#FFBB00",
    "#FF6666",
    "#00CC99",
  ];

  // Build chart data dynamically from basicInfo.
  // Each key-value pair becomes an object with:
  //   - value: the basicInfo value,
  //   - name: the key (translated),
  //   - itemStyle: color from the colorArray (cycling if needed)
  const chartData = Object.entries(basicInfo)
    .filter(([key]) => key !== "totalFloors")
    .map(([key, value], index) => ({
      value: value.value,
      name: t(value.arabic, key, lang),
      itemStyle: { color: colorArray[index % colorArray.length] },
    }));

  console.log(basicInfo);

  return (
    <section className="w-full">
      <h2 className="text-center text-[40px] font-bold">
        {t("المعلومات الأساسية", "Basic information", lang)}
      </h2>
      <div className="flex items-center justify-center gap-20">
        <aside>
          <DemographicsPieCharts data={chartData} loading={false} />
        </aside>
        <aside className="flex items-center gap-2">
          {/* Inputs */}
          <div className="flex flex-col gap-1">
            {Object.entries(basicInfo).map(([key, value], i) => {
              if (mixed) {
                return (
                  <input
                    key={i}
                    className="w-[50px] border-b-[2px] border-b-[#d9d9d9] bg-transparent p-1 
                             focus:rounded-[4px] focus:border-2 focus:border-[#007aff] 
                             focus:outline-none text-center"
                    type="text"
                    value={
                      value.value !== ""
                        ? parseFloat(value.value).toFixed()
                        : value.value
                    }
                  />
                );
              } else {
                if (key !== "secondFloors") {
                  return (
                    <input
                      key={i}
                      className="w-[50px] border-b-[2px] border-b-[#d9d9d9] bg-transparent p-1 
                               focus:rounded-[4px] focus:border-2 focus:border-[#007aff] 
                               focus:outline-none text-center"
                      type="text"
                      value={
                        value.value !== ""
                          ? parseFloat(value.value).toFixed()
                          : value.value
                      }
                    />
                  );
                }
              }
            })}
          </div>

          <div
            className={`${
              isCompound ? "h-[260px]" : "h-[185px]"
            } w-[2px] bg-[#d9d9d9]`}
          ></div>

          {/* Labels */}
          <div className="flex flex-col gap-1">
            {Object.entries(basicInfo).map(([key, value], i) => {
              if (mixed) {
                return (
                  <p
                    key={i}
                    className="border-b-[2px] text-left border-b-[#d9d9d9] p-1"
                  >
                    {t(value.arabic, value.arabic, lang)}
                  </p>
                );
              } else {
                if (key !== "secondFloors") {
                  return (
                    <p
                      key={i}
                      className="border-b-[2px] text-left border-b-[#d9d9d9] p-1"
                    >
                      {t(value.arabic, value.arabic, lang)}
                    </p>
                  );
                }
              }
            })}
          </div>
        </aside>
      </div>
    </section>
  );
};

const Areas = ({
  spaces,
  firstFloorSingleName,
  secondFloorSingleName,
  mixedUse = false,
  isCompound = false,
  isVillaCompound = false,
  isVilla = false,
  setBuildingArea,
  buildingArea,
  setVillaArea,
  VillaArea,
}) => {
  const { lang } = useLang();

  // Define an array of colors for the chart slices.
  const colorArray = [
    "#A998FA",
    "#6DCAF9",
    "#FACB5F",
    "#FFBB00",
    "#FF6666",
    "#00CC99",
  ];

  // Build chart data dynamically from the `spaces` object.
  // Each key-value pair becomes an object with:
  // - value: the spaces value,
  // - name: the key (translated),
  // - itemStyle: a color from the colorArray (cycled if there are more keys than colors).
  const chartData = Object.entries(spaces).map(([key, value], index) => ({
    value: value.value,
    name: t(value.arabic, value.arabic, lang),
    itemStyle: { color: colorArray[index % colorArray.length] },
  }));

  return (
    <section className="w-full">
      <h2 className="text-center text-[40px] font-bold">
        {t("المساحات", "Areas", lang)}
      </h2>
      <div className="flex items-center justify-center gap-20">
        <aside>
          <DemographicsPieCharts data={chartData} loading={false} />
        </aside>

        <aside className="flex items-center gap-2">
          {/* Inputs Column */}
          <div className="flex flex-col gap-1">
            {Object.entries(spaces).map(([key, value], i) => {
              console.log("value1: ", value);
              if (mixedUse) {
                return (
                  <p
                    key={i}
                    className="w-[100px] border-b-[2px] border-b-[#d9d9d9] 
                           bg-transparent p-1 focus:rounded-[4px] 
                           focus:border-2 focus:border-[#007aff] 
                           focus:outline-none text-center"
                    type="text"
                  >
                    {parseFloat(value.value).toFixed(2)}
                  </p>
                );
              } else {
                if (key !== "secondFloorArea") {
                  if (key === "buildingArea") {
                    return (
                      <div className="flex w-[100px] border-b-[2px] border-b-[#d9d9d9] bg- align-center text-center">
                        <input
                          key={i}
                          className=" w-[100px]
                        focus:rounded-[4px] focus:border-2 focus:border-[#007aff] 
                        focus:outline-none text-center"
                          type="text"
                          value={parseFloat(buildingArea).toFixed(0)}
                          onChange={(e) => setBuildingArea(e.target.value)}
                        />
                      </div>
                    );
                  } else if (key === "VillaArea") {
                    return (
                      <div className="flex w-[100px] border-b-[2px] border-b-[#d9d9d9] bg- align-center text-center">
                        <input
                          key={i}
                          className=" w-[100px]
                        focus:rounded-[4px] focus:border-2 focus:border-[#007aff]
                        focus:outline-none text-center"
                          type="text"
                          value={parseFloat(VillaArea).toFixed(0)}
                          onChange={(e) => setVillaArea(e.target.value)}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <p
                        key={i}
                        className="w-[100px] border-b-[2px] border-b-[#d9d9d9] 
                             bg-transparent p-1 focus:rounded-[4px] 
                             focus:border-2 focus:border-[#007aff] 
                             focus:outline-none text-center"
                        type="text"
                      >
                        {parseFloat(value.value).toFixed(2)}
                      </p>
                    );
                  }
                }
              }
            })}
          </div>

          <div
            className={`${
              isCompound ? "h-[260px]" : "h-[225px]"
            } w-[2px] bg-[#d9d9d9]`}
          />

          {/* Labels */}
          <div className="flex flex-col gap-1">
            {Object.entries(spaces).map(([key, value], i) => {
              if (mixedUse) {
                return (
                  <p
                    key={i}
                    className="border-b-[2px] text-left border-b-[#d9d9d9] p-1"
                  >
                    {t(value.arabic, value.arabic, lang)}
                  </p>
                );
              } else {
                if (key !== "secondFloorArea") {
                  return (
                    <p
                      key={i}
                      className="border-b-[2px] text-left border-b-[#d9d9d9] p-1"
                    >
                      {t(value.arabic, value.arabic, lang)}
                    </p>
                  );
                }
              }
            })}
          </div>
        </aside>
      </div>
    </section>
  );
};

const Costs = ({ costSchedule, spaces, setTotalFixedCost }) => {
  const { lang } = useLang();

  const totalLandCost = parseFloat(
    (
      parseFloat(costSchedule.fixedCost.pricePerSqMeter.value) *
      parseFloat(spaces.totalLandArea.value)
    ).toFixed(0)
  );

  useEffect(() => {
    setTotalFixedCost(totalLandCost);
  }, []);

  console.log(costSchedule, spaces);

  return (
    <section className="flex flex-col items-center justify-center gap-5">
      {/* Title */}
      <h2 className="text-center text-[40px] font-bold">
        {t(":جدول التكاليف", "Cost table:", lang)}
      </h2>

      {/* Fixed Cost Section */}
      <p className="text-center text-[30px] font-bold">
        {t(":التكلفه الثابتة", "Fixed cost:", lang)}
      </p>
      <div className="flex items-center justify-center gap-5">
        {/* Inputs Column */}
        <div className="flex flex-col gap-1">
          {Object.entries(costSchedule.fixedCost).map(([label, value], i) => (
            <input
              key={i}
              className="w-[150px] border-b-[2px] border-b-[#d9d9d9] 
                         bg-transparent p-1 focus:rounded-[4px] 
                         focus:border-2 focus:border-[#007aff] 
                         focus:outline-none text-center"
              type="text"
              defaultValue={parseFloat(
                parseFloat(value.value).toFixed(2)
              ).toLocaleString()}
            />
          ))}

          <p
            className="w-[150px] border-b-[2px] border-b-[#d9d9d9] 
                         bg-transparent p-1 focus:rounded-[4px] 
                         focus:border-2 focus:border-[#007aff] 
                         focus:outline-none text-center"
          >
            {parseFloat(spaces.totalLandArea.value).toFixed(2)}
          </p>

          <p
            className="w-[150px] flex justify-center items-center gap-1 border-b-[2px] border-b-[#d9d9d9] 
                         bg-transparent p-1 focus:rounded-[4px] 
                         focus:border-2 focus:border-[#007aff] 
                         focus:outline-none text-center"
          >
            <span>ريال</span>
            {totalLandCost.toLocaleString()}
          </p>
        </div>

        <div className="h-[110px] w-[2px] bg-[#d9d9d9]" />

        {/* Labels Column */}
        <div className="flex flex-col gap-1">
          {Object.entries(costSchedule.fixedCost).map((label, i) => (
            <p
              key={i}
              className="border-b-[2px] border-b-[#d9d9d9] p-1 text-left"
            >
              {t(label[1].arabic, label[1].arabic, lang)}
            </p>
          ))}
          <p className="border-b-[2px] border-b-[#d9d9d9] p-1 text-left">
            {t("مساحة الأرض (م²)", "مساحة الأرض (م²)", lang)}
          </p>
          <p className="border-b-[2px] border-b-[#d9d9d9] p-1 text-left">
            {t("إجمالي تكلفة الأرض", "إجمالي تكلفة الأرض", lang)}
          </p>
        </div>
      </div>

      {/* Variable Cost Section */}
      <p className="text-center text-[30px] font-bold">
        {t(":التكلفه المتغيرة", "Variable cost:", lang)}
      </p>
      <div className="flex items-center justify-center gap-5">
        {/* Inputs Column */}
        <div className="flex flex-col gap-1">
          {Object.entries(costSchedule.variableCost).map(
            ([label, value], i) => (
              <input
                key={i}
                className="w-[50px] border-b-[2px] border-b-[#d9d9d9] 
                         bg-transparent p-1 focus:rounded-[4px] 
                         focus:border-2 focus:border-[#007aff] 
                         focus:outline-none text-center"
                type="text"
                defaultValue={value.value}
              />
            )
          )}
        </div>

        <div className="h-[185px] w-[2px] bg-[#d9d9d9]" />

        {/* Labels Column */}
        <div className="flex flex-col gap-1 text-left">
          {Object.entries(costSchedule.variableCost).map((label, i) => (
            <p key={i} className="border-b-[2px] border-b-[#d9d9d9] p-1">
              {t(label[1].arabic, label[1].arabic, lang)}
            </p>
          ))}
        </div>
      </div>
    </section>
  );
};

const CostsWithChart = ({
  costSchedule,
  basicInfo,
  spaces,
  salesCommission,
  setTotalCost,
  name = "البناء الكلية",
  isCompound = false,
}) => {
  const { lang } = useLang();
  const formatYAxis = (value) => {
    const { number, abbreviationEn, abbreviationAr } = HandleBigNumbers(value);
    return lang === "en"
      ? `${number}${abbreviationEn}`
      : `${number}${abbreviationAr}`;
  };

  // 1) Compute each cost as a numeric value.
  let administrativeFloorsCost =
    ((spaces.firstFloorArea.value || 0) * (basicInfo.firstFloors.value || 0) +
      (spaces.secondFloorArea?.value || 0) *
        (basicInfo.secondFloors?.value || 0) +
      (spaces.groundFloorArea.value || 0) +
      (spaces.roofFloorArea.value || 0)) *
    (costSchedule.variableCost.administrativeCost.value || 0);

  let basementFloorsCost =
    (spaces.basementFloorArea.value || 0) *
    (basicInfo.basementFloors.value || 0) *
    (costSchedule.variableCost.parkingCost.value || 0);

  let sharedAreasCost =
    (spaces.sharedAreas.value || 0) *
    (costSchedule.variableCost.sharedAreasCost.value || 0);

  if (isCompound) {
    administrativeFloorsCost =
      administrativeFloorsCost *
      parseFloat(basicInfo.totalBuildings.value).toFixed(0);
    basementFloorsCost =
      basementFloorsCost *
      parseFloat(basicInfo.totalBuildings.value).toFixed(0);
    sharedAreasCost =
      sharedAreasCost * parseFloat(basicInfo.totalBuildings.value).toFixed(0);
  }

  // 3) Combine everything in a total:
  let totalCosts =
    administrativeFloorsCost +
    basementFloorsCost +
    sharedAreasCost +
    salesCommission;

  useEffect(() => {
    setTotalCost(totalCosts);
  }, [totalCosts, setTotalCost]);

  return (
    <section className="flex w-full flex-col items-center justify-center gap-5">
      <div className="flex min-h-[500px] w-full flex-col items-center justify-center gap-5 bg-[#414651] text-white">
        <h2 className="text-center text-[30px] font-light">
          {t(":جدول التكاليف", "Cost table:", lang)}
        </h2>

        <div className="flex items-center justify-center gap-5">
          <div className="flex flex-col gap-1">
            <p className="border-b-[2px] border-b-[#d9d9d9] p-1">
              {t("ريال سعودي", "Saudi Riyal", lang)}
            </p>
            <p className="border-b-[2px] border-b-[#d9d9d9] p-1">
              {t("ريال سعودي", "Saudi Riyal", lang)}
            </p>
            <p className="border-b-[2px] border-b-[#d9d9d9] p-1">
              {t("ريال سعودي", "Saudi Riyal", lang)}
            </p>
            <p className="border-b-[2px] border-b-[#d9d9d9] p-1">
              {t("ريال سعودي", "Saudi Riyal", lang)}
            </p>
            <p className="border-b-[2px] border-b-[#d9d9d9] p-1">
              {t("ريال سعودي", "Saudi Riyal", lang)}
            </p>
          </div>
          <div className="h-[220px] w-[2px] bg-[#d9d9d9]"></div>

          <div className="flex flex-col gap-1">
            <p className="w-[150px] border-b-[2px] border-b-[#d9d9d9] bg-transparent p-1 focus:rounded-[4px] focus:border-2 focus:border-[#007aff] focus:outline-none text-center">
              {parseFloat(administrativeFloorsCost.toFixed(0)).toLocaleString()}
            </p>
            <p className="w-[150px] border-b-[2px] border-b-[#d9d9d9] bg-transparent p-1 focus:rounded-[4px] focus:border-2 focus:border-[#007aff] focus:outline-none text-center">
              {parseFloat(basementFloorsCost.toFixed(0)).toLocaleString()}
            </p>
            <p className="w-[150px] border-b-[2px] border-b-[#d9d9d9] bg-transparent p-1 focus:rounded-[4px] focus:border-2 focus:border-[#007aff] focus:outline-none text-center">
              {parseFloat(sharedAreasCost.toFixed(0)).toLocaleString()}
            </p>
            <p className="w-[150px] border-b-[2px] border-b-[#d9d9d9] bg-transparent p-1 focus:rounded-[4px] focus:border-2 focus:border-[#007aff] focus:outline-none text-center">
              {parseFloat(salesCommission.toFixed(0)).toLocaleString()}
            </p>
            <p className="w-[150px] border-b-[2px] border-b-[#d9d9d9] bg-transparent p-1 focus:rounded-[4px] focus:border-2 focus:border-[#007aff] focus:outline-none text-center">
              {parseFloat(totalCosts.toFixed(0)).toLocaleString()}
            </p>
          </div>

          <div className="h-[220px] w-[2px] bg-[#d9d9d9]"></div>
          <div className="flex flex-col gap-1">
            {[
              {
                textAr: `تكلفة ${name}`,
                textEn: "Administrative floors cost",
              },
              {
                textAr: "تكلفة الطوابق السفلية (مواقف السيارات)",
                textEn: "Basement floors cost (parking)",
              },
              {
                textAr: "تكلفة المساحات المشتركة",
                textEn: "Shared lobbies cost",
              },
              {
                textAr: "عمولة المبيعات",
                textEn: "Lobbies commission",
              },
              {
                textAr: "إجمالي التكاليف المتغيرة",
                textEn: "Total estimated costs",
              },
            ].map((e, i) => (
              <p
                key={i}
                className="border-b-[2px] border-b-[#d9d9d9] p-1 text-left"
              >
                {t(e.textAr, e.textEn, lang)}
              </p>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

const TotalCost = ({ totalVariableCost, totalFixedCost }) => {
  const { lang } = useLang();
  return (
    <section className="flex h-[150px] items-center justify-center bg-[#414651]">
      <h4 className="text-center text-[30px] font-bold text-white">
        {t(
          `اجمالي التكلفة: ${parseFloat(
            (totalVariableCost + totalFixedCost).toFixed(0)
          ).toLocaleString()} ريال سعودي `,
          `Total cost: ${(
            totalVariableCost + totalFixedCost
          ).toLocaleString()} Saudi Riyal`,
          lang
        )}
      </h4>
    </section>
  );
};

const RevenueTable = ({ yearPlan, setYearPlan }) => {
  const { lang } = useLang();

  // Helper to update a specific field of a specific year
  const handleFractionChange = (yearIndex, fieldName) => (newFraction) => {
    setYearPlan((prev) => {
      const updated = [...prev];
      updated[yearIndex] = {
        ...updated[yearIndex],
        [fieldName]: newFraction,
      };
      return updated;
    });
  };

  return (
    <section className="flex flex-col items-center gap-5 ">
      <div className="flex flex-col gap-10">
        <h3 className="text-center text-[50px] font-bold">
          {t(":جدول الإيرادات", "Revenue table:", lang)}
        </h3>
        <p className="text-center text-[40px] font-bold">
          {t(":الإيرادات من البيع", "Revenue from sales:", lang)}
        </p>
      </div>

      <div className="flex gap-5 text-[20px] ">
        {/* === Third Year === */}
        <div className="flex flex-col gap-1">
          <div
            className="border-b-[2px] border-b-[#d9d9d9] bg-transparent p-1 
                          focus:rounded-[4px] focus:border-2 focus:border-[#007aff] 
                          focus:outline-none"
          >
            {t("السنة الثالثة", "third year", lang)}
          </div>
          <PercentageInput
            fraction={yearPlan[2]?.taxRate || 0}
            onFractionChange={handleFractionChange(2, "taxRate")}
          />
          <PercentageInput
            fraction={yearPlan[2]?.priceIncrease || 0}
            onFractionChange={handleFractionChange(2, "priceIncrease")}
          />
        </div>

        <div className="h-[128px] w-[2px] bg-[#d9d9d9]" />

        {/* === Second Year === */}
        <div className="flex flex-col gap-1">
          <div
            className="border-b-[2px] border-b-[#d9d9d9] bg-transparent p-1 
                          focus:rounded-[4px] focus:border-2 focus:border-[#007aff] 
                          focus:outline-none"
          >
            {t("السنة الثانية", "second year", lang)}
          </div>
          <PercentageInput
            fraction={yearPlan[1]?.taxRate || 0}
            onFractionChange={handleFractionChange(1, "taxRate")}
          />
          <PercentageInput
            fraction={yearPlan[1]?.priceIncrease || 0}
            onFractionChange={handleFractionChange(1, "priceIncrease")}
          />
        </div>

        <div className="h-[128px] w-[2px] bg-[#d9d9d9]" />

        {/* === First Year === */}
        <div className="flex flex-col gap-1">
          <div
            className="border-b-[2px] border-b-[#d9d9d9] bg-transparent p-1 
                          focus:rounded-[4px] focus:border-2 focus:border-[#007aff] 
                          focus:outline-none"
          >
            {t("السنة الاولى", "first year", lang)}
          </div>
          <PercentageInput
            fraction={yearPlan[0]?.taxRate || 0}
            onFractionChange={handleFractionChange(0, "taxRate")}
          />
          <PercentageInput
            fraction={yearPlan[0]?.priceIncrease || 0}
            onFractionChange={handleFractionChange(0, "priceIncrease")}
          />
        </div>

        <div className="h-[128px] w-[2px] bg-[#d9d9d9]" />

        {/* === Labels Column === */}
        <div className="flex flex-col gap-1">
          {[
            { textAr: "", textEn: "" },
            { textAr: "معدل الضريبة", textEn: "Tax rate" },
            { textAr: "زياده السعر", textEn: "Price increase" },
          ].map((e, i) => (
            <p
              key={i}
              className="h-[40px] border-b-[2px] border-b-[#d9d9d9] p-1 font-bold"
            >
              {t(e.textAr, e.textEn, lang)}
            </p>
          ))}
        </div>
      </div>
    </section>
  );
};

// A small wrapper for the input + "%" sign
function PercentageInput({ fraction, onFractionChange }) {
  // Internal state for the user’s typed text
  const [textValue, setTextValue] = React.useState("");

  // When `fraction` changes from outside, update `textValue`
  React.useEffect(() => {
    if (fraction != null && !Number.isNaN(fraction)) {
      // Convert fraction (0.12) to "12"
      const asPercentString = (fraction * 100).toFixed(1).toString();
      setTextValue(asPercentString);
    }
  }, [fraction]);

  // Update local state on each keystroke
  const handleChange = (e) => {
    setTextValue(e.target.value);
  };

  // On blur, convert string -> fraction, inform parent
  const handleBlur = () => {
    const numericValue = parseFloat(textValue);
    if (!Number.isNaN(numericValue)) {
      // 12 => 0.12
      onFractionChange(numericValue / 100);
    } else {
      // If the user typed something invalid, revert to 0
      onFractionChange(0);
    }
  };

  // On Enter key, apply changes
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleBlur();
      e.target.blur();
    }
  };

  return (
    <div className="relative w-[100px]">
      <input
        className="
          w-full border-b-[2px] border-b-[#d9d9d9]
          bg-transparent p-1 pr-6
          focus:rounded-[4px] focus:border-2 focus:border-[#007aff]
          focus:outline-none text-center
        "
        type="text"
        value={textValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      />
      <span className="absolute right-1 top-1/2 -translate-y-1/2 text-gray-600">
        %
      </span>
    </div>
  );
}

function BetterInput({ value, onChange }) {
  // Internal state for the user’s typed text
  const [textValue, setTextValue] = React.useState("");

  // When `fraction` changes from outside, update `textValue`
  React.useEffect(() => {
    if (value != null && !Number.isNaN(value)) {
      setTextValue(value);
    }
  }, [value]);

  // Update local state on each keystroke
  const handleChange = (e) => {
    setTextValue(e.target.value);
  };

  // On blur, convert string -> fraction, inform parent
  const handleBlur = () => {
    const numericValue = parseFloat(textValue);
    if (!Number.isNaN(numericValue)) {
      onChange(numericValue);
    } else {
      // If the user typed something invalid, revert to 0
      onChange(0);
    }
  };

  // On Enter key, apply changes
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleBlur();
      e.target.blur();
    }
  };

  return (
    <div
      className="w-[100px] flex justify-center 
    items-center gap-1 border-b-[2px] border-b-[#d9d9d9] 
    focus:rounded-[4px] focus:border-2 
    focus:border-[#007aff]"
    >
      <span>ريال</span>
      <input
        className="
        w-full border-b-[#d9d9d9]
        bg-transparent p-1
        focus:rounded-[4px] focus:border-2 focus:border-[#007aff]
        focus:outline-none text-center
      "
        type="text"
        value={textValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
}

// ----- Helper: Adjust shares for a given field -----
// This function takes an array (plan) and a share field name (e.g. "vipShare")
// and returns a new array in which each year's share is clamped so that
// the cumulative total never exceeds 1.0.
const adjustShares = (plan, shareField, rental = false) => {
  let remaining = 1.0;
  if (rental) return plan;

  return plan.map((year) => {
    const currentShare = year[shareField] || 0;
    if (remaining <= 0) {
      return { ...year, [shareField]: 0 };
    }
    if (currentShare <= remaining) {
      remaining -= currentShare;
      return { ...year, [shareField]: currentShare };
    }
    return { ...year, [shareField]: 0 };
  });
};

const VIPfloor = ({
  currentVipPrice,
  setCurrentVipPrice,
  yearPlan,
  setYearPlan,
  vipSpaceArea,
  setTotalRevenues,
}) => {
  const { lang } = useLang();

  // Helper to update a specific field of a specific year
  const handleFractionChange = (yearIndex, fieldName) => (newFraction) => {
    setYearPlan((prev) => {
      const updated = [...prev];
      updated[yearIndex] = {
        ...updated[yearIndex],
        [fieldName]: newFraction,
      };
      return adjustShares(updated, fieldName);
    });
  };

  const onBetterInputChange = (value) => {
    setCurrentVipPrice(value);
  };

  const secondYearPrice =
    currentVipPrice * yearPlan[1]?.priceIncrease + currentVipPrice;
  const thirdYearPrice =
    secondYearPrice * yearPlan[2]?.priceIncrease + secondYearPrice;

  // Revennues
  const firstYearRevenue =
    currentVipPrice * yearPlan[0]?.vipShare * vipSpaceArea;

  const secondYearRevenue =
    secondYearPrice * yearPlan[1]?.vipShare * vipSpaceArea;

  const thirdYearRevenue =
    thirdYearPrice * yearPlan[2]?.vipShare * vipSpaceArea;

  useEffect(() => {
    setTotalRevenues((prev) => {
      const updated = [...prev];
      updated[0] = {
        ...updated[0],
        vip: firstYearRevenue,
      };
      updated[1] = {
        ...updated[1],
        vip: secondYearRevenue,
      };
      updated[2] = {
        ...updated[2],
        vip: thirdYearRevenue,
      };
      return updated;
    });
  }, [firstYearRevenue, secondYearRevenue, thirdYearRevenue]);

  return (
    <section className="flex flex-col items-center gap-5">
      <div className="flex flex-col gap-2">
        <h3 className="text-center text-[30px] font-light">
          {t(":طابق كبار الزوار", "ٌVIP floor:", lang)}
        </h3>
      </div>
      <div className="flex gap-5">
        <div className="flex flex-col gap-1">
          <p className="w-[100px] flex justify-center items-center gap-1 border-b-[2px] border-b-[#d9d9d9] bg-transparent p-1 focus:rounded-[4px] focus:border-2 focus:border-[#007aff] focus:outline-none text-center">
            <span>م²</span>
            {vipSpaceArea * yearPlan[2].vipShare}
          </p>
          <PercentageInput
            fraction={yearPlan[2]?.vipShare || 0}
            onFractionChange={handleFractionChange(2, "vipShare")}
          />
          <p className="w-[100px] flex justify-center items-center gap-1 border-b-[2px] border-b-[#d9d9d9] bg-transparent p-1 focus:rounded-[4px] focus:border-2 focus:border-[#007aff] focus:outline-none text-center">
            <span>ريال</span>
            {thirdYearPrice}
          </p>
        </div>
        <div className="h-[115px] w-[2px] bg-[#d9d9d9]"></div>
        <div className="flex flex-col gap-1">
          <p className="w-[100px] flex justify-center items-center gap-1 border-b-[2px] border-b-[#d9d9d9] bg-transparent p-1 focus:rounded-[4px] focus:border-2 focus:border-[#007aff] focus:outline-none text-center">
            <span>م²</span>
            {vipSpaceArea * yearPlan[1].vipShare}
          </p>
          <PercentageInput
            fraction={yearPlan[1]?.vipShare || 0}
            onFractionChange={handleFractionChange(1, "vipShare")}
          />
          <p className="w-[100px] flex justify-center items-center gap-1 border-b-[2px] border-b-[#d9d9d9] bg-transparent p-1 focus:rounded-[4px] focus:border-2 focus:border-[#007aff] focus:outline-none text-center">
            <span>ريال</span>
            {secondYearPrice}
          </p>
        </div>
        <div className="h-[115px] w-[2px] bg-[#d9d9d9]"></div>
        <div className="flex flex-col gap-1">
          <p className="w-[100px] flex justify-center items-center gap-1 border-b-[2px] border-b-[#d9d9d9] bg-transparent p-1 focus:rounded-[4px] focus:border-2 focus:border-[#007aff] focus:outline-none text-center">
            <span>م²</span>
            {vipSpaceArea * yearPlan[0].vipShare}
          </p>
          <PercentageInput
            fraction={yearPlan[0]?.vipShare || 0}
            onFractionChange={handleFractionChange(0, "vipShare")}
          />
          <BetterInput value={currentVipPrice} onChange={onBetterInputChange} />
        </div>
        <div className="h-[115px] w-[2px] bg-[#d9d9d9]"></div>
        <div className="flex flex-col gap-1">
          {[
            {
              textAr: `المساحه الإجمالية (الإجمالي: ${vipSpaceArea}م٢)`,
              textEn: "",
            },
            {
              textAr: "نسبة الاكتمال",
              textEn: "Basement floors cost (parking)",
            },
            {
              textAr: "متوسط سعر البيع/م٢",
              textEn: "Basement floors cost (parking)",
            },
          ].map((e, i) => (
            <p
              dir={lang === "ar" ? "rtl" : "ltr"}
              key={i}
              className="h-[34px] border-b-[2px] border-b-[#d9d9d9] p-1 text-left"
            >
              {t(e.textAr, e.textEn, lang)}
            </p>
          ))}
        </div>
      </div>

      <div className="flex flex-row-reverse gap-5 rounded-[8px] border-[4px] border-[#626468] px-14 py-5">
        <p>{t("إجمالي الأرباح", "Total profit", lang)}</p>
        <div className="h-7 w-[1px] bg-[#626468]"></div>
        <p>{parseFloat(firstYearRevenue.toFixed(2)).toLocaleString()}</p>
        <div className="h-7 w-[1px] bg-[#626468]"></div>
        <p>{parseFloat(secondYearRevenue.toFixed(2)).toLocaleString()}</p>
        <div className="h-7 w-[1px] bg-[#626468]"></div>
        <p>{parseFloat(thirdYearRevenue.toFixed(2)).toLocaleString()}</p>
      </div>
    </section>
  );
};

const MezzanineFloor = ({
  currentMezzaninePrice,
  setCurrentMezzaninePrice,
  yearPlan,
  setYearPlan,
  mezzanineSpaceArea,
  setTotalRevenues,
}) => {
  const { lang } = useLang();

  // Helper to update a specific field of a specific year
  const handleFractionChange = (yearIndex, fieldName) => (newFraction) => {
    setYearPlan((prev) => {
      const updated = [...prev];
      updated[yearIndex] = {
        ...updated[yearIndex],
        [fieldName]: newFraction,
      };
      return adjustShares(updated, fieldName);
    });
  };

  const onBetterInputChange = (value) => {
    setCurrentMezzaninePrice(value);
  };

  const secondYearPrice =
    currentMezzaninePrice * yearPlan[1]?.priceIncrease + currentMezzaninePrice;
  const thirdYearPrice =
    secondYearPrice * yearPlan[2]?.priceIncrease + secondYearPrice;

  // Revennues
  const firstYearRevenue =
    currentMezzaninePrice * yearPlan[0]?.mezzShare * mezzanineSpaceArea;

  const secondYearRevenue =
    secondYearPrice * yearPlan[1]?.mezzShare * mezzanineSpaceArea;

  const thirdYearRevenue =
    thirdYearPrice * yearPlan[2]?.mezzShare * mezzanineSpaceArea;

  useEffect(() => {
    setTotalRevenues((prev) => {
      const updated = [...prev];
      updated[0] = {
        ...updated[0],
        mezz: firstYearRevenue,
      };
      updated[1] = {
        ...updated[1],
        mezz: secondYearRevenue,
      };
      updated[2] = {
        ...updated[2],
        mezz: thirdYearRevenue,
      };
      return updated;
    });
  }, [firstYearRevenue, secondYearRevenue, thirdYearRevenue]);

  return (
    <section className="flex flex-col items-center gap-5">
      <div className="flex flex-col gap-2">
        <h3 className="text-center text-[30px] font-light">
          {t(": طابق الميزانين", "ٌMezzanine Floor:", lang)}
        </h3>
      </div>
      <div className="flex gap-5">
        <div className="flex flex-col gap-1">
          <p className="w-[100px] flex justify-center items-center gap-1 border-b-[2px] border-b-[#d9d9d9] bg-transparent p-1 focus:rounded-[4px] focus:border-2 focus:border-[#007aff] focus:outline-none text-center">
            <span>م²</span>
            {mezzanineSpaceArea * yearPlan[2].mezzShare}
          </p>
          <PercentageInput
            fraction={yearPlan[2]?.mezzShare || 0}
            onFractionChange={handleFractionChange(2, "mezzShare")}
          />
          <p className="w-[100px] flex justify-center items-center gap-1 border-b-[2px] border-b-[#d9d9d9] bg-transparent p-1 focus:rounded-[4px] focus:border-2 focus:border-[#007aff] focus:outline-none text-center">
            <span>ريال</span>
            {thirdYearPrice}
          </p>
        </div>
        <div className="h-[115px] w-[2px] bg-[#d9d9d9]"></div>
        <div className="flex flex-col gap-1">
          <p className="w-[100px] flex justify-center items-center gap-1 border-b-[2px] border-b-[#d9d9d9] bg-transparent p-1 focus:rounded-[4px] focus:border-2 focus:border-[#007aff] focus:outline-none text-center">
            <span>م²</span>
            {mezzanineSpaceArea * yearPlan[1].mezzShare}
          </p>
          <PercentageInput
            fraction={yearPlan[1]?.mezzShare || 0}
            onFractionChange={handleFractionChange(1, "mezzShare")}
          />
          <p className="w-[100px] flex justify-center items-center gap-1 border-b-[2px] border-b-[#d9d9d9] bg-transparent p-1 focus:rounded-[4px] focus:border-2 focus:border-[#007aff] focus:outline-none text-center">
            <span>ريال</span>
            {secondYearPrice}
          </p>
        </div>
        <div className="h-[115px] w-[2px] bg-[#d9d9d9]"></div>
        <div className="flex flex-col gap-1">
          <p className="w-[100px] flex justify-center items-center gap-1 border-b-[2px] border-b-[#d9d9d9] bg-transparent p-1 focus:rounded-[4px] focus:border-2 focus:border-[#007aff] focus:outline-none text-center">
            <span>م²</span>
            {mezzanineSpaceArea * yearPlan[0].mezzShare}
          </p>
          <PercentageInput
            fraction={yearPlan[0]?.mezzShare || 0}
            onFractionChange={handleFractionChange(0, "mezzShare")}
          />
          <BetterInput
            value={currentMezzaninePrice}
            onChange={onBetterInputChange}
          />
        </div>
        <div className="h-[115px] w-[2px] bg-[#d9d9d9]"></div>
        <div className="flex flex-col gap-1">
          {[
            {
              textAr: `المساحه الإجمالية (الإجمالي: ${mezzanineSpaceArea}م٢)`,
              textEn: "",
            },
            {
              textAr: "نسبة الاكتمال",
              textEn: "Basement floors cost (parking)",
            },
            {
              textAr: "متوسط سعر البيع/م٢",
              textEn: "Basement floors cost (parking)",
            },
          ].map((e, i) => (
            <p
              dir={lang === "ar" ? "rtl" : "ltr"}
              key={i}
              className="h-[34px] border-b-[2px] border-b-[#d9d9d9] p-1 text-left"
            >
              {t(e.textAr, e.textEn, lang)}
            </p>
          ))}
        </div>
      </div>

      <div className="flex flex-row-reverse gap-5 rounded-[8px] border-[4px] border-[#626468] px-14 py-5">
        <p>{t("إجمالي الأرباح", "Total profit", lang)}</p>
        <div className="h-7 w-[1px] bg-[#626468]"></div>
        <p>{parseFloat(firstYearRevenue.toFixed(2)).toLocaleString()}</p>
        <div className="h-7 w-[1px] bg-[#626468]"></div>
        <p>{parseFloat(secondYearRevenue.toFixed(2)).toLocaleString()}</p>
        <div className="h-7 w-[1px] bg-[#626468]"></div>
        <p>{parseFloat(thirdYearRevenue.toFixed(2)).toLocaleString()}</p>
      </div>
    </section>
  );
};

const AdministrationFloor = ({
  currentAdminPrice,
  setCurrentAdminPrice,
  yearPlan,
  setYearPlan,
  adminSpaceArea,
  rental = false,
  setTotalRevenues,
  name = "الطوابق الإدارية",
  type = "admin",
}) => {
  const { lang } = useLang();

  // Helper to update a specific field of a specific year
  const handleFractionChange = (yearIndex, fieldName) => (newFraction) => {
    setYearPlan((prev) => {
      const updated = [...prev];
      updated[yearIndex] = {
        ...updated[yearIndex],
        [fieldName]: newFraction,
      };
      return adjustShares(updated, fieldName, rental);
    });
  };

  const onBetterInputChange = (value) => {
    setCurrentAdminPrice(value);
  };

  const secondYearPrice =
    currentAdminPrice * yearPlan[1]?.priceIncrease + currentAdminPrice;
  const thirdYearPrice =
    secondYearPrice * yearPlan[2]?.priceIncrease + secondYearPrice;

  // Revennues
  const firstYearRevenue =
    currentAdminPrice * yearPlan[0]?.adminShare * adminSpaceArea;

  const secondYearRevenue =
    secondYearPrice * yearPlan[1]?.adminShare * adminSpaceArea;

  const thirdYearRevenue =
    thirdYearPrice * yearPlan[2]?.adminShare * adminSpaceArea;

  useEffect(() => {
    setTotalRevenues((prev) => {
      const updated = [...prev];
      if (type === "admin") {
        updated[0] = {
          ...updated[0],
          admin: firstYearRevenue,
        };
        updated[1] = {
          ...updated[1],
          admin: secondYearRevenue,
        };
        updated[2] = {
          ...updated[2],
          admin: thirdYearRevenue,
        };
      } else {
        updated[0] = {
          ...updated[0],
          second: firstYearRevenue,
        };
        updated[1] = {
          ...updated[1],
          second: secondYearRevenue,
        };
        updated[2] = {
          ...updated[2],
          second: thirdYearRevenue,
        };
      }
      return updated;
    });
  }, [firstYearRevenue, secondYearRevenue, thirdYearRevenue]);

  return (
    <section className="flex flex-col items-center gap-5">
      <div className="flex flex-col gap-2">
        <h3 className="text-center text-[40px] font-bold">{`:${name}`}</h3>
      </div>
      <div className="flex gap-5 text-[20px] ">
        <div className="flex flex-col gap-1">
          <p className="w-[100px]  flex justify-center items-center gap-1 border-b-[2px] border-b-[#d9d9d9] bg-transparent p-1 focus:rounded-[4px] focus:border-2 focus:border-[#007aff] focus:outline-none text-center">
            <span>م²</span>
            {(adminSpaceArea * yearPlan[2].adminShare).toFixed(2)}
          </p>
          <PercentageInput
            fraction={yearPlan[2]?.adminShare || 0}
            onFractionChange={handleFractionChange(2, "adminShare")}
          />
          <p className="w-[100px] flex justify-center items-center gap-1 border-b-[2px] border-b-[#d9d9d9] bg-transparent p-1 focus:rounded-[4px] focus:border-2 focus:border-[#007aff] focus:outline-none text-center">
            <span>ريال</span>
            {parseFloat(thirdYearPrice.toFixed(2)).toLocaleString()}
          </p>
        </div>
        <div className="h-[128px] w-[2px] bg-[#d9d9d9]"></div>
        <div className="flex flex-col gap-1">
          <p className="w-[100px] flex justify-center items-center gap-1 border-b-[2px] border-b-[#d9d9d9] bg-transparent p-1 focus:rounded-[4px] focus:border-2 focus:border-[#007aff] focus:outline-none text-center">
            <span>م²</span>
            {(adminSpaceArea * yearPlan[1].adminShare).toFixed(2)}
          </p>
          <PercentageInput
            fraction={yearPlan[1]?.adminShare || 0}
            onFractionChange={handleFractionChange(1, "adminShare")}
          />
          <p className="w-[100px] flex justify-center items-center gap-1 border-b-[2px] border-b-[#d9d9d9] bg-transparent p-1 focus:rounded-[4px] focus:border-2 focus:border-[#007aff] focus:outline-none text-center">
            <span>ريال</span>
            {parseFloat(secondYearPrice.toFixed(2)).toLocaleString()}
          </p>
        </div>
        <div className="h-[128px] w-[2px] bg-[#d9d9d9]"></div>
        <div className="flex flex-col gap-1">
          <p className="w-[100px] flex justify-center items-center gap-1 border-b-[2px] border-b-[#d9d9d9] bg-transparent p-1 focus:rounded-[4px] focus:border-2 focus:border-[#007aff] focus:outline-none text-center">
            <span>م²</span>
            {(adminSpaceArea * yearPlan[0].adminShare).toFixed(2)}
          </p>
          <PercentageInput
            fraction={yearPlan[0]?.adminShare || 0}
            onFractionChange={handleFractionChange(0, "adminShare")}
          />
          <BetterInput
            value={currentAdminPrice}
            onChange={onBetterInputChange}
          />
        </div>
        <div className="h-[128px] w-[2px] bg-[#d9d9d9]"></div>
        <div className="flex flex-col gap-1">
          {[
            {
              textAr: `المساحه الإجمالية (الإجمالي: ${parseFloat(
                adminSpaceArea
              ).toFixed(2)}م٢)`,
              textEn: "",
            },
            {
              textAr: "نسبة الاكتمال",
              textEn: "Basement floors cost (parking)",
            },
            {
              textAr: "متوسط سعر البيع/م٢",
              textEn: "Basement floors cost (parking)",
            },
          ].map((e, i) => (
            <p
              dir={lang === "ar" ? "rtl" : "ltr"}
              key={i}
              className="h-[40px] border-b-[2px] border-b-[#d9d9d9] p-1 text-left"
            >
              {t(e.textAr, e.textEn, lang)}
            </p>
          ))}
        </div>
      </div>

      <div className="flex flex-row-reverse text-[20px] gap-5 rounded-[8px] border-[4px] border-[#626468] px-14 py-5">
        <p>{t("إجمالي الأرباح", "Total profit", lang)}</p>
        <div className="h-7 w-[1px] bg-[#626468]"></div>
        <p>{parseFloat(firstYearRevenue.toFixed(2)).toLocaleString()}</p>
        <div className="h-7 w-[1px] bg-[#626468]"></div>
        <p>{parseFloat(secondYearRevenue.toFixed(2)).toLocaleString()}</p>
        <div className="h-7 w-[1px] bg-[#626468]"></div>
        <p>{parseFloat(thirdYearRevenue.toFixed(2)).toLocaleString()}</p>
      </div>
    </section>
  );
};
const TotalGrossProfit = ({ totalRevenue }) => {
  const { lang } = useLang();
  console.log(totalRevenue);
  return (
    <section className="flex h-[100px] flex-row-reverse items-center justify-center gap-3 bg-[#414651] text-[20px] font-bold text-white">
      <h1>{t(":اجمالي الربح الكلي", "total gross profit:", lang)}</h1>
      <div className="flex-col gap-5">
        <p className="text-center text-[10px] text-[#a0a3a8]">
          {t("السنة الاولى", "first year", lang)}
        </p>
        <p className="text-[20px]">
          {parseFloat(totalRevenue[0].total.toFixed(2)).toLocaleString()}
        </p>
      </div>
      <div className="flex-col gap-5">
        <p className="text-center text-[10px] text-[#a0a3a8]">
          {t("السنة الثانية", "first year", lang)}
        </p>
        <p className="text-[20px]">
          {parseFloat(totalRevenue[1].total.toFixed(2)).toLocaleString()}
        </p>
      </div>
      <div className="flex-col gap-5">
        <p className="text-center text-[10px] text-[#a0a3a8]">
          {t("السنة الثالثة", "first year", lang)}
        </p>
        <p className="text-[20px]">
          {parseFloat(totalRevenue[2].total.toFixed(2)).toLocaleString()}
        </p>
      </div>
    </section>
  );
};

const RentalIncome = ({ yearPlan, setYearPlan }) => {
  const { lang } = useLang();

  // Helper to update a specific field of a specific year
  const handleFractionChange = (yearIndex, fieldName) => (newFraction) => {
    setYearPlan((prev) => {
      const updated = [...prev];
      updated[yearIndex] = {
        ...updated[yearIndex],
        [fieldName]: newFraction,
      };
      return updated;
    });
  };

  return (
    <section className="flex flex-col items-center gap-5">
      <div className="flex flex-col gap-10">
        <h3 className="text-center text-[50px] font-bold">
          {t(":جدول الإيرادات", "Revenue table:", lang)}
        </h3>
        <p className="text-center text-[40px] font-bold">
          {t(":الإيرادات من الإيجار", "Revenue from sales:", lang)}
        </p>
      </div>

      <div className="flex gap-5 text-[20px]">
        {/* === Third Year === */}
        <div className="flex flex-col gap-1">
          <div
            className=" border-b-[2px] border-b-[#d9d9d9] bg-transparent p-1 
                          focus:rounded-[4px] focus:border-2 focus:border-[#007aff] 
                          focus:outline-none"
          >
            {t("السنة الثالثة", "third year", lang)}
          </div>
          <PercentageInput
            fraction={yearPlan[2]?.taxRate || 0}
            onFractionChange={handleFractionChange(2, "taxRate")}
          />
          <PercentageInput
            fraction={yearPlan[2]?.priceIncrease || 0}
            onFractionChange={handleFractionChange(2, "priceIncrease")}
          />
        </div>

        <div className="h-[128px] w-[2px] bg-[#d9d9d9]" />

        {/* === Second Year === */}
        <div className="flex flex-col gap-1">
          <div
            className=" border-b-[2px] border-b-[#d9d9d9] bg-transparent p-1 
                          focus:rounded-[4px] focus:border-2 focus:border-[#007aff] 
                          focus:outline-none"
          >
            {t("السنة الثانية", "second year", lang)}
          </div>
          <PercentageInput
            fraction={yearPlan[1]?.taxRate || 0}
            onFractionChange={handleFractionChange(1, "taxRate")}
          />
          <PercentageInput
            fraction={yearPlan[1]?.priceIncrease || 0}
            onFractionChange={handleFractionChange(1, "priceIncrease")}
          />
        </div>

        <div className="h-[128px] w-[2px] bg-[#d9d9d9]" />

        {/* === First Year === */}
        <div className="flex flex-col gap-1">
          <div
            className=" border-b-[2px] border-b-[#d9d9d9] bg-transparent p-1 
                          focus:rounded-[4px] focus:border-2 focus:border-[#007aff] 
                          focus:outline-none"
          >
            {t("السنة الاولى", "first year", lang)}
          </div>
          <PercentageInput
            fraction={yearPlan[0]?.taxRate || 0}
            onFractionChange={handleFractionChange(0, "taxRate")}
          />
          <PercentageInput
            fraction={yearPlan[0]?.priceIncrease || 0}
            onFractionChange={handleFractionChange(0, "priceIncrease")}
          />
        </div>

        <div className="h-[128px] w-[2px] bg-[#d9d9d9]" />

        {/* === Labels Column === */}
        <div className="flex flex-col gap-1">
          {[
            { textAr: "", textEn: "" },
            { textAr: "معدل الضريبة", textEn: "Tax rate" },
            { textAr: "زياده السعر", textEn: "Price increase" },
          ].map((e, i) => (
            <p
              key={i}
              className="h-[40px] border-b-[2px] border-b-[#d9d9d9] p-1 font-bold"
            >
              {t(e.textAr, e.textEn, lang)}
            </p>
          ))}
        </div>
      </div>
    </section>
  );
};

const Samerization = ({ totalCost, totalRevenue }) => {
  const { lang } = useLang();
  return (
    <section className="flex h-[500px] flex-col items-center justify-center gap-5 bg-[#414651]">
      <div className="text-[30px] text-white">
        {t("الملخص", "summerization", lang)}
      </div>
      <div className="flex flex-row-reverse items-center gap-5">
        <div className="text-center text-white">
          <p className="text-[15px] font-thin">
            {t("اجمالي التكاليف", "", lang)}
          </p>
          <p className="text-[25px]">
            {parseFloat(totalCost.toFixed(1)).toLocaleString()}
          </p>
        </div>
        <div className="h-[50px] w-[1px] bg-white"></div>
        <div className="text-center text-white">
          <p className="text-[15px] font-thin">
            {t("اجمالي الايرادات", "", lang)}
          </p>
          <p className="text-[25px]">
            {parseFloat(totalRevenue.toFixed(1)).toLocaleString()}
          </p>
        </div>
        <div className="h-[50px] w-[1px] bg-white"></div>
        <div className="text-center text-white">
          <p className="text-[15px] font-thin">
            {t(" صافي الربح (قبل الزكاة)", "", lang)}
          </p>
          <p className="text-[25px]">
            {parseFloat((totalRevenue - totalCost).toFixed(1)).toLocaleString()}
          </p>
        </div>
        <div className="h-[50px] w-[1px] bg-white"></div>
        <div className="text-center text-white">
          <p className="text-[15px] font-thin">
            {t("%صافي الربح في", "", lang)}
          </p>
          <p className="text-[25px]">{`${parseFloat(
            (((totalRevenue - totalCost) / totalCost) * 100).toFixed(1)
          ).toLocaleString()}%`}</p>
        </div>
        <div className="h-[50px] w-[1px] bg-white"></div>
        <div className="text-center text-white">
          <p className="text-[15px] font-thin">
            {t("عائد الاستثمار", "", lang)}
          </p>
          <p className="text-[25px]">{`${parseFloat(
            (((totalRevenue - totalCost) / totalCost) * 100).toFixed(1)
          ).toLocaleString()}%`}</p>
        </div>
      </div>
    </section>
  );
};

const RentalSamerization = ({ totalCost, totalRevenue }) => {
  const { lang } = useLang();
  return (
    <section className="flex h-[500px] flex-col items-center justify-center gap-5 bg-[#414651]">
      <div className="text-[30px] text-white">
        {t("الملخص", "summerization", lang)}
      </div>
      <div className="flex flex-row-reverse items-center gap-5">
        <div className="text-center text-white">
          <p className="text-[15px] font-thin">
            {t("اجمالي التكاليف", "", lang)}
          </p>
          <p className="text-[25px]">
            {parseFloat(totalCost.toFixed(1)).toLocaleString()}
          </p>
        </div>
        <div className="h-[50px] w-[1px] bg-white"></div>
        <div className="text-center text-white">
          <p className="text-[15px] font-thin">
            {t("اجمالي الايرادات", "", lang)}
          </p>
          <p className="text-[25px]">
            {parseFloat(totalRevenue.toFixed(1)).toLocaleString()}
          </p>
        </div>
        <div className="h-[50px] w-[1px] bg-white"></div>
        <div className="text-center text-white">
          <p className="text-[15px] font-thin">
            {t(" صافي الربح (قبل الزكاة)", "", lang)}
          </p>
          <p className="text-[25px]">
            {parseFloat((totalRevenue - totalCost).toFixed(1)).toLocaleString()}
          </p>
        </div>
        <div className="h-[50px] w-[1px] bg-white"></div>
        <div className="text-center text-white">
          <p className="text-[15px] font-thin">
            {t("%صافي الربح في", "", lang)}
          </p>
          <p className="text-[25px]">{`${parseFloat(
            (((totalRevenue - totalCost) / totalCost) * 100).toFixed(1)
          ).toLocaleString()}%`}</p>
        </div>
        <div className="h-[50px] w-[1px] bg-white"></div>
        <div className="text-center text-white">
          <p className="text-[15px] font-thin">
            {t("عائد الاثتثمار", "", lang)}
          </p>
          <p className="text-[25px]">{`${parseFloat(
            (((totalRevenue - totalCost) / totalCost) * 100).toFixed(1)
          ).toLocaleString()}%`}</p>
        </div>
      </div>
    </section>
  );
};

const ProfitAndCostAreaChartCard = ({ totalCost, totalRevenue }) => {
  const { lang } = useLang();

  // 1. Build the standard chart data using a numeric "x" field and a display "name" field.
  let cumulative = 0;
  const chartDataWithoutBreakEven = totalRevenue.map((rev, index) => {
    cumulative += rev.total;
    const yearNumber = index + 1;
    return {
      x: yearNumber, // numeric value for positioning
      uv: parseFloat(totalCost.toFixed(2)),
      pv: parseFloat(cumulative.toFixed(2)),
      name: `سنة ${yearNumber}`, // display name
    };
  });

  // 2. Determine the break-even point.
  // Loop through totalRevenue and compute the exact fractional year when cumulative revenue >= totalCost.
  let breakEven = null;
  cumulative = 0;
  for (let i = 0; i < totalRevenue.length; i++) {
    const annualRevenue = totalRevenue[i].total;
    const prevCumulative = cumulative;
    cumulative += annualRevenue;
    if (cumulative >= totalCost) {
      const fraction = (totalCost - prevCumulative) / annualRevenue;
      // If years are 1-indexed, breakEven will be:
      breakEven = i + fraction + 1 - 1;
      // (Here, if i = 0 then breakEven = fraction; adjust if you prefer to label the first year as "سنة 1")
      break;
    }
  }

  // 3. Create the final chart data by inserting the break-even point.
  let chartData = [...chartDataWithoutBreakEven];
  if (breakEven !== null) {
    chartData.push({
      x: breakEven.toFixed(1),
      uv: parseFloat(totalCost.toFixed(2)),
      pv: parseFloat(totalCost.toFixed(2)),
      // You can also add an optional flag or label field if needed
      isBreakEven: true,
      name: `سنة ${breakEven.toFixed(1)}`,
    });
    // Sort the data by the numeric x value so the line is continuous.
    chartData.sort((a, b) => a.x - b.x);
  }

  // 4. (Optional) Calculate total profit.
  const totalProfit =
    totalRevenue.reduce((acc, rev) => acc + rev.total, 0) - totalCost;

  return (
    <div className="gap flex w-full flex-col rounded-[8px] px-[26px] py-[36px] shadow-[0px_0.841px_1.682px_-0.841px_rgba(0,0,0,0.10),0px_-0.841px_2.524px_0px_rgba(0,0,0,0.10)] md:w-[65%]">
      <div className="mb-5 flex flex-col gap-2">
        <div
          dir={lang === "ar" ? "rtl" : "ltr"}
          className="flex items-center justify-between"
        >
          <div>
            <h4 className="text-[16px] font-semibold text-[#0E1726]">
              {t("الربح و التكلفة", "Profit and Cost", lang)}
            </h4>
            <p className="ga flex items-center gap-2">
              <span>{t("إجمال الربح", "Total Profit", lang)}</span>
              <span className="text-[#4361EE]">
                {totalProfit.toLocaleString()}
              </span>
            </p>
          </div>
          <div className="bg-white flex flex-row-reverse items-center gap-2">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <button
                  title="options"
                  className="rounded-lg p-1 duration-300 hover:bg-gray-200"
                >
                  <Ellipsis />
                </button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem>
                  <Link to={""} className="flex w-full items-center gap-2">
                    {t("عرض", "View", lang)}
                  </Link>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
        <div className="flex items-center gap-[25px]">
          <div className="flex items-center gap-[5px]">
            <p>{t("الدخل", "Income", lang)}</p>
            <div className="h-2 w-2 rounded-full bg-[#4361EE]"></div>
          </div>
          <div className="flex items-center gap-[5px]">
            <p>{t("التكلفة", "Cost", lang)}</p>
            <div className="h-2 w-2 rounded-full bg-[#E7515A]"></div>
          </div>
        </div>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          data={chartData}
          margin={{ top: 10, right: 30, left: 30, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#E7515A" stopOpacity={0.2} />
              <stop offset="100%" stopColor="#E7515A" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#4361EE" stopOpacity={0.2} />
              <stop offset="100%" stopColor="#4361EE" stopOpacity={0} />
            </linearGradient>
          </defs>
          {/* Use the numeric "x" field for the XAxis */}
          <XAxis
            dataKey="x"
            type="number"
            domain={["dataMin", "dataMax"]}
            tickFormatter={(value) =>
              `سنة ${value % 1 === 0 ? value : value.toFixed(1)}`
            }
            axisLine={false}
          />
          <YAxis
            orientation="right"
            axisLine={false}
            tickFormatter={(tick) => {
              const { number, abbreviationEn, abbreviationAr } =
                HandleBigNumbers(tick);
              return lang === "en"
                ? `${number}${abbreviationEn}`
                : `${number}${abbreviationAr}`;
            }}
          />
          <CartesianGrid
            horizontal={false}
            vertical={true}
            strokeDasharray="3 3"
          />
          <Tooltip />
          {/* Cost line */}
          <Area
            type="monotone"
            dataKey="uv"
            stroke="#E7515A"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
          {/* Cumulative revenue line */}
          <Area
            type="monotone"
            dataKey="pv"
            stroke="#4361EE"
            fillOpacity={1}
            fill="url(#colorPv)"
          />
          {/* ReferenceDot to highlight the break-even point */}
          {breakEven !== null && (
            <ReferenceDot
              x={breakEven.toFixed(1)}
              y={totalCost}
              r={8}
              fill="green"
              stroke="black"
              label={{
                value: `نقطة التعادل: ${breakEven.toFixed(1)} سنة`,
                position: "top",
                fill: "green",
              }}
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

function RegenerateImages({ images = [1, 1], index = 0 }) {
  const [img, setImg] = useState(null);
  // const [loading,setLoading] = useState()
  const [loading, setLoading] = useState(false);

  return (
    <div className="flex w-full flex-col gap-2">
      <div className="bg-neutral-50 flex justify-center items-center border-neutral-300 py-4 dark:border-neutral-700 dark:bg-neutral-900 rounded-md border">
        <img
          style={{ width: "60%" }}
          src={img || images[index]}
          alt="Generated"
        />
      </div>
    </div>
  );
}

export default function ForsaInvestments() {
  const location = useLocation();

  const locationState = location?.state;

  console.log("locationState: ", locationState);

  const images = locationState?.images;
  const map = locationState?.mapUrl;
  const projectType = locationState?.projectType;
  const firstTypeFloors = locationState?.firstTypeFloors;
  const { photo } = useMapContext();

  // -------------------------------------
  // 1) MAIN INPUTS
  // -------------------------------------
  const mixedUse =
    (projectType.includes("إداري") && projectType.includes("تجاري")) ||
    (projectType.includes("تجاري") && projectType.includes("سكني"));

  const isCompound = projectType.includes("مجمع");
  const isVillaCompound = projectType.includes("فلل");
  const isVilla = projectType.includes("فيلا");

  const firstPriceIncreaseFlag =
    projectType.includes("تجاري") || projectType.includes("إداري");
  const secondPriceIncreaseFlag =
    mixedUse && projectType.includes("تجاري إداري");

  const firstFloorSingleName =
    projectType.includes("إداري") && projectType.includes("تجاري")
      ? "التجاري"
      : projectType.includes("تجاري") && projectType.includes("سكني")
      ? "التجاري"
      : projectType.includes("إداري")
      ? "الإداري"
      : projectType.includes("تجاري")
      ? "التجاري"
      : projectType.includes("سكني")
      ? "السكني"
      : projectType.includes("فندقي")
      ? "الفندقي"
      : "الإداري";

  const secondFloorSingleName =
    projectType.includes("إداري") && projectType.includes("تجاري")
      ? "الإداري"
      : projectType.includes("تجاري") && projectType.includes("سكني")
      ? "السكني"
      : "";

  const [inputs, setInputs] = useState({
    // Basic Info
    basicInfo: {
      totalFloors: {
        value: locationState?.no_of_floors,
        arabic: "عدد الطوابق",
      },
      firstFloors: {
        value: mixedUse ? firstTypeFloors - 1 : locationState?.no_of_floors - 1,
        arabic: `الطوابق ${
          projectType.includes("إداري") && projectType.includes("تجاري")
            ? "التجارية"
            : projectType.includes("تجاري") && projectType.includes("سكني")
            ? "التجارية"
            : projectType.includes("إداري")
            ? "الإدارية"
            : projectType.includes("تجاري")
            ? "التجارية"
            : projectType.includes("سكني")
            ? "السكنية"
            : projectType.includes("فندقي")
            ? "الفندقية"
            : "الإدارية"
        }`,
      },
      secondFloors: {
        value: mixedUse ? locationState?.no_of_floors - firstTypeFloors : 0,
        arabic: `الطوابق ${
          projectType.includes("إداري") && projectType.includes("تجاري")
            ? "الإدارية"
            : projectType.includes("تجاري") && projectType.includes("سكني")
            ? "السكنية"
            : ""
        }`,
      },
      roofFloorArea: {
        value: 1,
        arabic: `الملحق العلوي`,
      },
      groundFloor: {
        value: 1,
        arabic: "الطابق الأرضي",
      },
      basementFloors: {
        value: projectType.includes("برج") ? 4 : 1,
        arabic: "عدد طوابق الطابق السفلي (مواقف السيارات)",
      },
    },

    // Spaces
    spaces: {
      totalLandArea: {
        value: locationState?.landArea,
        arabic: "إجمالي مساحة الأرض (م²)",
      },
      groundFloorArea: {
        value: locationState?.landArea * locationState?.ground_percentage,
        arabic: "مساحة الطابق الأرضي (م²)",
      },
      firstFloorArea: {
        value: locationState?.landArea * locationState?.technical_percentage,
        arabic: `مساحة الطابق ${firstFloorSingleName} (م²)`,
      },
      secondFloorArea: {
        value: mixedUse
          ? locationState?.landArea * locationState?.technical_percentage
          : 0,
        arabic: `مساحة الطابق ${secondFloorSingleName} (م²)`,
      },
      roofFloorArea: {
        value:
          locationState?.landArea *
          locationState?.technical_percentage *
          locationState?.roof_floor_percentage,
        arabic: `مساحة الملحق العلوي (م²)`,
      },
      basementFloorArea: {
        value: locationState?.landArea,
        arabic: "مساحة الطابق السفلي (م²)",
      },
      sharedAreas: {
        value:
          locationState?.landArea -
          locationState?.landArea * locationState?.ground_percentage,
        arabic: "المساحات المشتركة (م²)",
      },
    },

    // Cost Schedule
    costSchedule: {
      fixedCost: {
        pricePerSqMeter: {
          value: locationState?.landPrice,
          arabic: "سعر المتر المربع (ريال/م²)",
        },
      },
      variableCost: {
        administrativeCost: {
          value: locationState?.constructionCostPerSqm,
          arabic: "تكلفة البناء (ريال/م²)",
        },
        parkingCost: {
          value: 1000,
          arabic: "تكلفة المواقف (ريال/م²)",
        },
        sharedAreasCost: {
          value: 300,
          arabic: "تكلفة المساحات المشتركة (ريال/م²)",
        },
        salesCommissionPct: {
          value: 5, // 5% of total revenue
          arabic: "نسبة عمولة المبيعات (%)",
        },
      },
    },
  });

  const [buildingArea, setBuildingArea] = useState(350);

  const [compoundInputs, setCompoundInputs] = useState({
    // Basic Info
    basicInfo: {
      totalBuildings: {
        value: locationState?.landArea / buildingArea,
        arabic: "عدد المباني",
      },
      perBuildingInfo: {
        value: "",
        arabic: "بيانات المباني",
      },
      totalFloors: {
        value: locationState?.no_of_floors,
        arabic: "عدد الطوابق",
      },
      firstFloors: {
        value: locationState?.no_of_floors - 1,
        arabic: `الطوابق السكنية`,
      },
      roofFloorArea: {
        value: 1,
        arabic: `الملحق العلوي`,
      },
      groundFloor: {
        value: 1,
        arabic: "الطابق الأرضي",
      },
      basementFloors: {
        value: 1,
        arabic: "عدد طوابق الطابق السفلي (مواقف السيارات)",
      },
    },

    // Spaces
    spaces: {
      totalLandArea: {
        value: locationState?.landArea,
        arabic: "إجمالي مساحة الأرض (م²)",
      },
      buildingArea: {
        value: buildingArea,
        arabic: "مساحة المباني (م²)",
      },
      groundFloorArea: {
        value: buildingArea * locationState?.ground_percentage,
        arabic: "مساحة الطابق الأرضي (م²)",
      },
      firstFloorArea: {
        value: buildingArea * locationState?.technical_percentage,
        arabic: `مساحة الطابق ${"السكني"} (م²)`,
      },
      roofFloorArea: {
        value:
          buildingArea *
          locationState?.technical_percentage *
          locationState?.roof_floor_percentage,
        arabic: `مساحة الملحق العلوي (م²)`,
      },
      basementFloorArea: {
        value: buildingArea,
        arabic: "مساحة الطابق السفلي (م²)",
      },
      sharedAreas: {
        value: buildingArea - buildingArea * locationState?.ground_percentage,
        arabic: "المساحات المشتركة للمبنى (م²)",
      },
    },

    // Cost Schedule
    costSchedule: {
      fixedCost: {
        pricePerSqMeter: {
          value: locationState?.landPrice,
          arabic: "سعر المتر المربع (ريال/م²)",
        },
      },
      variableCost: {
        administrativeCost: {
          value: locationState?.constructionCostPerSqm,
          arabic: "تكلفة البناء (ريال/م²)",
        },
        parkingCost: {
          value: 1000,
          arabic: "تكلفة المواقف (ريال/م²)",
        },
        sharedAreasCost: {
          value: 300,
          arabic: "تكلفة المساحات المشتركة (ريال/م²)",
        },
        salesCommissionPct: {
          value: 5, // 5% of total revenue
          arabic: "نسبة عمولة المبيعات (%)",
        },
      },
    },
  });

  useEffect(() => {
    setCompoundInputs((prev) => ({
      ...prev,
      // Update Basic Info if needed
      basicInfo: {
        ...prev.basicInfo,
        totalBuildings: {
          ...prev.basicInfo.totalBuildings,
          value: locationState?.landArea / buildingArea,
        },
      },
      // Update Spaces calculations that depend on buildingArea
      spaces: {
        ...prev.spaces,
        buildingArea: {
          ...prev.spaces.buildingArea,
          value: buildingArea,
        },
        groundFloorArea: {
          ...prev.spaces.groundFloorArea,
          value: buildingArea * locationState?.ground_percentage,
        },
        firstFloorArea: {
          ...prev.spaces.firstFloorArea,
          value: buildingArea * locationState?.technical_percentage,
        },
        roofFloorArea: {
          ...prev.spaces.roofFloorArea,
          value:
            buildingArea *
            locationState?.technical_percentage *
            locationState?.roof_floor_percentage,
        },
        basementFloorArea: {
          ...prev.spaces.basementFloorArea,
          value: buildingArea,
        },
        sharedAreas: {
          ...prev.spaces.sharedAreas,
          value: buildingArea - buildingArea * locationState?.ground_percentage,
        },
      },
    }));
  }, [buildingArea, locationState]);

  const [VillaArea, setVillaArea] = useState(300);

  const [villaCompoundInputs, setVillaCompoundInputs] = useState({
    // Basic Info
    basicInfo: {
      totalBuildings: {
        value: locationState?.landArea / VillaArea,
        arabic: "عدد الفلل",
      },
      perBuildingInfo: {
        value: "",
        arabic: "بيانات الفيلا",
      },
      totalFloors: {
        value: locationState?.no_of_floors,
        arabic: "عدد الطوابق",
      },
      firstFloors: {
        value: locationState?.no_of_floors - 1,
        arabic: `الطوابق المتكررة`,
      },
      roofFloorArea: {
        value: 1,
        arabic: `الملحق العلوي`,
      },
      groundFloor: {
        value: 1,
        arabic: "الطابق الأرضي",
      },
      basementFloors: {
        value: 1,
        arabic: "عدد طوابق الطابق السفلي (مواقف السيارات)",
      },
    },

    // Spaces
    spaces: {
      totalLandArea: {
        value: locationState?.landArea,
        arabic: "إجمالي مساحة الأرض (م²)",
      },
      VillaArea: {
        value: VillaArea,
        arabic: "مساحة الفيلا (م²)",
      },
      groundFloorArea: {
        value: VillaArea * locationState?.ground_percentage,
        arabic: "مساحة الطابق الأرضي (م²)",
      },
      firstFloorArea: {
        value: VillaArea * locationState?.technical_percentage,
        arabic: `مساحة الطابق ${"المتكرر"} (م²)`,
      },
      roofFloorArea: {
        value:
          VillaArea *
          locationState?.technical_percentage *
          locationState?.roof_floor_percentage,
        arabic: `مساحة الملحق العلوي (م²)`,
      },
      basementFloorArea: {
        value: VillaArea,
        arabic: "مساحة الطابق السفلي (م²)",
      },
      sharedAreas: {
        value: VillaArea - VillaArea * locationState?.ground_percentage,
        arabic: "المساحات المشتركة للمبنى (م²)",
      },
    },

    // Cost Schedule
    costSchedule: {
      fixedCost: {
        pricePerSqMeter: {
          value: locationState?.landPrice,
          arabic: "سعر المتر المربع (ريال/م²)",
        },
      },
      variableCost: {
        administrativeCost: {
          value: locationState?.constructionCostPerSqm,
          arabic: "تكلفة البناء (ريال/م²)",
        },
        parkingCost: {
          value: 1000,
          arabic: "تكلفة المواقف (ريال/م²)",
        },
        sharedAreasCost: {
          value: 300,
          arabic: "تكلفة المساحات المشتركة (ريال/م²)",
        },
        salesCommissionPct: {
          value: 5, // 5% of total revenue
          arabic: "نسبة عمولة المبيعات (%)",
        },
      },
    },
  });

  useEffect(() => {
    setVillaCompoundInputs((prev) => ({
      ...prev,
      // Update Basic Info if needed
      basicInfo: {
        ...prev.basicInfo,
        totalBuildings: {
          ...prev.basicInfo.totalBuildings,
          value: locationState?.landArea / VillaArea,
        },
      },
      // Update Spaces calculations that depend on buildingArea
      spaces: {
        ...prev.spaces,
        VillaArea: {
          ...prev.spaces.VillaArea,
          value: VillaArea,
        },
        groundFloorArea: {
          ...prev.spaces.groundFloorArea,
          value: VillaArea * locationState?.ground_percentage,
        },
        firstFloorArea: {
          ...prev.spaces.firstFloorArea,
          value: VillaArea * locationState?.technical_percentage,
        },
        roofFloorArea: {
          ...prev.spaces.roofFloorArea,
          value:
            VillaArea *
            locationState?.technical_percentage *
            locationState?.roof_floor_percentage,
        },
        basementFloorArea: {
          ...prev.spaces.basementFloorArea,
          value: VillaArea,
        },
        sharedAreas: {
          ...prev.spaces.sharedAreas,
          value: VillaArea - VillaArea * locationState?.ground_percentage,
        },
      },
    }));
  }, [VillaArea, locationState]);

  const [villaInputs, setVillaInputs] = useState({
    // Basic Info
    basicInfo: {
      totalFloors: {
        value: locationState?.no_of_floors,
        arabic: "عدد الطوابق",
      },
      firstFloors: {
        value: locationState?.no_of_floors - 1,
        arabic: `الطوابق المتكررة`,
      },
      roofFloorArea: {
        value: 1,
        arabic: `الملحق العلوي`,
      },
      groundFloor: {
        value: 1,
        arabic: "الطابق الأرضي",
      },
      basementFloors: {
        value: 1,
        arabic: "عدد طوابق الطابق السفلي (مواقف السيارات)",
      },
    },

    // Spaces
    spaces: {
      totalLandArea: {
        value: locationState?.landArea,
        arabic: "إجمالي مساحة الأرض (م²)",
      },
      sinlgeVillaArea: {
        value:
          locationState?.landArea * locationState?.ground_percentage +
          locationState?.landArea *
            locationState?.technical_percentage *
            (locationState?.no_of_floors - 1) +
          locationState?.landArea *
            locationState?.technical_percentage *
            locationState?.roof_floor_percentage,
        arabic: "مساحة الفيلا (م²)",
      },
      groundFloorArea: {
        value: locationState?.landArea * locationState?.ground_percentage,
        arabic: "مساحة الطابق الأرضي (م²)",
      },
      firstFloorArea: {
        value: locationState?.landArea * locationState?.technical_percentage,
        arabic: `مساحة الطابق ${"المتكرر"} (م²)`,
      },
      roofFloorArea: {
        value:
          locationState?.landArea *
          locationState?.technical_percentage *
          locationState?.roof_floor_percentage,
        arabic: `مساحة الملحق العلوي (م²)`,
      },
      basementFloorArea: {
        value: locationState?.landArea * locationState?.ground_percentage,
        arabic: "مساحة الطابق السفلي (م²)",
      },
      sharedAreas: {
        value:
          locationState?.landArea -
          locationState?.landArea * locationState?.ground_percentage,
        arabic: "المساحات المشتركة للمبنى (م²)",
      },
    },

    // Cost Schedule
    costSchedule: {
      fixedCost: {
        pricePerSqMeter: {
          value: locationState?.landPrice,
          arabic: "سعر المتر المربع (ريال/م²)",
        },
      },
      variableCost: {
        administrativeCost: {
          value: locationState?.constructionCostPerSqm,
          arabic: "تكلفة البناء (ريال/م²)",
        },
        parkingCost: {
          value: 1000,
          arabic: "تكلفة المواقف (ريال/م²)",
        },
        sharedAreasCost: {
          value: 300,
          arabic: "تكلفة المساحات المشتركة (ريال/م²)",
        },
        salesCommissionPct: {
          value: 5, // 5% of total revenue
          arabic: "نسبة عمولة المبيعات (%)",
        },
      },
    },
  });

  // useEffect(() => {
  //   setVillaInputs((prev) => ({
  //     ...prev,
  //     // Update Basic Info if needed
  //     basicInfo: {
  //       ...prev.basicInfo,
  //       totalBuildings: {
  //         ...prev.basicInfo.totalBuildings,
  //         value: locationState?.landArea / VillaArea,
  //       },
  //     },
  //     // Update Spaces calculations that depend on buildingArea
  //     spaces: {
  //       ...prev.spaces,
  //       VillaArea: {
  //         ...prev.spaces.VillaArea,
  //         value: VillaArea,
  //       },
  //       groundFloorArea: {
  //         ...prev.spaces.groundFloorArea,
  //         value: VillaArea * locationState?.ground_percentage,
  //       },
  //       firstFloorArea: {
  //         ...prev.spaces.firstFloorArea,
  //         value: VillaArea * locationState?.technical_percentage,
  //       },
  //       roofFloorArea: {
  //         ...prev.spaces.roofFloorArea,
  //         value:
  //           VillaArea *
  //           locationState?.technical_percentage *
  //           locationState?.roof_floor_percentage,
  //       },
  //       basementFloorArea: {
  //         ...prev.spaces.basementFloorArea,
  //         value: VillaArea,
  //       },
  //       sharedAreas: {
  //         ...prev.spaces.sharedAreas,
  //         value: VillaArea - VillaArea * locationState?.ground_percentage,
  //       },
  //     },
  //   }));
  // }, [VillaArea, locationState]);

  const handleInputChange = (e, isFloat = false) => {
    const { name, value } = e.target;
    setInputs((prev) => ({
      ...prev,
      [name]: isFloat ? parseFloat(value) : parseInt(value, 10),
    }));
  };

  // -------------------------------------
  // 2) YEAR-BY-YEAR PLAN
  // -------------------------------------
  const [planYears, setPlanYears] = useState(5);
  const [yearPlan, setYearPlan] = useState([
    {
      year: 2025,
      priceIncrease: 0.08,
      adminShare: isVilla ? 1 : 0.5,
      secondShare: 0.5,
      taxRate: 0.14,
    },
    {
      year: 2026,
      priceIncrease: 0.08,
      adminShare: isVilla ? 0 : 0.4,
      secondShare: 0.4,
      taxRate: 0.14,
    },
    {
      year: 2027,
      priceIncrease: 0.08,
      adminShare: isVilla ? 0 : 0.1,
      secondShare: 0.1,
      taxRate: 0.14,
    },
  ]);
  const [rentalYearPlan, setRentalYearPlan] = useState([
    {
      year: 2025,
      priceIncrease: 0.08,
      adminShare: 1,
      secondShare: 1,
      taxRate: 0.14,
    },
    {
      year: 2026,
      priceIncrease: 0.08,
      adminShare: 1,
      secondShare: 1,
      taxRate: 0.14,
    },
    {
      year: 2027,
      priceIncrease: 0.08,
      adminShare: 1,
      secondShare: 1,
      taxRate: 0.14,
    },
  ]);

  // Let user adjust how many plan years
  const handlePlanYearsChange = (e) => {
    const newCount = parseInt(e.target.value, 10);
    setPlanYears(newCount);

    setYearPlan((prev) => {
      const diff = newCount - prev.length;
      if (diff > 0) {
        // add extra
        const lastYear = prev.length ? prev[prev.length - 1].year : 2025;
        const newRows = Array.from({ length: diff }, (_, idx) => ({
          year: lastYear + idx + 1,
          priceIncrease: 0.08,
          vipShare: 0,
          mezzShare: 0,
          adminShare: 1.0,
        }));
        return [...prev, ...newRows];
      }
      if (diff < 0) {
        // remove
        return prev.slice(0, newCount);
      }
      return prev;
    });
  };

  // Handler for changing a single row/field
  const handleYearPlanChange = (index, field, value) => {
    const updated = [...yearPlan];
    updated[index][field] = parseFloat(value);
    setYearPlan(updated);
  };

  // -------------------------------------
  // 3) EXTRA FIELDS for Valuation, Taxes, etc.
  // -------------------------------------
  const [discountFactor, setDiscountFactor] = useState(7.0); // e.g. 7%
  const [propertyTransferTax, setPropertyTransferTax] = useState(5.0);
  const [vatRate, setVatRate] = useState(15.0);
  const [zakatRate, setZakatRate] = useState(2.5);
  const [municipalFees, setMunicipalFees] = useState(0.0);
  const [currentAdminPrice, setCurrentAdminPrice] = useState(
    firstPriceIncreaseFlag
      ? locationState?.sellingPrice + locationState?.sellingPrice / 3
      : locationState?.sellingPrice
  );

  const [currentSecondFloorPrice, setCurrentSecondFloorPrice] = useState(
    secondPriceIncreaseFlag
      ? locationState?.sellingPrice + locationState?.sellingPrice / 3
      : locationState?.sellingPrice
  );

  const [currentRentalAdminPrice, setCurrentRentalAdminPrice] = useState(
    firstPriceIncreaseFlag
      ? inputs.costSchedule.fixedCost.pricePerSqMeter.value * 0.18
      : inputs.costSchedule.fixedCost.pricePerSqMeter.value * 0.09
  );

  const [currentRentalSecondFloorPrice, setCurrentRentalSecondFloorPrice] =
    useState(
      secondPriceIncreaseFlag
        ? inputs.costSchedule.fixedCost.pricePerSqMeter.value * 0.18
        : inputs.costSchedule.fixedCost.pricePerSqMeter.value * 0.09
    );

  const [CurrentSalesCommission, setCurrentSalesCommission] = useState(0);
  const [totalVariableCost, setTotalVariableCost] = useState(0);
  const [totalFixedCost, setTotalFixedCost] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState([
    {
      total: 0,
    },
    {
      total: 0,
    },
    {
      total: 0,
    },
  ]);
  const [totalRentalRevenue, setTotalRentalRevenue] = useState([
    {
      total: 0,
    },
    {
      total: 0,
    },
    {
      total: 0,
    },
  ]);
  const [totalRevenues, setTotalRevenues] = useState([
    {
      admin: 0,
      second: 0,
    },
    {
      admin: 0,
      second: 0,
    },
    {
      admin: 0,
      second: 0,
    },
  ]);
  const [totalRentalRevenues, setTotalRentalRevenues] = useState([
    {
      admin: 0,
      second: 0,
    },
    {
      admin: 0,
      second: 0,
    },
    {
      admin: 0,
      second: 0,
    },
  ]);

  // -------------------------------------
  // 4) CORE CALCULATIONS
  // -------------------------------------
  const calculations = useMemo(() => {
    // (A) COSTS
    const totalAdminArea =
      inputs.spaces.firstFloorArea * inputs.basicInfo.firstFloors +
      inputs.spaces.secondFloorArea * inputs.basicInfo.secondFloors +
      inputs.spaces.groundFloorArea +
      inputs.spaces.roofFloorArea;
    const totalBasementArea =
      inputs.spaces.basementFloorArea * inputs.basicInfo.basementFloors;
    const landCost =
      inputs.spaces.totalLandArea *
      inputs.costSchedule.fixedCost.pricePerSqMeter;

    const adminFloorsCost =
      totalAdminArea * inputs.costSchedule.variableCost.administrativeCost;
    const basementFloorsCost =
      totalBasementArea * inputs.costSchedule.variableCost.parkingCost;
    const technicalAreaCost =
      inputs.spaces.technicalFloorArea *
      inputs.costSchedule.variableCost.technicalFloorsCost;
    const sharedAreasCost =
      inputs.spaces.sharedAreas *
      inputs.costSchedule.variableCost.sharedAreasCost;

    // We'll approximate that salesCommission is % of total revenue, so we compute it after revenue.

    // (B) REVENUES (Year by Year)
    let vCurrentAdminPrice = currentAdminPrice;
    let vCurrentSecondFloorPrice = currentSecondFloorPrice;

    let totalRevenues = 0;
    const yearlyData = [];
    let sumVip = 0;
    let sumMezz = 0;
    let sumAdmin = 0;

    for (let i = 0; i < yearPlan.length; i++) {
      const {
        year,
        priceIncrease,
        vipShare,
        mezzShare,
        adminShare,
        secondShare,
      } = yearPlan[i];
      if (i > 0) {
        vCurrentAdminPrice *= 1 + priceIncrease;
        vCurrentSecondFloorPrice *= 1 + priceIncrease;
      }

      const currentAdminArea =
        (inputs.spaces.firstFloorArea.value *
          inputs.basicInfo.firstFloors.value +
          inputs.spaces.groundFloorArea.value) *
        adminShare;

      const currentSecondFloorArea =
        inputs.spaces.secondFloorArea.value *
        inputs.basicInfo.secondFloors.value *
        secondShare;

      const adminRevenue =
        currentAdminArea * vCurrentAdminPrice +
        currentSecondFloorArea * vCurrentSecondFloorPrice;

      const totalForYear = adminRevenue;
      totalRevenues += totalForYear;

      sumVip += vipShare;
      sumMezz += mezzShare;
      sumAdmin += adminShare;

      yearlyData.push({
        year,
        administrative: adminRevenue,
        vCurrentAdminPrice,
        vCurrentSecondFloorPrice,
        currentAdminArea,
        total: totalForYear,
      });
    }

    // Now we can compute the sales commission
    const salesCommission =
      (inputs.costSchedule.variableCost.salesCommissionPct.value / 100) *
      totalRevenues;
    setCurrentSalesCommission(salesCommission);

    const totalVariableCost =
      adminFloorsCost +
      basementFloorsCost +
      technicalAreaCost +
      sharedAreasCost +
      salesCommission;
    const totalCost = landCost + totalVariableCost;

    const netOperatingProfit = totalRevenues - totalCost;
    const netOperatingProfitPercent = totalRevenues
      ? (netOperatingProfit / totalRevenues).toFixed(2) * 100
      : 0;
    const roi = totalCost
      ? ((netOperatingProfit / totalCost) * 100).toFixed(2)
      : 0;

    // (C) BREAKEVEN
    let breakEvenPercent = 0;
    if (totalRevenues > 0) {
      breakEvenPercent = ((totalCost / totalRevenues) * 100).toFixed(2);
    }

    // (D) Valuation (Placeholder: Simple discounting)
    let discountRows = [];
    let cumulative = 0;
    for (let i = 0; i < yearlyData.length; i++) {
      const yearIndex = i + 1; // 1-based
      const factor = Math.pow(1 + discountFactor / 100, yearIndex);
      const discFlow = yearlyData[i].total / factor;
      cumulative += discFlow;
      discountRows.push({
        year: yearlyData[i].year,
        flow: yearlyData[i].total,
        discountedFlow: discFlow,
        cumulative,
      });
    }
    const xnpv = cumulative - totalCost; // Very rough placeholder
    const xirr = 25.5; // Hard-coded
    const dpp = 4.3; // Hard-coded

    return {
      // cost breakdown
      landCost,
      adminFloorsCost,
      basementFloorsCost,
      technicalAreaCost,
      sharedAreasCost,
      salesCommission,
      totalVariableCost,
      totalCost,

      // revenue details
      yearlyData,
      totalRevenues,
      netOperatingProfit,
      netOperatingProfitPercent,
      roi,
      breakEvenPercent,
      sumVip,
      sumMezz,
      sumAdmin,

      // discount / valuation
      discountFactor,
      discountRows,
      xnpv,
      xirr,
      dpp,
    };
  }, [inputs, yearPlan, discountFactor, currentAdminPrice]);

  console.log("map: ", map);
  console.log("photo: ", photo);
  console.log("iamges: ", images);

  // Helper functions
  // A helper function to generate percentages from 0% to 100% in 10% steps
  function generateOptions(maxDecimal = 1.0, step = 0.1) {
    const options = [];
    for (let val = 0; val <= maxDecimal + 0.000001; val += step) {
      const fraction = parseFloat(val.toFixed(2));
      if (fraction <= maxDecimal) {
        options.push(fraction);
      }
    }
    return options;
  }

  useEffect(() => {
    setTotalRevenue((prev) => {
      const updated = [...prev];
      updated[0] = {
        total: totalRevenues[0].admin + totalRevenues[0].second,
      };
      updated[1] = {
        total: totalRevenues[1].admin + totalRevenues[1].second,
      };
      updated[2] = {
        total: totalRevenues[2].admin + totalRevenues[2].second,
      };
      return updated;
    });
  }, [totalRevenues]);

  useEffect(() => {
    setTotalRentalRevenue((prev) => {
      const updated = [...prev];
      updated[0] = {
        total: totalRentalRevenues[0].admin + totalRentalRevenues[0].second,
      };
      updated[1] = {
        total: totalRentalRevenues[1].admin + totalRentalRevenues[1].second,
      };
      updated[2] = {
        total: totalRentalRevenues[2].admin + totalRentalRevenues[2].second,
      };
      return updated;
    });
  }, [totalRentalRevenues]);

  return (
    <div className="flex items-center justify-center min-h-screen">
      {
        <section className="flex flex-col text-center gap-5 overflow-hidden w-[90%]">
          <section className="flex flex-col font-bold text-[40px] text-center gap-5 overflow-hidden w-full">
            <div className="flex flex-col items-center justify-center text-center gap-10">
              <p>التصميم الخارجي</p>
              <div>
                <img src={images[0]} />
              </div>
            </div>
          </section>
          <section className="flex flex-col font-bold text-[40px] text-center gap-5 overflow-hidden w-full">
            <div className="flex flex-col items-center justify-center text-center gap-10">
              <p>مقترحات</p>
              <div>
                <img src={images[1]} />
              </div>
            </div>
          </section>
          <section className="flex flex-col font-bold text-[40px] text-center gap-5 overflow-hidden w-full">
            <div className="flex flex-col items-center justify-center text-center gap-10">
              <p>التصميم الداخلي</p>
              <div>
                <img src={images[2]} />
              </div>
            </div>
          </section>
          <section className="flex flex-col font-bold text-[40px] text-center gap-5 overflow-hidden w-full">
            <div className="flex flex-col items-center justify-center text-center gap-10">
              <p>الموقع</p>
              <div className="flex w-full h-full justify-center ">
                <img style={{ width: "60%" }} src={map || photo} />
              </div>
            </div>
          </section>
          <hr className="h-[4px] bg-[#3538cd]" />
          <BasicInfo
            basicInfo={
              isCompound
                ? compoundInputs.basicInfo
                : isVillaCompound
                ? villaCompoundInputs.basicInfo
                : isVilla 
                ? villaInputs.basicInfo
                : inputs.basicInfo
            }
            mixed={mixedUse}
            isCompound={isCompound}
            isVillaCompound={isVillaCompound}
            isVilla={isVilla}
          />
          <hr className="h-[4px] bg-[#3538cd]" />
          <Areas
            spaces={
              isCompound
                ? compoundInputs.spaces
                : isVillaCompound
                ? villaCompoundInputs.spaces
                : isVilla 
                ? villaInputs.spaces
                : inputs.spaces
            }
            setBuildingArea={setBuildingArea}
            buildingArea={buildingArea}
            setVillaArea={setVillaArea}
            VillaArea={VillaArea}
            firstFloorSingleName={firstFloorSingleName}
            secondFloorSingleName={secondFloorSingleName}
            mixedUse={mixedUse}
            isCompound={isCompound}
            isVillaCompound={isVillaCompound}
            isVilla={isVilla}
          />
          <hr className="h-[4px] bg-[#3538cd]" />
          <Costs
            costSchedule={
              isCompound
                ? compoundInputs.costSchedule
                : isVillaCompound
                ? villaCompoundInputs.costSchedule
                : isVilla 
                ? villaInputs.costSchedule
                : inputs.costSchedule
            }
            spaces={
              isCompound
                ? compoundInputs.spaces
                : isVillaCompound
                ? villaCompoundInputs.spaces
                : isVilla 
                ? villaInputs.spaces
                : inputs.spaces
            }
            setTotalFixedCost={setTotalFixedCost}
            isVillaCompound={isVillaCompound}
            isVilla={isVilla}
          />
          {/* <hr className="h-[4px] bg-[#3538cd]" /> */}
          <CostsWithChart
            costSchedule={
              isCompound
                ? compoundInputs.costSchedule
                : isVillaCompound
                ? villaCompoundInputs.costSchedule
                : isVilla 
                ? villaInputs.costSchedule
                : inputs.costSchedule
            }
            spaces={
              isCompound
                ? compoundInputs.spaces
                : isVillaCompound
                ? villaCompoundInputs.spaces
                : isVilla 
                ? villaInputs.spaces
                : inputs.spaces
            }
            basicInfo={
              isCompound
                ? compoundInputs.basicInfo
                : isVillaCompound
                ? villaCompoundInputs.basicInfo
                : isVilla 
                ? villaInputs.basicInfo
                : inputs.basicInfo
            }
            salesCommission={CurrentSalesCommission}
            setTotalCost={setTotalVariableCost}
            isCompound={isCompound}
            isVillaCompound={isVillaCompound}
            isVilla={isVilla}
          />
          <hr className="h-[4px] bg-[#3538cd]" />
          <TotalCost
            totalVariableCost={totalVariableCost}
            totalFixedCost={totalFixedCost}
          />
          <hr className="h-[4px] bg-[#D9D9D9]" />
          <RevenueTable yearPlan={yearPlan} setYearPlan={setYearPlan} />
          <hr className="h-[4px] bg-[#D9D9D9]" />
          <AdministrationFloor
            currentAdminPrice={currentAdminPrice}
            setCurrentAdminPrice={setCurrentAdminPrice}
            yearPlan={yearPlan}
            setYearPlan={setYearPlan}
            adminSpaceArea={
              inputs.spaces.firstFloorArea.value *
                inputs.basicInfo.firstFloors.value +
              inputs.spaces.groundFloorArea.value
            }
            setTotalRevenues={setTotalRevenues}
            name={inputs.basicInfo.firstFloors.arabic}
          />
          {mixedUse && (
            <AdministrationFloor
              currentAdminPrice={currentSecondFloorPrice}
              setCurrentAdminPrice={setCurrentSecondFloorPrice}
              yearPlan={yearPlan}
              setYearPlan={setYearPlan}
              adminSpaceArea={
                inputs.spaces.secondFloorArea.value *
                inputs.basicInfo.secondFloors.value
              }
              setTotalRevenues={setTotalRevenues}
              name={inputs.basicInfo.secondFloors.arabic}
              type={"second"}
            />
          )}
          <TotalGrossProfit totalRevenue={totalRevenue} />
          <div className="flex items-center justify-center">
            <ProfitAndCostAreaChartCard
              totalCost={totalFixedCost + totalVariableCost}
              totalRevenue={totalRevenue}
            />
          </div>
          <Samerization
            totalCost={totalFixedCost + totalVariableCost}
            totalRevenue={totalRevenue.reduce((acc, rev) => acc + rev.total, 0)}
          />
          <hr className="h-[4px] bg-[#D9D9D9]" />
          <RentalIncome
            yearPlan={rentalYearPlan}
            setYearPlan={setRentalYearPlan}
          />
          <hr className="h-[4px] bg-[#D9D9D9]" />
          <AdministrationFloor
            currentAdminPrice={currentRentalAdminPrice}
            setCurrentAdminPrice={setCurrentRentalAdminPrice}
            yearPlan={rentalYearPlan}
            setYearPlan={setRentalYearPlan}
            adminSpaceArea={
              inputs.spaces.firstFloorArea.value *
              inputs.basicInfo.firstFloors.value
            }
            rental={true}
            setTotalRevenues={setTotalRentalRevenues}
            name={inputs.basicInfo.firstFloors.arabic}
          />
          {mixedUse && (
            <AdministrationFloor
              currentAdminPrice={currentRentalSecondFloorPrice}
              setCurrentAdminPrice={setCurrentRentalSecondFloorPrice}
              yearPlan={rentalYearPlan}
              setYearPlan={setRentalYearPlan}
              adminSpaceArea={
                inputs.spaces.secondFloorArea.value *
                inputs.basicInfo.secondFloors.value
              }
              rental={true}
              setTotalRevenues={setTotalRentalRevenues}
              name={inputs.basicInfo.secondFloors.arabic}
              type={"second"}
            />
          )}
          <TotalGrossProfit totalRevenue={totalRentalRevenue} />
          <div className="flex items-center justify-center">
            <ProfitAndCostAreaChartCard
              totalCost={totalFixedCost + totalVariableCost}
              totalRevenue={totalRentalRevenue}
            />
          </div>
          <hr className="h-[4px] bg-[#3538cd]" />
          <RentalSamerization
            totalCost={totalFixedCost + totalVariableCost}
            totalRevenue={totalRentalRevenue.reduce(
              (acc, rev) => acc + rev.total,
              0
            )}
          />
        </section>
      }
    </div>
  );
}
