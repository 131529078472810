import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { format } from "date-fns";
import * as DateFnsLocale from "date-fns/locale";
import { z } from "zod";  

import { ARRAY_DELIMITER, RANGE_DELIMITER, SLIDER_DELIMITER } from "../Hooks/use-search-params";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const numberFormatter = (number) =>
  new Intl.NumberFormat("en-US", {
    // minimumFractionDigits: 1,
    maximumFractionDigits: Number(number) % 1 === 0 ? 0 : 2,
  }).format(number);

export const t = (ar, en, lang) => (lang === "ar" ? ar : en);

export const cropText = (text, length) => {
  return text?.length > length ? text.substring(0, length) + "..." : text;
};
export function formatDate({ date, locale = "en", formatStr = "PPP", ...props }) {
  return format(date, formatStr, {
    locale: DateFnsLocale?.[locale === "ar" ? "arSA" : "enUS"],
    ...props,
  });
}

export function formatNumber(number, props) {
  if (Number.isNaN(number)) return null;

  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...props,
  }).format(Number(number));
}

// TODO: check if we can move to /data-table-filter-command/utils.ts

export function isValidDate(date) {
  if (!date) return false;
  const parsed = new Date(date);
  return parsed instanceof Date && !isNaN(parsed.getTime());
}

export function normalizeDate(date) {
  if (!date) return null;
  if (date instanceof Date) return date;
  if (typeof date === "string" || typeof date === "number") {
    const parsed = new Date(date);
    return isValidDate(parsed) ? parsed : null;
  }
  return null;
}

export function deserialize(schema) {
  const castToSchema = z.preprocess((val) => {
    if (typeof val !== "string") return val;
    return val
      .trim()
      .split(" ")
      .reduce((prev, curr) => {
        const [name, value] = curr.split(":");
        if (!value || !name) return prev;
        prev[name] = value;
        return prev;
      }, {});
  }, schema);

  return (value) => castToSchema.safeParse(value);
}

// export function serialize(schema: T) {
//   return (value: z.infer<T>) =>
//     schema
//       .transform((val) => {
//         Object.keys(val).reduce((prev, curr) => {
//           if (Array.isArray(val[curr])) {
//             return `${prev}${curr}:${val[curr].join(",")} `;
//           }
//           return `${prev}${curr}:${val[curr]} `;
//         }, "");
//       })
//       .safeParse(value);
// }

export function serializeColumFilters(columnFilters, filterFields) {
  return columnFilters.reduce((prev, curr) => {
    const { type, commandDisabled } = filterFields?.find((field) => curr.id === field.value) || {
      commandDisabled: true,
    }; // if column filter is not found, disable the command by default

    if (commandDisabled) return prev;

    if (Array.isArray(curr.value)) {
      if (type === "slider") {
        return `${prev}${curr.id}:${curr.value.join(SLIDER_DELIMITER)} `;
      }
      if (type === "checkbox") {
        return `${prev}${curr.id}:${curr.value.join(ARRAY_DELIMITER)} `;
      }
      if (type === "timerange") {
        return `${prev}${curr.id}:${curr.value.join(RANGE_DELIMITER)} `;
      }
    }

    return `${prev}${curr.id}:${curr.value} `;
  }, "");
}

export function isArrayOfNumbers(arr) {
  return arr.every((item) => typeof item === "number");
}

export function isArrayOfDates(arr) {
  return arr.every((item) => item instanceof Date);
}
