import { useEffect, useRef } from "react";
import * as echarts from "echarts";
import PropTypes from "prop-types";
import { Loader2 } from "lucide-react";
import { useLang } from "../Hooks/LangContext";
import { t } from "../lib/utils";

const DemographicsPieCharts = ({ loading = false, data }) => {
  const { lang } = useLang();
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (!loading && chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.dispose(); // Dispose old chart instance
      }
      chartInstance.current = echarts.init(chartRef.current); // Initialize new chart

      const options = {
        series: [
          {
            name: t("طلبات الاستثمار", "Investment Requests", lang),
            type: "pie",
            radius: ["30%", "60%"], // Adjusted for better visibility
            center: ["50%", "50%"],
            itemStyle: { borderRadius: 2 },
            // Normal labels hidden by default.
            label: {
              show: false,
              position: "inside",
              formatter: "{b}: {c} ({d}%)",
              fontSize: 12,
              fontWeight: "bold",
              color: "#000",
            },
            // On hover (emphasis), the label is shown.
            emphasis: {
              label: {
                show: true,
                fontSize: 14,
                fontWeight: "bold",
                formatter: "{b}: {c} ({d}%)",
              },
            },
            data:
              data ||
              [
                { value: 50, name: t("مقبول", "Approved", lang), itemStyle: { color: "#A998FA" } },
                { value: 100, name: t("مرفوض", "Rejected", lang), itemStyle: { color: "#6DCAF9" } },
                { value: 150, name: t("معلق", "Pending", lang), itemStyle: { color: "#FACB5F" } },
              ],
          },
        ],
      };

      chartInstance.current.setOption(options);
    }
  }, [loading, lang, data]);

  useEffect(() => {
    const handleResize = () => {
      if (chartInstance.current) {
        chartInstance.current.resize();
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="flex flex-col items-center">
      {loading ? (
        <div className="flex w-full items-center justify-center">
          <Loader2 size={64} className="animate-spin text-[#B09FFF]" />
        </div>
      ) : (
        <div className="flex flex-col items-center">
          <div ref={chartRef} className="h-[19rem] w-[25rem]" />
          
        </div>
      )}
    </div>
  );
};

DemographicsPieCharts.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      itemStyle: PropTypes.object,
    })
  ),
};

export default DemographicsPieCharts;
