import { useEffect, useState } from "react";
import { AnimatePresence, motion, useIsPresent } from "framer-motion";
// import { IoIosRemoveCircle } from "react-icons/io";
import { MapPinMinusInside } from "lucide-react";
import { useMapContext } from "../../Hooks/mapContext";
import { Card } from "../RealStateInvestmentAi/RealStateInvestmentAiNew";
// A reusable DynamicList component that accepts any JSX element as children
export default function DynamicList({ items, setItemLength }) {
  const { removeItem, setSelectedPositionData } = useMapContext();
  const [listItems, setListItems] = useState(items);
  useEffect(() => {
    console.log("Items2: ", items);
    setListItems((prevItems) => {
      console.log("prevItems: ", prevItems);
      // Check if the newItem already exists in the list by comparing its unique id
      const exists = prevItems.some(
        (item) => item?.shape_area === items[0]?.shape_area
      );

      if (exists) {
        console.log("exists: ", exists);
        // Remove the item if it exists
        console.log(
          prevItems.filter((item) => item?.shape_area !== items[0]?.shape_area)
        );
        return prevItems.filter(
          (item) => item?.shape_area !== items[0]?.shape_area
        );
      } else {
        console.log("exists: ", exists);
        // Otherwise, add the item to the list
        return [...prevItems, ...items];
      }
    });
  }, [items]);
  useEffect(() => {
    console.log("removeItem: ", removeItem);
    // if (!isNaN(removeItem)) {
    let arr = [...listItems];
    console.log("listItems: ", listItems);
    console.log("pre arr: ", arr);
    arr.splice(removeItem, 1);
    console.log("post arr: ", arr);
    setListItems(arr);
    // }
  }, [removeItem]);

  const handleRemove = (index) => {
    const newItems = listItems.filter((_, i) => i !== index);
    setListItems(newItems);
  };

  useEffect(() => {
    setItemLength(listItems.length);
    if (listItems.length > 0) {
      const combinedArea = listItems.reduce((total, item) => {
        return total + Number(item.shape_area || 0);
      }, 0);

      const lastItemData = listItems[listItems.length - 1];

      const newItemData = {
        ...lastItemData,
        shape_area: combinedArea,
      };

      setSelectedPositionData(newItemData);
    } else {
      const defaultItemData = {
        shape_area: "",
        price_of_meter: 0,
        landuseagroup: "",
        parcel_no: "",
        subdivision_no: "",
        city: "",
        city_ar: "",
        name_ar: "",
        no_of_floors: 0,
        ground_percentage: 0,
        repeated_floor_percentage: 0,
        roof_floor_percentage: 0,
        height: "",
        building_rate: "",
        recommendedBuildingType: "",
        bCalculatedFromBlock: false,
        bPriceFromPaseetah: false,
        neighborhaname: "",
        landuseadetailed: "",
      };

      setSelectedPositionData(defaultItemData);
    }
  }, [listItems.length]);
  return (
    <div className="dynamic-list mx-2 ">
      {listItems.length > 0 && (
        <h3 className="text-right font-bold text-[20px]">الأراضي المحددة</h3>
      )}

      <AnimatePresence>
        {listItems.map((item, index) => {
          console.log("item from list: ", item);
          return (
            <ItemWrapper key={index} onRemove={() => handleRemove(index)}>
              <Card data={item} />
            </ItemWrapper>
          );
        })}
      </AnimatePresence>
    </div>
  );
}

// Item wrapper component to handle animations
const ItemWrapper = ({ children, onRemove }) => {
  const isPresent = useIsPresent();
  const animations = {
    style: {
      position: isPresent ? "static" : "absolute",
    },
    initial: { scale: 0, opacity: 0 },
    animate: { scale: 1, opacity: 1 },
    exit: { scale: 0, opacity: 0 },
    transition: { type: "spring", stiffness: 900, damping: 40 },
  };
  return (
    <motion.div {...animations} layout>
      <div className="relative">
        <div
          onClick={onRemove}
          className="absolute left-2  cursor-pointer hover:scale-110 duration-300 top-5"
        >
          <img src="/cancellation.webp" className="object-cover" alt="" />
        </div>
        {children}
      </div>
    </motion.div>
  );
};
