/* eslint-disable no-unused-vars */
/* eslint-disable no-duplicate-case */
import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { getRTLTextPluginStatus, setRTLTextPlugin } from "mapbox-gl";
import { useMapContext } from "../../Hooks/mapContext";

const apiKey = "AIzaSyC3LhU5__i5drYp2kZkZ5su50khcxh3sFU"; // Replace with your actual API key
async function getParcelWithPosition(lat, lng, page = 1, per_page = 1) {
  const token_response = await fetch(
    `https://seashell-app-fxoft.ondigitalocean.app/api/v1/0ad9sf70asd8f7a0d9f87123lk4h1k23jl4h`,
    {
      method: "get",
      headers: {
        accept: "application/json",
      },
    }
  );

  const token_data = await token_response?.json();

  const access_token = token_data?.token;

  const parcel_response = await fetch(
    `https://paseetah.com/p-api/v1/parcels?page=${page}&per_page=${per_page}&lat=${lat}&lng=${lng}&return_geometry=true&geometry_format=geojson
`,
    {
      method: "get",
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    }
  );

  const parcel_data = await parcel_response.json();

  let jeojson;
  if (parcel_data?.data?.length > 0) {
    const building_code_response = await fetch(
      `https://paseetah.com/p-api/v1/parcels/${parcel_data.data[0]?.id}/building-code`,
      {
        method: "get",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    const building_code_data = await building_code_response?.json();

    console.log("building_code_data: ", building_code_data);

    const rer_transaction_response = await fetch(
      `https://paseetah.com/p-api/v1/rer/transactions?sort=-transaction_date%2Cmeter_price&page=${page}&per_page=${per_page}&min_meter_price=1&neighbourhood_id=${parcel_data.data[0]?.neighbourhood?.id}`,
      {
        method: "get",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    const rer_transaction_data = await rer_transaction_response?.json();

    let ground_floor_percentage = building_code_data?.data?.data[0]
      ?.parcel_coverage?.ar
      ? parseFloat(building_code_data?.data?.data[0]?.parcel_coverage?.ar) ===
        typeof Number
        ? parseFloat(building_code_data?.data?.data[0]?.parcel_coverage?.ar) /
          100
        : 0.6
      : 0.6;

    let repeated_floor_percentage = 0.7;
    let roof_floor_percentage = 0.5;

    let bCalculatedFromBlock = false;

    function parseFloorsAndCoverage(rule) {
      // Prepare the returned structures:
      const floors = {
        ground: 0,
        technical: 0,
        roof: 0,
      };

      const percentage = {
        ground: 0.6, // from parcel_coverage
        technical: 0.7, // set by policy or discovered
        roof: 0.5,
      };

      if (rule) {
        // 1) We’ll look at height in both languages; if English is missing, fallback to Arabic
        const heightStr =
          rule.height?.en?.toLowerCase() ||
          rule.height?.ar?.toLowerCase() ||
          "";

        // 2) Pull out the base ground coverage from `parcel_coverage` if it exists
        //    e.g. "60%" in your example
        const groundCoverageStr = rule.parcel_coverage?.ar || "";

        const foundPercentages = groundCoverageStr.match(/(\d+(\.\d+)?)/g);

        if (foundPercentages && foundPercentages.length === 2) {
          // Convert found strings to numbers
          const values = foundPercentages.map(Number);

          percentage.ground = Math.min(...values) / 100;
          percentage.technical = Math.max(...values) / 100;
        } else if (foundPercentages && foundPercentages.length === 1) {
          // Only one percentage is provided; assign it to both
          const value = parseFloat(foundPercentages[0]);
          percentage.ground = value / 100;
        }

        // 3) Detect “ground”/”أرضي”
        if (heightStr.includes("ground") || heightStr.includes("أرضي")) {
          floors.ground = 1;
        }

        // 4) Detect the number of technical floors
        //    e.g., matches "3", "three", etc.
        //    - First, try numeric
        let technicalMatch;
        let technicalFloors = 0;
        if (technicalMatch) {
          technicalFloors = parseInt(technicalMatch[1], 10);
        } else {
          const spelledMap = {
            one: 1,
            two: 2,
            three: 3,
            four: 4,
            five: 5,
            six: 6,
            seven: 7,
            eight: 8,
            nine: 9,
            ten: 10,
            eleven: 11,
            twelve: 12,
            thirteen: 13,
            fourteen: 14,
            fifteen: 15,
            sixteen: 16,
            seventeen: 17,
            eighteen: 18,
            nineteen: 19,
            twenty: 20,
            "twenty-one": 21,
            "twenty-two": 22,
            "twenty-three": 23,
            "twenty-four": 24,
            "twenty-five": 25,
            "twenty-six": 26,
            "twenty-seven": 27,
            "twenty-eight": 28,
            "twenty-nine": 29,
            thirty: 30,
          };
          for (const [word, numVal] of Object.entries(spelledMap)) {
            if (heightStr.includes(word)) {
              technicalFloors = numVal;
              console.log("technical floor found: ", numVal, word);
              break;
            }
          }
        }

        const plusExistance = heightStr.match(/\+/g);

        const added_floors =
          plusExistance?.length > 2 ? plusExistance?.length - 2 : 0;

        console.log("plus: ", plusExistance);
        console.log("added floors: ", added_floors);
        console.log("pre technical floors: ", technicalFloors);

        technicalFloors += added_floors;

        console.log("post technical floors: ", technicalFloors);

        floors.technical = technicalFloors;

        // 5) Detect a roof floor if the text says something like “50% supplement area”
        //    We assume 1 roof floor if we see a “%”.
        const roofMatch = heightStr.match(/(\d+)%/);
        if (roofMatch) {
          floors.roof = 1;
          percentage.roof = parseFloat(roofMatch[0]) / 100; // e.g. "50%"
          bCalculatedFromBlock = true;
        }
      }

      // Return the final objects
      return { floors, percentage };
    }

    const blockData = building_code_data?.data?.data[0] || null;

    console.log(blockData);

    const { floors, percentage } = parseFloorsAndCoverage(blockData);

    let number_of_floors =
      rer_transaction_data?.data[0]?.real_estate_number_of_floors || 0;

    if (blockData) {
      ground_floor_percentage = percentage.ground;
      number_of_floors = floors.ground + floors.technical + floors.roof;
      repeated_floor_percentage = percentage.technical;
      roof_floor_percentage = percentage.roof;
    }

    // console.log(floors);

    // console.log(percentage);

    const paseetahBuildingType = parcel_data.data[0]?.zoning_en || "";

    let recommendedBuildingTypeFromPaseetah;
    switch (paseetahBuildingType) {
      case "Mixed":
        recommendedBuildingTypeFromPaseetah = "مبنى تجاري سكني";
        break;

      case "Commercial":
        recommendedBuildingTypeFromPaseetah = "مبنى تجاري";
        break;

      default:
        recommendedBuildingTypeFromPaseetah = "مبنى سكني";
        break;
    }

    let bPriceFromPaseetah = rer_transaction_data?.data[0]?.meter_price
      ? true
      : false;

    jeojson = {
      type: "Feature",
      geometry: parcel_data.data[0]?.geometry,
      properties: {
        id: parcel_data.data[0]?.id,
        plan_number: parcel_data.data[0]?.plan_number || "بدون",
        parcel_number: parcel_data.data[0]?.parcel_number || "بدون",
        measured_area: parcel_data.data[0]?.measuredarea,
        zoning_en: parcel_data.data[0]?.zoning_en,
        zoning_ar: parcel_data.data[0]?.zoning_ar,
        property_type_en: parcel_data.data[0]?.property_type_en,
        property_type_ar: parcel_data.data[0]?.property_type_ar,
        region: parcel_data.data[0]?.region?.name_en,
        city: parcel_data.data[0]?.city?.name_en,
        city_ar: parcel_data.data[0]?.city?.name_ar,
        neighborhood: parcel_data.data[0]?.neighbourhood.name_en,
        ground_percentage: ground_floor_percentage || 0.6,
        repeated_floor_percentage: repeated_floor_percentage || 0.7,
        roof_floor_percentage: roof_floor_percentage || 0.5,
        height: building_code_data?.data?.data[0]?.height?.ar || null,
        building_rate: building_code_data?.data?.data[0]?.far?.ar || null,
        price_of_meter: rer_transaction_data?.data[0]?.meter_price || 4000,
        no_of_floors: number_of_floors,
        recommendedBuildingType: recommendedBuildingTypeFromPaseetah,
        bCalculatedFromBlock: bCalculatedFromBlock,
        bPriceFromPaseetah: bPriceFromPaseetah,
      },
    };
  }
  return { data: parcel_data.data[0], jeojson };
}

const MyMap = ({ itemLength }) => {
  const {
    setSelectedPositionData,
    setMapPhotoData,
    searchData,
    mapPhotoData,
    setPhoto,
    items,
    setRemoveItem,
    setItems,
    newItems,
    setNewItems,
    update,
    loadingData,
    setLoadingData,
  } = useMapContext();
  const mapContainer = useRef(null);
  const map = useRef(null);
  const circleLayerRef = useRef();
  const selectedParcels = useRef([]); // Store selected parcels coordinates
  const [loading, setLoading] = useState(false);
  const MAPBOX_TOKEN =
    "pk.eyJ1Ijoic3VoYWlsLWFwcCIsImEiOiJjazl0cjFrOWMxZ2p1M2huMW4xZTl2MzgzIn0.GMPVuCC_3EpJ4HZA8KZoPg"; // Replace with your Mapbox token

  useEffect(() => {
    setLoading(true);
    if (map.current) return; // Initialize map only once
    if (getRTLTextPluginStatus() === "unavailable") {
      setRTLTextPlugin(
        "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
        null,
        true // Force plugin load
      );
    }
    mapboxgl.accessToken = MAPBOX_TOKEN;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "https://tiles.suhail.ai/riyadh/", // You can choose different styles
      center: [0, 0],
      zoom: 1.5,
      projection: "globe", // Display the map as a 3D globe
    });
    map.current.on("load", (e) => {
      console.log("loaded");
      setLoading(false);

      map.current.flyTo({
        center: { lat: 24.694466820974483, lng: 46.733376691538695 },
        essential: true,
        zoom: 14,
        speed: 0.7,
      });
    });

    map.current.on("style.load", () => {
      map.current.setFog({
        range: [-0.1, 0.1],
        color: "white",
        "horizon-blend": 0.03,
      });

      const layersToShow = [
        "subdivisions-fill",
        "parcels",
        "parcels-base",
        "neighborhoods",
      ]; // Example layers to show

      // Loop through all layers and hide those not in layersToShow
      map.current.getStyle().layers.forEach((layer) => {
        if (layersToShow.includes(layer.id)) {
          switch (layer.type) {
            case "fill":
              map.current.setPaintProperty(layer.id, "fill-opacity", 0);
              break;
            case "line":
              map.current.setPaintProperty(layer.id, "line-opacity", 0);
              break;
            case "symbol":
              map.current.setPaintProperty(layer.id, "text-opacity", 0);
              map.current.setPaintProperty(layer.id, "icon-opacity", 0);
              break;
            case "circle":
              map.current.setPaintProperty(layer.id, "circle-opacity", 0);
              break;
            case "raster":
              map.current.setPaintProperty(layer.id, "raster-opacity", 0);
              break;
            case "background":
              map.current.setPaintProperty(layer.id, "background-opacity", 1);
              break;
            default:
              break;
          }
        } else {
          // Ensure the selected layers are visible
          map.current.setLayoutProperty(layer.id, "visibility", "visible");
        }
      });
    });
    map.current.on("click", async (event) => {
      console.log(event);
      let zoom = map.current.getZoom();
      const { lng, lat } = event.lngLat;

      console.log(zoom);
      const dt = await getParcelWithPosition(lat, lng);
      const parcelGeoJSON = dt.jeojson;
      console.log(dt, parcelGeoJSON, "the dt");

      if (parcelGeoJSON) {
        // Check if the parcel is already selected
        const isAlreadySelected = selectedParcels.current.some(
          (parcel) =>
            JSON.stringify(parcel.geometry.coordinates) ===
            JSON.stringify(parcelGeoJSON.geometry.coordinates)
        );

        if (!isAlreadySelected) {
          selectedParcels.current.splice(0, selectedParcels.current.length);
          selectedParcels.current.push(parcelGeoJSON); // Add new parcel to the list
        } else {
          // If it already exists, remove it (toggle selection)
          // selectedParcels.current.splice(0, selectedParcels.current.length-1);
          // selectedParcels.current.push(parcelGeoJSON); // Add new parcel to the list

          selectedParcels.current = selectedParcels.current.filter(
            (parcel) =>
              JSON.stringify(parcel.geometry.coordinates) !==
              JSON.stringify(parcelGeoJSON.geometry.coordinates)
          );
        }

        console.log("selectedParcels: ", selectedParcels);

        // Create a GeoJSON FeatureCollection from selected parcels
        const geojsonData = {
          type: "FeatureCollection",
          features: selectedParcels.current,
        };

        // Check if the source exists, update it if it does, otherwise create it
        if (map.current.getSource("parcel-boundary")) {
          map.current.getSource("parcel-boundary").setData(geojsonData);
        } else {
          map.current.addSource("parcel-boundary", {
            type: "geojson",
            data: geojsonData,
          });

          // Add a fill layer to highlight parcels
          map.current.addLayer({
            id: "parcel-layer",
            type: "fill",
            source: "parcel-boundary",
            paint: {
              "fill-color": "#ff0000", // Red color
              "fill-opacity": 0.5, // Semi-transparent
            },
          });

          // Add a border around parcels
          map.current.addLayer({
            id: "parcel-border",
            type: "line",
            source: "parcel-boundary",
            paint: {
              "line-color": "#000",
              "line-width": 2,
            },
          });

          // Add a text layer for plan_number
          map.current.addLayer({
            id: "parcel-label",
            type: "symbol",
            source: "parcel-boundary",
            layout: {
              "text-field": ["get", "parcel_number"], // Display plan_number
              "text-size": 14,
              "text-anchor": "center",
            },
            paint: {
              "text-color": "#000000", // Black text
              "text-halo-color": "#ffffff", // White halo for readability
              "text-halo-width": 1,
            },
          });
        }

        setSelectedPositionData({
          shape_area: dt.data?.measuredarea || "N/A",
          price_of_meter: parcelGeoJSON?.properties?.price_of_meter || "N/A", // Adjust based on API data availability
          landuseagroup: dt.data?.zoning_en || "N/A",
          parcel_no: parcelGeoJSON?.properties.parcel_number || "بدون",
          subdivision_no: parcelGeoJSON?.properties.plan_number || "بدون",
          city: dt.data?.city?.name_en || "N/A",
          name_ar: dt.data?.neighbourhood?.name_ar || "N/A",
          no_of_floors: parcelGeoJSON?.properties?.no_of_floors || "N/A",
          ground_percentage:
            parcelGeoJSON?.properties?.ground_percentage || "N/A",
          height: parcelGeoJSON?.properties?.height || "N/A",
          building_rate: parcelGeoJSON?.properties?.building_rate || "N/A",
          recommendedBuildingType:
            parcelGeoJSON?.properties?.recommendedBuildingType || "N/A",
        });

        return;
      }

      if (zoom < 16) return;
      setMapPhotoData({ lng, lat, zoom });
      const features = map.current.queryRenderedFeatures(event.point, {});
      console.log(features, "the eee");
      function getAllUniqueProperties(featuresArray) {
        const uniqueProps = {};
        for (let feature of featuresArray) {
          if (feature.properties) {
            for (let key in feature.properties) {
              // If the key doesn't exist in uniqueProps, add it
              if (!uniqueProps.hasOwnProperty(key)) {
                uniqueProps[key] = feature.properties[key];
              }
            }
          }
        }

        return uniqueProps;
      }
      let data = getAllUniqueProperties(features);
      console.log("getAllUniqueProperties: ", data);
      setSelectedPositionData(data);
      function light() {
        const features = map.current.queryRenderedFeatures(event.point, {
          layers: ["parcels"], // Ensure "parcels" is the correct layer ID
        });

        console.log("features: ", features);

        if (features.length > 0) {
          const selectedParcel = features[0];
          const parcelCoordinates = selectedParcel.geometry.coordinates;

          console.log("selectedParcel: ", selectedParcel);

          // Toggle selection of the parcel
          toggleParcelSelection(parcelCoordinates, data);
          // setSelectedPositionData(selectedParcel.properties);
        }
      }
      light();
    });
  }, []); // Empty dependency array to run once

  // Function to highlight or unhighlight selected parcels
  function toggleParcelSelection(coordinates, data) {
    // Check if the parcel is already selected
    const index = selectedParcels.current.findIndex(
      (coords) =>
        JSON.stringify(coords.coordinates) === JSON.stringify(coordinates)
    );

    if (index > -1) {
      // If the parcel is already selected, remove it
      selectedParcels.current.splice(index, 1);
      setRemoveItem(index);
    } else {
      // If the parcel is not selected, add it
      selectedParcels.current.push({ coordinates, data });
    }

    // Update the highlighted parcels on the map without causing re-render
    updateHighlightedParcels();
  }

  // Function to update the highlighted parcels on the map
  function updateHighlightedParcels() {
    // Remove the existing highlight layer if present
    if (map.current.getLayer("highlighted-parcels")) {
      map.current.removeLayer("highlighted-parcels");
      map.current.removeSource("highlighted-parcels");
    }

    // Create a GeoJSON feature collection for all selected parcels
    const geojsonData = {
      type: "FeatureCollection",
      features: selectedParcels.current.map((coords) => ({
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: coords.coordinates,
        },
      })),
    };

    // Add a new GeoJSON source for the highlighted parcels
    map.current.addSource("highlighted-parcels", {
      type: "geojson",
      data: geojsonData,
    });

    // Add a layer to style all highlighted parcels
    map.current.addLayer({
      id: "highlighted-parcels",
      type: "fill",
      source: "highlighted-parcels",
      paint: {
        "fill-color": "#ff0000", // Red color for highlighting
        "fill-opacity": 0.5,
      },
    });
  }

  useEffect(() => {
    if (mapPhotoData) {
      setPhoto(
        `https://maps.googleapis.com/maps/api/staticmap?center=${
          mapPhotoData?.lat
        },${
          mapPhotoData?.lng
        }&zoom=${16}&size=600x400&markers=color:red%7Clabel:A%7C${
          mapPhotoData?.lat
        },${mapPhotoData?.lng}&key=${apiKey}`
      );
      console.log(
        `https://maps.googleapis.com/maps/api/staticmap?center=${
          mapPhotoData?.lat
        },${
          mapPhotoData?.lng
        }&zoom=${16}&size=600x400&markers=color:red%7Clabel:A%7C${
          mapPhotoData?.lat
        },${mapPhotoData?.lng}&key=${apiKey}`
      );
    }
  }, [mapPhotoData]);
  useEffect(() => {
    if (searchData) console.log("this part updates", searchData);
    if (searchData)
      fetch(
        `https://el-samaloty-apis.vercel.app/api/search?num1=${searchData[0]}&num2=${searchData[1]}`,
        {
          method: "get",
        }
      )
        .then((e) => {
          return e.json();
        })
        .then((e) => {
          if (e.content.length > 0) {
            const center = e.content[0]?.properties?.centroid;
            console.log(e, center);
            map.current.flyTo({
              center: { lat: center.y, lng: center.x },
              essential: true,
              zoom: 17,
              speed: 0.7,
            });
            // getData({ lat: center.y, lng: center.x });
          }
        });
  }, [update]);
  useEffect(() => {
    try {
      if (searchData[0]) {
        map.current.flyTo({
          center: { lat: searchData[1], lng: searchData[0] },
          essential: true,
          zoom: 17,
          speed: 0.7,
        });
      }
    } catch {}
  }, [searchData]);

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1024px)").matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1024px)");
    const handleMediaQueryChange = (e) => setIsMobile(e.matches);

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  // ─── NEW: Listen for PARCEL_CLICK events from outside (e.g. from Iframe) ─────
  useEffect(() => {
    const handleParcelClick = async (event) => {
      if (event.data && event.data.type === "PARCEL_CLICK") {
        const { parcelId, location } = event.data.payload;
        const { lat, lng } = location;
        console.log("Parcel clicked via event listener:", parcelId, lat, lng);
        if (parcelId && location && lat && lng) {
          setLoadingData(true);
          // Call getParcelWithPosition to retrieve parcel data for these coordinates
          const dt = await getParcelWithPosition(lat, lng);
          const parcelGeoJSON = dt.jeojson;
          console.log("dt: ", dt);
          if (parcelGeoJSON) {
            // Update the context/state similar to what is done on map click:
            const newItemData = 
              {
                shape_area: dt.data?.measuredarea || "N/A",
                price_of_meter: parcelGeoJSON?.properties?.price_of_meter || 4000,
                landuseagroup: dt.data?.zoning_ar || "N/A",
                parcel_no: parcelGeoJSON?.properties.parcel_number || "بدون",
                subdivision_no: parcelGeoJSON?.properties.plan_number || "بدون",
                city: dt.data?.city?.name_en || "N/A",
                city_ar: dt.data?.city?.name_ar || "N/A",
                name_ar: dt.data?.neighbourhood?.name_ar || "N/A",
                no_of_floors: parcelGeoJSON?.properties?.no_of_floors || 0,
                ground_percentage:
                  parcelGeoJSON?.properties?.ground_percentage || 0.6,
                repeated_floor_percentage:
                  parcelGeoJSON?.properties?.repeated_floor_percentage || 0.7,
                roof_floor_percentage:
                  parcelGeoJSON?.properties?.roof_floor_percentage || 0.5,
                height: parcelGeoJSON?.properties?.height || "N/A",
                building_rate: parcelGeoJSON?.properties?.building_rate || "N/A",
                recommendedBuildingType:
                  parcelGeoJSON?.properties?.recommendedBuildingType || "N/A",
                bCalculatedFromBlock:
                  parcelGeoJSON?.properties?.bCalculatedFromBlock || false,
                bPriceFromPaseetah:
                  parcelGeoJSON?.properties?.bPriceFromPaseetah || false,
                  neighborhaname: dt?.data?.neighbourhood?.name_ar || "",
                  landuseadetailed:
                  parcelGeoJSON?.properties?.recommendedBuildingType || "",
              };

            setSelectedPositionData(newItemData);
            // Optionally update map photo data or other state if needed
            setMapPhotoData({ lng, lat, zoom: 16 });

            setNewItems([newItemData]);
          }
          


          setLoadingData(false);
        }
      }
    };
    window.addEventListener("message", handleParcelClick);
    return () => {
      window.removeEventListener("message", handleParcelClick);
    };
  }, [setSelectedPositionData]);

  useEffect(() => {
    const addItem = (selectedParcels) => {
      console.log("parcels: ", selectedParcels);
      setItems([...selectedParcels]);
    };
    console.log(items);
    addItem([...newItems]);
  }, [newItems]);

  console.log(isMobile);
  return (
    <div
      // ${
      //   itemLength === 0
      //     ? "md:h-[650px]"
      //     : itemLength === 1
      //     ? "md:h-[760px]"
      //     : "md:h-[876px]"
      // }
      className={`
       md:h-[876px] h-full
       w-full`}
    >
      <div
        ref={mapContainer}
        style={{
          width: "100%",
          height: isMobile ? "300px" : "876px",
          // itemLength === 0 ? "650px" : itemLength === 1 ? "760px" : "876px",
          display: loading ? "none" : "",
        }}
      />
      <div
        className="flex justify-center items-center text-4xl text-black"
        style={{
          width: "100%",
          height: "100%",
          display: !loading ? "none" : "",
        }}
      >
        loading map
      </div>
    </div>
  );
};

export default MyMap;
