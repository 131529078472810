/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import ReactDOMServer from "react-dom/server";
// import { saveAs } from "file-saver";
import axios from "axios";
// import styles from "../pdf.css";

// import html2pdf from "html2pdf.js";
import html2pdf from "html2pdf.js";
import { useLocation, useNavigate } from "react-router";
import Pdf from "./Pdf";
import useKonvaWrapper from "../konva";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useLang } from "../Hooks/LangContext";
import { usePopup } from "../Hooks/popup";
import useGeneratePDF from "../Hooks/download";
import transformMinimalDataToOldJson from "../UtilityFunctions/ConvertedToOldSystem";
import StudyCasesApi from "../apis/StudyCases";
import { useAuth } from "../Hooks/authContext";

const DownloadPage = () => {
  const { generatePDF, isLoading, error } = useGeneratePDF();
  const { auth } = useAuth();

  const location = useLocation();

  const [dataObj, setDataObj] = useState(null);
  const [simplifiedData, setSimplifiedData] = useState(null);
  const [returnedVariables, setReturnedVariables] = useState(null);
  const [projectType, setProjectType] = useState(null);
  const [images, setImages] = useState(null);
  const [loadingStudyCase, setLoadingStudyCase] = useState(true);

  
  let redirect = location?.state?.redirect;
  console.log(redirect);
  const map = location?.state?.mapUrl;
  const prompts = location?.state?.prompts;
  const blockProps = location?.state?.blockProps;
  const { popup } = usePopup();
  const [cssText, setCssText] = useState("");
  const [loading, setloading] = useState(false);
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
  useEffect(() => {
    if (isGeneratingPdf) {
      popup.forced();
      popup.run(
        <div className="flex justify-center items-center w-full h-full text-3xl text-white">
          Generating pdf ...
        </div>
      );
    } else {
      popup.close();
    }
  }, [isGeneratingPdf]);
  useEffect(() => {
    setIsGeneratingPdf(isLoading);
  }, [isLoading]);
  const handleDownload = () => {
    generatePDF(dataObj, images, map);
    // const input = document.getElementById("pdfContainer22");
    // const opt = {
    //   margin: [0, 0, 0, 0],
    //   filename: "download.pdf",
    //   image: { type: "jpeg", quality: 1 },
    //   html2canvas: {
    //     scale: 2,
    //     useCORS: true,
    //   },
    //   jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    //   pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    // };

    // html2pdf()
    //   .from(input)
    //   .set(opt)
    //   .save()
    //   .finally(() => {
    //     setIsGeneratingPdf(false); // Reset the state back to normal
    //   });
  };

  useEffect(() => {
    const fetchStudyCase = async () => {
      try {
        // Await the API call
        const caseStudyResponse = await StudyCasesApi.getCase(auth, location?.state?.id);
        // Extract the needed data and update state.
        const fetchedData = caseStudyResponse?.data?.studyCase?.data;
        console.log("fetchedData: ", fetchedData);
        setDataObj(fetchedData);
        setSimplifiedData(fetchedData?.EditingData?.simpleData);
        setReturnedVariables(fetchedData?.EditingData?.returnedVariables);
        setProjectType(fetchedData?.EditingData?.projectType);
        setImages(caseStudyResponse?.data?.studyCase?.images);
      } catch (error) {
        console.error("Error fetching study case:", error);
        // Optionally handle error state here
      } finally {
        setLoadingStudyCase(false);
      }
    };

    // Ensure we have auth and an id before making the API call.
    if (auth && location?.state?.id) {
      fetchStudyCase();
    }
  }, [auth, location?.state?.id]);


  const nav = useNavigate();
  const { lang } = useLang();


   // Render a loader until dataObj is ready.
   if (loadingStudyCase || !dataObj || !simplifiedData || !returnedVariables || !projectType || !images) {
    return (
      <div className="flex justify-center items-center h-screen">
        Loading study case...
      </div>
    );
  }

  return (
    <>
      <div className="w-screen overflow-x-hidden absolute left-0 top-0 bg-white">
        {!redirect && (
          <div
            onClick={() => {
              nav(-1);
            }}
            className="cursor-pointer flex items-center gap-1 text-[#9995ee] font-semibold absolute left-20 top-20"
          >
            <MdKeyboardArrowLeft className="text-[25px]" />
            <p>{lang === "ar" ? "العوده" : "back"}</p>
          </div>
        )}
        <div className="px-[2%] md:px-[20%] py-[5%]">
          <div id="docsdo" className="">
            <HtmlComponent
              redirect={redirect}
              blockProps={blockProps}
              map={map}
              prompts={prompts}
              isGeneratingPdf={isGeneratingPdf}
              dataObj={dataObj}
              images={images}
              simplifiedJson={simplifiedData}
              jsonVariables={returnedVariables}
              projectType={projectType}
            />
          </div>
        </div>
        <div className="w-full justify-center items-center flex pb-10 scale-150">
          <button
            className="bg-blue-500 flex justify-center items-center gap-2 text-white font-bold py-2 px-4 rounded"
            onClick={() => {
              handleDownload();
            }}
          >
            Download{" "}
            {isGeneratingPdf && (
              <span className="w-[20px] h-[20px] border-2 rounded-full border-white border-dashed animate-spin flex flex-row justify-center items-center"></span>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export const HtmlComponent = ({
  dataObj,
  images,
  map,
  prompts,
  blockProps,
  isGeneratingPdf,
  simplifiedJson,
  jsonVariables,
  projectType,
  redirect,
}) => {

  console.log("jsonVariables2: ", jsonVariables);
  return (
    <>
      <FinancialStudyCard
        redirect={redirect}
        isGeneratingPdf={isGeneratingPdf}
        map={map}
        prompts={prompts}
        blockProps={blockProps}
        dataObj={dataObj}
        images={images}
        simplifiedJson={simplifiedJson}
        jsonVariables={jsonVariables}
        projectType={projectType}
      />
    </>
  );
};

const FinancialStudyCard = ({
  dataObj: dt, // The initial old-data (calculated JSON)
  images,
  prompts,
  map,
  blockProps,
  simplifiedJson, // The minimal data used to generate oldData
  jsonVariables, // The numeric variables used for calculations
  isGeneratingPdf = false,
  projectType,
  redirect,
}) => {
  console.log("jsonVariables: ", jsonVariables);
  const location = useLocation();
  const navigate = useNavigate();
  const { popup } = usePopup();
  const { auth } = useAuth();

  console.log("dataObj2: ", dt);


  // We keep the displayed old-data in state
  const [dataObj, setDataObj] = useState(dt);

  // Editing mode (for numeric fields only)
  const [isEditing, setIsEditing] = useState(false);

  // We'll store local edits to numeric variables here
  const [editingVars, setEditingVars] = useState(jsonVariables);

  console.log(editingVars);

  const [pendingEdits, setPendingEdits] = useState({});

  const commercialAdminConfig = [
    {
      key: "landPurchasePricePerSqm",
      label: "سعر الشراء للمتر:",
      type: "number",
      step: "0.01",
    },
    {
      key: "sellingPricePerSqm",
      label: "سعر البيع للمتر:",
      type: "number",
      step: "0.01",
    },
    // {
    //   key: "specialSellingPrice",
    //   label: "سعر البيع للمتر المميز:",
    //   type: "number",
    //   step: "0.01",
    // },
    {
      key: "constructionCostPerSqm",
      label: "تكلفة البناء للمتر:",
      type: "number",
      step: "0.01",
    },
    {
      key: "floorsCount",
      label: "عدد الأدوار:",
      type: "number",
      step: "1",
    },
    {
      key: "firstFloors",
      label: "عدد الأدوار التجارية:",
      type: "number",
      step: "1",
    },
    {
      key: "earthArea",
      label: "مساحة الأرض:",
      type: "number",
      step: "0.01",
    },
    {
      key: "basePropertySize",
      label: "نطاق مساحة الوحدات التجارية:",
      type: "number",
      step: "0.01",
    },
    {
      key: "propertySizesFrom",
      label: "مساحة الوحدة التجارية من:",
      type: "number",
      step: "0.01",
    },
    {
      key: "propertySizesTo",
      label: "مساحة الوحدة التجارية إلى:",
      type: "number",
      step: "0.01",
    },
    {
      key: "secondBasePropertySize",
      label: "نطاق مساحة الوحدات الإدارية:",
      type: "number",
      step: "0.01",
    },
    {
      key: "secondPropertySizeFrom",
      label: "مساحة الوحدة الإدارية من:",
      type: "number",
      step: "0.01",
    },
    {
      key: "secondPropertySizeTo",
      label: "مساحة الوحدة الإدارية إلى:",
      type: "number",
      step: "0.01",
    },
    {
      key: "groundFloorBuildRatio",
      label: "نسبة البناء للدور الأرضي:",
      type: "number",
      step: "0.01",
    },
    {
      key: "repeatedFloorsRatio",
      label: "نسبة البناء للأدوار المتكررة:",
      type: "number",
      step: "0.01",
    },
    {
      key: "roofAnnexRatio",
      label: "نسبة البناء للملحق العلوي:",
      type: "number",
      step: "0.01",
    },
    {
      key: "landscapeCostPerSquareMeter",
      label: "تكلفة اللاندسكيب لكل متر:",
      type: "number",
      step: "0.01",
    },
    {
      key: "designCost",
      label: "تكلفة التصميم المعماري:",
      type: "number",
      step: "0.01",
    },
    {
      key: "legalCost",
      label: "تكلفة القوانين والإدارة:",
      type: "number",
      step: "0.01",
    },
    {
      key: "siteCoordinationCost",
      label: "تكلفة تنسيق الموقع:",
      type: "number",
      step: "0.01",
    },
  ];

  const residentialCommConfig = [
    {
      key: "landPurchasePricePerSqm",
      label: "سعر الشراء للمتر:",
      type: "number",
      step: "0.01",
    },
    {
      key: "sellingPricePerSqm",
      label: "سعر البيع السكني للمتر:",
      type: "number",
      step: "0.01",
    },
    // {
    //   key: "specialSellingPrice",
    //   label: "سعر البيع السكني للمتر المميز:",
    //   type: "number",
    //   step: "0.01",
    // },
    // {
    //   key: "specialSellingPrice2",
    //   label: "سعر البيع التجاري للمتر المميز:",
    //   type: "number",
    //   step: "0.01",
    // },
    {
      key: "sellingPricePerSqm2",
      label: "سعر البيع التجاري للمتر:",
      type: "number",
      step: "0.01",
    },
    {
      key: "yearlyRentPerMeterValue",
      label: "سعر الإيجار السنوي للسكني للمتر:",
      type: "number",
      step: "0.01",
    },
    {
      key: "yearlyRentPerMeterValue2",
      label: "سعر الإيجار السنوي للتجاري للمتر:",
      type: "number",
      step: "0.01",
    },
    {
      key: "constructionCostPerSqm",
      label: "تكلفة البناء للمتر:",
      type: "number",
      step: "0.01",
    },
    {
      key: "floorsCount",
      label: "عدد الأدوار:",
      type: "number",
      step: "1",
    },
    {
      key: "firstFloors",
      label: "عدد الأدوار التجارية:",
      type: "number",
      step: "1",
    },
    {
      key: "earthArea",
      label: "مساحة الأرض:",
      type: "number",
      step: "0.01",
    },
    {
      key: "basePropertySize",
      label: "نطاق مساحة الوحدات التجارية:",
      type: "number",
      step: "0.01",
    },
    {
      key: "propertySizesFrom",
      label: "مساحة الوحدة التجارية من:",
      type: "number",
      step: "0.01",
    },
    {
      key: "propertySizesTo",
      label: "مساحة الوحدة التجارية إلى:",
      type: "number",
      step: "0.01",
    },
    {
      key: "secondBasePropertySize",
      label: "نطاق مساحة الوحدات السكنية:",
      type: "number",
      step: "0.01",
    },
    {
      key: "secondPropertySizeFrom",
      label: "مساحة الوحدة السكنية من:",
      type: "number",
      step: "0.01",
    },
    {
      key: "secondPropertySizeTo",
      label: "مساحة الوحدة السكنية إلى:",
      type: "number",
      step: "0.01",
    },
    {
      key: "groundFloorBuildRatio",
      label: "نسبة البناء للدور الأرضي:",
      type: "number",
      step: "0.01",
    },
    {
      key: "repeatedFloorsRatio",
      label: "نسبة البناء للأدوار المتكررة:",
      type: "number",
      step: "0.01",
    },
    {
      key: "roofAnnexRatio",
      label: "نسبة البناء للملحق العلوي:",
      type: "number",
      step: "0.01",
    },
    {
      key: "landscapeCostPerSquareMeter",
      label: "تكلفة اللاندسكيب لكل متر:",
      type: "number",
      step: "0.01",
    },
    {
      key: "designCost",
      label: "تكلفة التصميم المعماري:",
      type: "number",
      step: "0.01",
    },
    {
      key: "legalCost",
      label: "تكلفة القوانين والإدارة:",
      type: "number",
      step: "0.01",
    },
    {
      key: "siteCoordinationCost",
      label: "تكلفة تنسيق الموقع:",
      type: "number",
      step: "0.01",
    },
  ];

  const adminTowerConfig = [
    {
      key: "landPurchasePricePerSqm",
      label: "سعر الشراء للمتر:",
      type: "number",
      step: "0.01",
    },
    {
      key: "sellingPricePerSqm",
      label: "سعر البيع للمتر:",
      type: "number",
      step: "0.01",
    },
    // {
    //   key: "specialSellingPrice",
    //   label: "سعر البيع للمتر المميز:",
    //   type: "number",
    //   step: "0.01",
    // },
    {
      key: "constructionCostPerSqm",
      label: "تكلفة البناء للمتر:",
      type: "number",
      step: "0.01",
    },
    {
      key: "floorsCount",
      label: "عدد الأدوار:",
      type: "number",
      step: "1",
    },
    {
      key: "earthArea",
      label: "مساحة الأرض:",
      type: "number",
      step: "0.01",
    },
    {
      key: "basePropertySize",
      label: "نطاق مساحة الوحدات:",
      type: "number",
      step: "0.01",
    },
    {
      key: "propertySizesFrom",
      label: "مساحة الوحدة من:",
      type: "number",
      step: "0.01",
    },
    {
      key: "propertySizesTo",
      label: "مساحة الوحدة إلى:",
      type: "number",
      step: "0.01",
    },
    {
      key: "groundFloorBuildRatio",
      label: "نسبة البناء للدور الأرضي:",
      type: "number",
      step: "0.01",
    },
    {
      key: "repeatedFloorsRatio",
      label: "نسبة البناء للأدوار المتكررة:",
      type: "number",
      step: "0.01",
    },
    {
      key: "roofAnnexRatio",
      label: "نسبة البناء للملحق العلوي:",
      type: "number",
      step: "0.01",
    },
    {
      key: "landscapeCostPerSquareMeter",
      label: "تكلفة اللاندسكيب لكل متر:",
      type: "number",
      step: "0.01",
    },
    {
      key: "designCost",
      label: "تكلفة التصميم المعماري:",
      type: "number",
      step: "0.01",
    },
    {
      key: "legalCost",
      label: "تكلفة القوانين والإدارة:",
      type: "number",
      step: "0.01",
    },
    {
      key: "siteCoordinationCost",
      label: "تكلفة تنسيق الموقع:",
      type: "number",
      step: "0.01",
    },
  ];

  const VillaConfig = [
    {
      key: "landPurchasePricePerSqm",
      label: "سعر الشراء للمتر:",
      type: "number",
      step: "0.01",
    },
    {
      key: "sellingPricePerSqm",
      label: "سعر البيع للمتر:",
      type: "number",
      step: "0.01",
    },
    // {
    //   key: "specialSellingPrice",
    //   label: "سعر البيع للمتر المميز:",
    //   type: "number",
    //   step: "0.01",
    // },
    {
      key: "constructionCostPerSqm",
      label: "تكلفة البناء للمتر:",
      type: "number",
      step: "0.01",
    },
    {
      key: "floorsCount",
      label: "عدد الأدوار:",
      type: "number",
      step: "1",
    },
    {
      key: "earthArea",
      label: "مساحة الأرض:",
      type: "number",
      step: "0.01",
    },
    {
      key: "groundFloorBuildRatio",
      label: "نسبة البناء للدور الأرضي:",
      type: "number",
      step: "0.01",
    },
    {
      key: "repeatedFloorsRatio",
      label: "نسبة البناء للأدوار المتكررة:",
      type: "number",
      step: "0.01",
    },
    {
      key: "roofAnnexRatio",
      label: "نسبة البناء للملحق العلوي:",
      type: "number",
      step: "0.01",
    },
    {
      key: "landscapeCostPerSquareMeter",
      label: "تكلفة اللاندسكيب لكل متر:",
      type: "number",
      step: "0.01",
    },
    {
      key: "designCost",
      label: "تكلفة التصميم المعماري:",
      type: "number",
      step: "0.01",
    },
    {
      key: "legalCost",
      label: "تكلفة القوانين والإدارة:",
      type: "number",
      step: "0.01",
    },
    {
      key: "siteCoordinationCost",
      label: "تكلفة تنسيق الموقع:",
      type: "number",
      step: "0.01",
    },
  ];

  const villasConfig = [
    {
      key: "landPurchasePricePerSqm",
      label: "سعر الشراء للمتر:",
      type: "number",
      step: "0.01",
    },
    {
      key: "sellingPricePerSqm",
      label: "سعر البيع للمتر:",
      type: "number",
      step: "0.01",
    },
    // {
    //   key: "specialSellingPrice",
    //   label: "سعر البيع للمتر المميز:",
    //   type: "number",
    //   step: "0.01",
    // },
    {
      key: "constructionCostPerSqm",
      label: "تكلفة البناء للمتر:",
      type: "number",
      step: "0.01",
    },
    {
      key: "floorsCount",
      label: "عدد الأدوار:",
      type: "number",
      step: "1",
    },
    {
      key: "landBuildRatio",
      label: "نسبة البناء للأرض:",
      type: "number",
      step: "0.01",
    },
    {
      key: "earthArea",
      label: "مساحة الأرض:",
      type: "number",
      step: "0.01",
    },
    {
      key: "basePropertySize",
      label: "نطاق مساحة الفيلا:",
      type: "number",
      step: "0.01",
    },
    {
      key: "propertySizesFrom",
      label: "مساحة الفيلا من:",
      type: "number",
      step: "0.01",
    },
    {
      key: "propertySizesTo",
      label: "مساحة الفيلا إلى:",
      type: "number",
      step: "0.01",
    },
    {
      key: "groundFloorBuildRatio",
      label: "نسبة البناء للدور الأرضي:",
      type: "number",
      step: "0.01",
    },
    {
      key: "repeatedFloorsRatio",
      label: "نسبة البناء للأدوار المتكررة:",
      type: "number",
      step: "0.01",
    },
    {
      key: "roofAnnexRatio",
      label: "نسبة البناء للملحق العلوي:",
      type: "number",
      step: "0.01",
    },
    {
      key: "landscapeCostPerSquareMeter",
      label: "تكلفة اللاندسكيب لكل متر:",
      type: "number",
      step: "0.01",
    },
    {
      key: "designCost",
      label: "تكلفة التصميم المعماري:",
      type: "number",
      step: "0.01",
    },
    {
      key: "legalCost",
      label: "تكلفة القوانين والإدارة:",
      type: "number",
      step: "0.01",
    },
    {
      key: "siteCoordinationCost",
      label: "تكلفة تنسيق الموقع:",
      type: "number",
      step: "0.01",
    },
  ];

  const resCompoundConfig = [
    {
      key: "landPurchasePricePerSqm",
      label: "سعر الشراء للمتر:",
      type: "number",
      step: "0.01",
    },
    {
      key: "sellingPricePerSqm",
      label: "سعر البيع للمتر:",
      type: "number",
      step: "0.01",
    },
    // {
    //   key: "specialSellingPrice",
    //   label: "سعر البيع للمتر المميز:",
    //   type: "number",
    //   step: "0.01",
    // },
    {
      key: "constructionCostPerSqm",
      label: "تكلفة البناء للمتر:",
      type: "number",
      step: "0.01",
    },
    {
      key: "floorsCount",
      label: "عدد الأدوار:",
      type: "number",
      step: "1",
    },
    {
      key: "landBuildRatio",
      label: "نسبة البناء للأرض:",
      type: "number",
      step: "0.01",
    },
    {
      key: "buildingArea",
      label: "مساحة العمارة:",
      type: "number",
      step: "0.01",
    },
    {
      key: "earthArea",
      label: "مساحة الأرض:",
      type: "number",
      step: "0.01",
    },
    {
      key: "basePropertySize",
      label: "نطاق مساحة الوحدات:",
      type: "number",
      step: "0.01",
    },
    {
      key: "propertySizesFrom",
      label: "مساحة الوحدة من:",
      type: "number",
      step: "0.01",
    },
    {
      key: "propertySizesTo",
      label: "مساحة الوحدة إلى:",
      type: "number",
      step: "0.01",
    },
    {
      key: "groundFloorBuildRatio",
      label: "نسبة البناء للدور الأرضي:",
      type: "number",
      step: "0.01",
    },
    {
      key: "repeatedFloorsRatio",
      label: "نسبة البناء للأدوار المتكررة:",
      type: "number",
      step: "0.01",
    },
    {
      key: "roofAnnexRatio",
      label: "نسبة البناء للملحق العلوي:",
      type: "number",
      step: "0.01",
    },
    {
      key: "landscapeCostPerSquareMeter",
      label: "تكلفة اللاندسكيب لكل متر:",
      type: "number",
      step: "0.01",
    },
    {
      key: "designCost",
      label: "تكلفة التصميم المعماري:",
      type: "number",
      step: "0.01",
    },
    {
      key: "legalCost",
      label: "تكلفة القوانين والإدارة:",
      type: "number",
      step: "0.01",
    },
    {
      key: "siteCoordinationCost",
      label: "تكلفة تنسيق الموقع:",
      type: "number",
      step: "0.01",
    },
  ];

  const commercialResidentialConfig = commercialAdminConfig.map((field) => {
    if (field.key === "secondBasePropertySize") {
      return {
        ...field,
        label: "نطاق حجم الوحدات السكنية:",
      };
    }
    if (field.key === "secondPropertySizeFrom") {
      return {
        ...field,
        label: "حجم الوحدة السكنية من:",
      };
    }
    if (field.key === "secondPropertySizeTo") {
      return {
        ...field,
        label: "حجم الوحدة السكنية إلى:",
      };
    }

    return { ...field };
  });

  const numericVariablesConfig = {
    "برج تجاري إداري": commercialAdminConfig,
    "برج إداري": adminTowerConfig,

    "مبنى سكني": adminTowerConfig,
    "مبنى تجاري": adminTowerConfig,
    "مول تجاري": adminTowerConfig,
    فلل: villasConfig,
    فيلا: VillaConfig,
    "مجمع سكني": resCompoundConfig,
    "مبنى إداري": adminTowerConfig,
    فندقى: adminTowerConfig,

    "برج تجاري سكني": residentialCommConfig,
    "مبنى تجاري سكني": residentialCommConfig,
  };
  const handleChange = (key, value) => {
    setEditingVars((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  /**
   * Enter numeric-editing mode
   */
  const handleEdit = () => {
    setIsEditing(true);
  };

  /**
   * Discard changes to numeric variables
   */
  const handleDiscard = () => {
    setEditingVars(jsonVariables || {}); // revert to original variables
    setIsEditing(false);
  };

  /**
   * Apply changes: re-run transformDataWithAllVariables
   */
  const handleApply = async () => {
    // Optional: show a loading popup
    popup.forced();
    popup.run(
      <div className="w-full h-full text-white flex justify-center items-center text-2xl">
        Applying changes...
      </div>
    );

    try {
      // Re-run calculations with updated numeric variables
      const { oldData, variables } = transformMinimalDataToOldJson(
        simplifiedJson,
        editingVars,
        projectType
      );
      // Update the displayed old-data
      console.log("rev data", oldData);
      oldData.EditingData = {
        simplifiedJson,
        editingVars,
        projectType: projectType
      }
      setDataObj(oldData);
      setEditingVars(variables);

      // Hide edit mode
      setIsEditing(false);

      // If you want to navigate or do other things with "oldData", do so here.
      // e.g. navigate("/convert", { state: { apiResponse: oldData, ... } });
      await StudyCasesApi.updateCase(auth, location?.state?.id, oldData);
    } catch (err) {
      console.error("Error applying numeric edits:", err);
    } finally {
      popup.close();
    }
  };

  // Optionally, fix the location string if missing some parts
  let propLocation = "";
  if (
    !dataObj["تقرير_تحليل_الاستثمار"]["تفاصيل_المشروع"]["الموقع"].includes(
      "رقم القطعة"
    ) ||
    !dataObj["تقرير_تحليل_الاستثمار"]["تفاصيل_المشروع"]["الموقع"].includes(
      "رقم المخطط"
    )
  ) {
    const preLocation = `رقم القطعة: "${blockProps?.blockId}"، رقم المخطط: "${blockProps?.landId}"`;
    const tempLocation =
      dataObj["تقرير_تحليل_الاستثمار"]["تفاصيل_المشروع"]["الموقع"];
    propLocation = tempLocation;
    dataObj["propLocation"] = propLocation;
    dataObj["تقرير_تحليل_الاستثمار"]["تفاصيل_المشروع"]["الموقع"] =
      preLocation + "، " + tempLocation;
  }

  // Prepare data for tables
  let developmentStandards = dataObj["تقرير_تحليل_الاستثمار"]["معايير_التطوير"];
  let table2 = dataObj["تقرير_تحليل_الاستثمار"]["تفاصيل_المشروع"];
  // let table3 = keepOnlyOneKey(
  //   dataObj["تقرير_تحليل_الاستثمار"]["توقعات_التمويل"],
  //   "الاستثمار_الكلي"
  // );

  let table3 = filterObjectByKeys(
    dataObj["تقرير_تحليل_الاستثمار"]["توقعات_التمويل"],
    [
      ["تكلفة_شراء_الأرض"],
      ["تكاليف_البناء"],
      ["توقعات_الإيرادات_من_البيع"],
      ["توقعات_الإيرادات_من_الإيجار"],
    ]
  );

  let table4 = filterObjectByKeys(
    dataObj["تقرير_تحليل_الاستثمار"]["توقعات_التمويل"]["تكاليف_البناء"],
    ["التكاليف_الإضافية"]
  );
  let table5 = filterObjectByKeys(
    dataObj["تقرير_تحليل_الاستثمار"]["توقعات_التمويل"]["تكلفة_شراء_الأرض"],
    []
  );
  let table6 = filterObjectByKeys(
    dataObj["تقرير_تحليل_الاستثمار"]["توقعات_التمويل"][
      "توقعات_الإيرادات_من_البيع"
    ],
    []
  );
  let table7 = filterObjectByKeys(
    dataObj["تقرير_تحليل_الاستثمار"]["توقعات_التمويل"][
      "توقعات_الإيرادات_من_الإيجار"
    ],
    []
  );
  let table8 = keepOnlyOneKey(
    dataObj["تقرير_تحليل_الاستثمار"]["توقعات_التمويل"],
    "نتيجة_الاستثمار_الكلي"
  );

  return (
    <>
      {/* Edit Controls */}
      {!redirect && (
        <div className="edit-controls flex justify-end mb-4">
          {!isEditing ? (
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded"
              onClick={handleEdit}
            >
              تعديل قيم المتغيرات
            </button>
          ) : (
            <div className="flex gap-2">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={handleDiscard}
              >
                نسيان
              </button>
              <button
                className="bg-green-500 text-white px-4 py-2 rounded"
                onClick={handleApply}
              >
                تأكيد
              </button>
            </div>
          )}
        </div>
      )}

      {/* Numeric-Variables Edit Form (shown only if isEditing) */}
      {isEditing && (
        <div className="border p-4 mb-4 rounded bg-gray-100">
          <h2 className="text-xl mb-2">Edit Numeric Variables</h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {numericVariablesConfig[projectType]?.map((variable) => (
              <div key={variable.key}>
                <label className="font-semibold">{variable.label}</label>
                <input
                  type={variable.type}
                  step={variable.step}
                  className="border p-1 w-full"
                  value={editingVars[variable.key] || ""}
                  onChange={(e) =>
                    handleChange(
                      variable.key,
                      variable.type === "number"
                        ? variable.step === "1"
                          ? parseInt(e.target.value, 10) || 0
                          : parseFloat(e.target.value) || 0
                        : e.target.value
                    )
                  }
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Now render the PDF & read-only tables */}
      <Pdf
        blockProps={blockProps}
        propLocation={propLocation}
        dataObj={dataObj}
        images={images}
        map={map}
        prompts={prompts}
        isGeneratingPdf={isGeneratingPdf}
        tables={
          <>
            <div className={isGeneratingPdf ? "element-to-print" : ""}>
              {RenderTable(
                table2,
                "تفاصيل عن المشروع",
                (e) => {}, // No individual updates in batch mode
                isGeneratingPdf,
                isEditing,
                pendingEdits,
                setPendingEdits
              )}
              {RenderTable(
                developmentStandards,
                "النسب والمعايير",
                (e) => {}, // No individual updates in batch mode
                isGeneratingPdf
              )}
            </div>
            <div className={isGeneratingPdf ? "element-to-print" : ""}>
              {RenderTable(
                table5,
                "تكلفة شراء الأرض",
                (e) => {}, // No individual updates in batch mode
                isGeneratingPdf
              )}
              {RenderTable(
                table4,
                "تكاليف البناء",
                (e) => {}, // No individual updates in batch mode
                isGeneratingPdf
              )}
              {RenderTable(
                table3,
                "توقعات الإستثمار",
                (e) => {}, // No individual updates in batch mode
                isGeneratingPdf
              )}
            </div>
            <div className={isGeneratingPdf ? "element-to-print" : ""}>
              {RenderTable(
                table6,
                "توقعات الإيرادات من البيع",
                (e) => {}, // No individual updates in batch mode
                isGeneratingPdf
              )}
              {RenderTable(
                table7,
                "توقعات الإيرادات من الإيجار",
                (e) => {}, // No individual updates in batch mode
                isGeneratingPdf
              )}
            </div>
          </>
        }
      />
    </>
  );
};

export default DownloadPage;

const convertToArabicNumbers = (num) => {
  return num;
};
const FinancialStudy = ({ dataObj }) => {
  const [allNums, setAllNums] = useState(extractAll(dataObj));

  // Example function to format numbers into Arabic numerals, modify as necessary

  // Determine the unit based on the label
  const determineUnit = (label) => {
    if (label.includes("مساحة")) return "متر مربع";
    if (label.includes("حجم")) return "متر مكعب";
    if (label.includes("الوحدات")) return " عدد الوحدات";
    // if (label.includes('نتيجة') || label.includes('نسبة')) return '%';
    if (label.includes("تكلفة") || label.includes("التكلفة"))
      return "ريال سعودي";
    return ""; // Default return if none of the conditions are met
  };

  return (
    <div className="financial-study-container">
      <div class="card-header">
        <h1 class="card-title">دراسة مختصرة | {dataObj["العنوان"]}</h1>
      </div>

      {/* Dynamic table for numbers */}
      <table style={{ direction: "rtl" }} className="financial-study-table">
        <tbody>
          {allNums.map((item) => (
            <tr key={item.label}>
              <td>{item.label.replace(/_/g, " ")}</td>
              <td>{convertToArabicNumbers(item.number)}</td>
              <td>{determineUnit(item.label)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="footer w-[200px]">
        <img
          src="https://res.cloudinary.com/dfbtwwa8p/image/upload/v1721790549/kotxkxycpfeeocxnirg2.png"
          alt="Logo"
          className="logo"
        />
        {/* <span>تكامل المتقدمة</span> */}
      </div>
    </div>
  );
};

// function DevelopmentStandards({ data }) {
//   // A function to determine if a value is an object that should be displayed as a table
const isObject = (value) => {
  return value && typeof value === "object" && !Array.isArray(value);
};

// Render tables recursively
const RenderTable = (
  obj,
  title = "",
  updateFunction = () => {},
  isGeneratingPdf
) => {
  const [editableValue, setEditableValue] = useState(null);

  const handleDoubleClick = (key, value) => {
    setEditableValue({ key, value });
  };

  const handleInputChange = (e) => {
    setEditableValue((prev) => ({ ...prev, value: e.target.value }));
  };

  const handleInputBlur = () => {
    if (editableValue && editableValue.value !== obj[editableValue.key]) {
      console.log("Updated value:", editableValue);
      updateFunction(editableValue);
    }
    setTimeout(() => {
      setEditableValue(null);
    }, 500);
  };

  // Function to extract equation and result separately while correctly handling decimals
  const extractEquationAndResult = (str) => {
    if (!str || typeof str !== "string") return { equation: str, result: null };

    if (str.includes("=")) {
      const parts = str.split("=");
      if (parts.length < 2) return { equation: str, result: null };

      const equationPart = parts.slice(0, -1).join("="); // Everything before the last "="
      const lastPart = parts[parts.length - 1].trim(); // The final part

      // Extract full decimal numbers (including floating points)
      const numbers = lastPart.match(/\d+(\.\d+)?/g);
      const splitedLastPart = lastPart.split(" ");
      if (numbers && numbers.length === 1 && splitedLastPart.length <= 2) {
        return { equation: equationPart, result: lastPart }; // Keep full decimal as result
      }
    } else {
      // If there's no "=", check if there's a single number
      const numbers = str.match(/\d+(\.\d+)?/g);
      const splittedString = str.split(" ");
      if (
        str.includes(numbers) &&
        str.includes("ريال") &&
        splittedString.length <= 3
      ) {
        return { equation: null, result: str };
      } else if (str.includes(numbers) && splittedString.length < 3) {
        return { equation: null, result: str };
      }
    }

    return { equation: str, result: null };
  };

  return (
    <div className="card-section avoid-break flex flex-col justify-center items-center">
      {title && <h2 className="intro">{formatKey(title)}</h2>}
      <div
        style={{
          width: isGeneratingPdf ? "100%" : "100%",
          fontSize: isGeneratingPdf ? "14px" : "20px",
        }}
        className="table-container"
      >
        <table style={{ direction: "rtl" }} className="table">
          <thead>
            <tr>
              <th>الوصف</th>
              <th>المعادلة</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(obj).map(([key, value]) => {
              if (isObject(value)) {
                // Recursive call for nested objects
                return RenderTable(
                  value,
                  formatKey(key),
                  updateFunction,
                  isGeneratingPdf
                );
              } else {
                const { equation, result } = extractEquationAndResult(value);

                // Updated highlight style for a more polished/commercial look:
                const highlightStyle = result
                  ? {
                      backgroundColor: "#BEBEFF", // Lighter shade of #9D9DFF
                      color: "#3D3D8F", // Slightly darker text for contrast
                      padding: "6px 10px",
                      border: "2px solid #3D3D8F", // Keeping original #9D9DFF as border
                      borderRadius: "6px",
                      fontWeight: "bold",
                      display: "inline-block",
                      marginLeft: "6px",
                      textAlign: "center",
                      minWidth: "50px",
                    }
                  : {};

                return (
                  <tr key={key}>
                    <td>{formatKey(key)}</td>
                    <td>
                      {/* Equation part (before '=') */}
                      {equation && formatKey(convertToArabicNumbers(equation))}

                      {/* If both equation and result exist, show '=' symbol */}
                      {equation && result && (
                        <span>
                          <span style={{ margin: "0 4px" }}>=</span>
                        </span>
                      )}

                      {/* Result part with highlight style */}
                      {result && (
                        <span style={highlightStyle}>
                          {formatKey(convertToArabicNumbers(result))}
                        </span>
                      )}
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

//   // Helper function to format keys

//   return renderTable(data);
// }

const formatKey = (key) => {
  return key
    .split("_")
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
    .join(" ");
};

// Helper function to translate expressions into more readable format
const formatValue = (value) => {
  if (typeof value === "string") {
    return value
      .replace(/=/g, " = ")
      .replace(/\*/g, " × ")
      .replace(/\//g, " ÷ ");
  }
  return value;
};

function filterObjectByKeys(obj, keysToRemove) {
  const filteredObject = { ...obj };
  keysToRemove.forEach((key) => {
    delete filteredObject[key];
  });
  return filteredObject;
}

function keepOnlyOneKey(obj, keyToKeep) {
  return obj.hasOwnProperty(keyToKeep) ? { [keyToKeep]: obj[keyToKeep] } : {};
}

function extractAll(data, result = []) {
  for (const key in data) {
    if (typeof data[key] === "object") {
      extractAll(data[key], result);
    } else if (typeof data[key] === "string") {
      const matches = data[key].match(/\d+(\.\d+)?/g); // Regex to match integers and decimals
      if (matches) {
        matches.forEach((match) => {
          result.push({ label: key, number: parseFloat(match) });
        });
      }
    }
  }
  return result;
}
