export const HandleBigNumbers = (num) => {
    if (num === undefined || num === null) {
        return { scale: 1, number: 0, abbreviationEn: "", abbreviationAr: "" };
      }

    const Len = num.toString().length; // Length of the number

    // Determine the abbreviated number based on length
    const result =
      Len < 7 && Len >= 4
        ? Math.round(num / 10 ** 3) // Convert to K
        : Len < 10 && Len >= 7
          ? Math.round(num / 10 ** 6) // Convert to M
          : Len < 13 && Len >= 10
            ? Math.round(num / 10 ** 9) // Convert to B
            : Len < 16 && Len >= 13
              ? Math.round(num / 10 ** 12) // Convert to T
              : Len < 19 && Len >= 16
                ? Math.round(num / 10 ** 15) // Convert to Q (Quadrillion)
                : num; // For values larger than Quadrillion (1e18)

    // Handle the scaling and abbreviations for different ranges
    if (Len < 4) {
        return { scale: 1, number: num, abbreviationEn: "", abbreviationAr: "" };
      }else if (Len < 7) {
      return {
        scale: 1 - Len / 10,
        number: result,
        abbreviationEn: "K",
        abbreviationAr: "ألف",
      };
    } else if (Len < 10) {
      return {
        scale: 1 - Len / 14,
        number: result,
        abbreviationEn: "M",
        abbreviationAr: "مليون",
      };
    } else if (Len < 13) {
      return {
        scale: 1 - Len / 20,
        number: result,
        abbreviationEn: "B",
        abbreviationAr: "مليار",
      };
    } else if (Len < 16) {
      return {
        scale: 1 - Len / 25,
        number: result,
        abbreviationEn: "T",
        abbreviationAr: "تريليون",
      };
    } else if (Len < 19) {
      return {
        scale: 1 - Len / 25,
        number: result,
        abbreviationEn: "Q",
        abbreviationAr: "كوادريليون",
      };
    } else if (Len >= 19) {
      return { scale: 0.5, number: result };
    }
  };