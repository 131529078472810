/*************************************************
 *  advancedPrompt.js (the "advanced approach" with type-specific Saudi styles)
 *************************************************/

// Global Saudi styles (fallback if no type-specific style exists)
const saudiStyles = {
  modern: [
    "corporate office building with clean lines and glass facades, featuring distinct floor plates",
    "modern commercial building with traditional Islamic elements and clearly defined floor levels",
    "modern business center with Arabian architectural details and visible floor divisions",
  ],
  traditional: [
    "traditional Najdi style commercial building with clearly marked floor levels",
    "classic Saudi office building with courtyards and distinct floor separations",
    "traditional commercial center with mashrabiya elements and defined story heights",
    "heritage-inspired business building with visible floor plates",
  ],
  mixed: [
    "contemporary building with traditional Saudi elements and pronounced floor divisions",
    "modern commercial structure with Islamic architectural features and clear story separation",
    "business center combining traditional and modern design with distinct floor levels",
    "office building with heritage-inspired modern architecture and visible floor plates",
  ],
};

// Type-specific style arrays.
// Add or refine these arrays as needed for your project.
const typeStyles = {
  سكني: {
    // Residential
    modern: [
      "modern residential building with a minimalist facade and gentle curves",
      "sleek, modern apartment building with large windows and contemporary design",
      "urban modern residential building with clean lines and an inviting atmosphere",
    ],
    traditional: [
      "traditional residential building with local architectural elements and classic detailing",
      "heritage residential design featuring intricate patterns and warm earthy tones",
    ],
    mixed: [
      "residential building blending modern minimalism with traditional motifs",
    ],
  },
  تجاري: {
    // Commercial
    modern: [
      "modern commercial office building with glass facades and dynamic angles",
      "sleek commercial building with a futuristic design and innovative materials",
    ],
    traditional: [
      "commercial building with traditional Saudi architectural elements and ornate detailing",
    ],
    mixed: [
      "commercial building combining modern minimalism with traditional influences",
    ],
  },
  "تجاري سكني": {
    // Mixed-use
    modern: [
      "contemporary mixed-use building with a modern design and clear floor demarcations",
      "modern mixed-use structure featuring sleek facades that balance office and residential elements",
    ],
    traditional: [
      "mixed-use building with traditional details for residential spaces and classic commercial facades",
    ],
    mixed: [
      "building that harmoniously blends modern commercial design with traditional residential elements",
    ],
  },
  "مول تجاري": {
    // Shopping Mall
    modern: [
      "modern shopping mall with expansive glass facades and a dynamic layout",
      "contemporary mall design featuring spacious retail zones and sleek modern lines",
    ],
    traditional: [
      "shopping mall that incorporates traditional design elements with cultural references",
    ],
    mixed: [
      "mall with a blend of modern and traditional design cues, offering both innovation and heritage",
    ],
  },
  فلل: {
    // Villas
    modern: [
      "modern villa complex with minimalist design and luxurious open spaces",
    ],
    traditional: [
      "villas with traditional Saudi architectural details, emphasizing elegance and heritage",
    ],
    mixed: [
      "villa community that combines modern amenities with traditional design charm",
    ],
  },
  إداري: {
    // Administrative
    modern: [
      "modern administrative building with a clean, professional design and clear facades",
    ],
    traditional: [
      "administrative building with classic detailing and traditional Saudi architectural cues",
    ],
    mixed: [
      "government or administrative building that blends modern efficiency with traditional formality",
    ],
  },
  فندقي: {
    // Hotel
    modern: [
      "modern hotel with sleek lines, contemporary decor, and a luxurious ambiance",
    ],
    traditional: [
      "hotel design that incorporates traditional Saudi decorative elements and warm materials",
    ],
    mixed: [
      "hotel that marries modern luxury with traditional cultural accents",
    ],
  },
  أخرى: {
    // Other / Custom – you can also adjust for custom types if needed.
    modern: ["modern building with a unique, innovative design"],
    traditional: [
      "building featuring traditional design elements and cultural heritage",
    ],
    mixed: [
      "a uniquely styled building that combines modern and traditional influences",
    ],
  },
};

/**
 * Returns a random Saudi style description based on the style preference
 * and (optionally) building type.
 *
 * @param {string} stylePreference - "modern", "traditional", or "mixed"
 * @param {string} [type] - The building type (e.g., "سكني", "تجاري", etc.)
 * @returns {string} A style description string.
 */
function getSaudiStyle(stylePreference, type = "") {
  const preferenceKey = stylePreference.toLowerCase();
  const typeKey = type.trim();

  // If a type is provided and we have type-specific styles for that type and preference, use them.
  if (typeKey && typeStyles[typeKey] && typeStyles[typeKey][preferenceKey]) {
    const arr = typeStyles[typeKey][preferenceKey];
    return arr[Math.floor(Math.random() * arr.length)];
  }

  // Fallback to the global saudiStyles mapping.
  if (saudiStyles[preferenceKey]) {
    return saudiStyles[preferenceKey][
      Math.floor(Math.random() * saudiStyles[preferenceKey].length)
    ];
  }
  // Fallback to modern if no match.
  return saudiStyles.modern[
    Math.floor(Math.random() * saudiStyles.modern.length)
  ];
}

/**
 * Generates an architectural building prompt that includes type-specific language.
 *
 * @param {string} style - The design style ("modern", "traditional", or "mixed").
 * @param {number} levels - Total number of floors.
 * @param {string} [type] - Building type (e.g. "سكني", "تجاري", etc.).
 * @returns {string} The complete advanced prompt.
 */
function generateBuildingPrompt(style, levels, type = "") {
  // Grab a Saudi style that also matches the building type (if provided)
  const saudiStyle = getSaudiStyle(style, type);

  // Arrays for randomization.
  const viewingAngles = [
    "three-quarter view from the left",
    "three-quarter view from the right",
    "straight-on facade view",
    "slight diagonal view",
    "corner perspective",
  ];
  const architecturalFeatures = [
    "with geometric patterns on the facade",
    "with vertical ribbing elements",
    "with horizontal banding details",
    "with minimalist clean lines",
    "with traditional Islamic architectural motifs",
    "with contemporary glass curtain walls",
    "with decorative stone cladding",
    "with modernist concrete elements",
    "with integrated vertical gardens",
    "with metal and glass combination",
  ];
  const timesOfDay = [
    "during bright morning sunlight",
    "during golden afternoon hours",
    "during clear midday sun",
    "during early morning hours",
    "during late afternoon",
  ];
  const weatherConditions = [
    "with crystal clear blue skies",
    "with scattered light clouds",
    "with completely cloudless sky",
    "with pristine desert sky",
    "with light atmospheric haze",
  ];

  // Random picks.
  const randomAngle =
    viewingAngles[Math.floor(Math.random() * viewingAngles.length)];
  const randomFeature =
    architecturalFeatures[
      Math.floor(Math.random() * architecturalFeatures.length)
    ];
  const randomTime = timesOfDay[Math.floor(Math.random() * timesOfDay.length)];
  const randomWeather =
    weatherConditions[Math.floor(Math.random() * weatherConditions.length)];

  // A random seed for extra uniqueness.
  const uniqueSeed = Math.floor(Math.random() * 10000);

  // Determine extra type-specific context.
  let typeContext = "";
  if (type && typeof type === "string") {
    switch (type.trim()) {
      case "سكني": // Residential
        typeContext =
          "designed for residential use, emphasizing a warm and inviting atmosphere.";
        break;
      case "تجاري": // Commercial
        typeContext =
          "crafted for commercial purposes, emphasizing modernity and functionality.";
        break;
      case "تجاري سكني": // Mixed-use
        typeContext =
          "blending both commercial efficiency and residential comfort.";
        break;
      case "مول تجاري": // Shopping Mall
        typeContext =
          "designed as a spacious shopping mall with abundant retail space.";
        break;
      case "فلل": // Villas
        typeContext = "envisioned as a gated community of luxurious villas.";
        break;
      case "إداري": // Administrative
        typeContext =
          "constructed as a formal administrative or government building.";
        break;
      case "فندقي": // Hotel
        typeContext = "designed as a luxurious hotel with premium amenities.";
        break;
      case "أخرى": // Other / Custom
        typeContext =
          "a unique property with a distinctive architectural style.";
        break;
      default:
        typeContext = "";
    }
  }

  // Construct the “core” building description.
  // We insert the typeContext (if any) after the random feature for clarity.
  const buildingCore = `${levels}-story ${saudiStyle} ${randomFeature} ${typeContext}`;

  // Strict floor count language.
  const floorRequirements = [
    `The building MUST have EXACTLY ${levels} floors - no more, no less.`,
    `Each floor MUST be clearly visible, countable, and distinct.`,
    `The building MUST show ${levels} separate floors with uniform heights.`,
    `The facade MUST clearly display ${levels} individual stories.`,
    `Every single floor from 1 to ${levels} MUST be visible and countable.`,
  ].join(" ");

  // Architectural specs.
  const architecturalGuidance = [
    "Each floor MUST have:",
    `- Clear horizontal lines marking floor divisions`,
    `- Standard commercial floor-to-floor height`,
    `- Visible windows or architectural elements that define the floor`,
    `- No ambiguous spaces between floors`,
    `- No mechanical floors or penthouses that could be counted as additional floors`,
    `The building envelope MUST clearly demonstrate ${levels} distinct levels.`,
    `The building MUST not have an excessive number of windows or over-complicated facades.`,
  ].join(" ");

  // Viewing specs.
  const viewingSpecifications = [
    `Show the COMPLETE building from ${randomAngle}`,
    `Ensure ALL ${levels} floors are fully visible`,
    `Maintain perfect clarity and visibility of all floor divisions`,
    `Photograph must capture the entire height without any cropping`,
  ].join(" ");

  // Negative “do not include” specs.
  const negativeRequirements = [
    `DO NOT include:`,
    `- Mechanical penthouses that look like additional floors`,
    `- Ambiguous floor divisions`,
    `- Partial or hidden floors`,
    `- Basement levels`,
    `- Split levels or mezzanines`,
    `- Rooftop structures that could be mistaken for floors`,
    `- Buildings with too many windows or complex facades that obscure clear floor divisions`,
    `- Unclear or low-quality images`,
  ].join(" ");

  // Context / environment.
  const environment = [
    `Captured ${randomTime}`,
    randomWeather,
    "Professional architectural photography perspective",
    "Located in Riyadh business district",
    "No surrounding buildings blocking the view",
  ].join(" ");

  return `Ultra-realistic architectural photograph of a ${buildingCore}.

STRICT FLOOR COUNT REQUIREMENTS:
${floorRequirements}

ARCHITECTURAL SPECIFICATIONS:
${architecturalGuidance}

VIEWING REQUIREMENTS:
${viewingSpecifications}

RESTRICTIONS:
${negativeRequirements}

ENVIRONMENT:
${environment}

CRITICAL: This MUST be a ${levels}-floor building with ${levels} clearly countable, distinct floors.
Each floor MUST be clearly visible and countable in the final image.
Unique generation seed: ${uniqueSeed}`;
}

module.exports = {
  generateBuildingPrompt,
};
