import React, { useEffect, useState } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

const parcels = [
  {
    id: 19485292,
    lat: 21.80309738935428,
    lng: 39.0368650210888,
    measuredarea: "3675.82436053",
    zoning_en: "Residential",
    neighbourhood: "Alzmrd",
    city: "Jeddah",
  },
  {
    id: 19485294,
    lat: 21.80385836755791,
    lng: 39.03556140909391,
    measuredarea: "2402.97437037",
    zoning_en: "Residential",
    neighbourhood: "Alzmrd",
    city: "Jeddah",
  },
  {
    id: 19485295,
    lat: 21.803140078947532,
    lng: 39.034949237960525,
    measuredarea: "3723.15922436",
    zoning_en: "Residential",
    neighbourhood: "Unknown",
    city: "Unknown",
  },
  {
    id: 19485300,
    lat: 21.314895333792414,
    lng: 39.11939277312078,
    measuredarea: "743.80267361",
    zoning_en: "Residential",
    neighbourhood: "Alsahl",
    city: "Jeddah",
  },
];

const LeafletMap = () => {
  useEffect(() => {
    // Initialize the map
    const map = L.map("map").setView([21.803, 39.036], 12);

    // Add a tile layer (OpenStreetMap)
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);

    // Add markers for parcels
    parcels.forEach((parcel) => {
      if (parcel.lat && parcel.lng) {
        const marker = L.marker([parcel.lat, parcel.lng]).addTo(map);
        marker.bindPopup(
          `<b>Parcel ID:</b> ${parcel.id}<br>
          <b>Area:</b> ${parcel.measuredarea} m²<br>
          <b>Zoning:</b> ${parcel.zoning_en}<br>
          <b>City:</b> ${parcel.city || "Unknown"}<br>
          <b>Neighbourhood:</b> ${parcel.neighbourhood || "Unknown"}`
        );
      }
    });

    // Cleanup on unmount
    return () => {
      map.remove();
    };
  }, []);

  return <div id="map" style={{ height: "500px", width: "100%" }}></div>;
};

export default LeafletMap;

// App.js
// import React, { useEffect, useState } from "react";

function Iframe() {
  const [loading, setLoading] = useState(true);
  const [iframeSrc, setIframeSrc] = useState("");

  // Fetch the token and set the iframe source
  const fetchTokenAndSetIframeSrc = async () => {
    const apiUrl = "https://paseetah.com";
    try {
      const response = await fetch(`${apiUrl}/api/token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          email: "pm@takamoladvanced.sa",
          password: "z0ZufgK7VaqP",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch token");
      }

      const data = await response.json();
      const token = data.token;
      setIframeSrc(`${apiUrl}/map?token=${token}`);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching token:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTokenAndSetIframeSrc();

    // Listen for token refresh requests
    const handleMessage = (event) => {
      const allowedOrigin = "https://paseetah.com";
      if (
        event.origin === allowedOrigin &&
        event.data?.action === "requestAuthToken"
      ) {
        fetchTokenAndSetIframeSrc();
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  return (
    <div style={{ fontFamily: "Arial, sans-serif", textAlign: "center" }}>
      {loading ? (
        <div style={{ padding: "20px", fontSize: "18px" }}>Loading...</div>
      ) : (
        <iframe
          id="our-iframe"
          style={{ width: "100vw", height: "100vh", border: "none" }}
          src={iframeSrc}
          title="Embedded Content"
          sandbox="allow-scripts allow-same-origin"
          allow="fullscreen"
        ></iframe>
      )}
    </div>
  );
}

export { Iframe };
