/* eslint-disable react/prop-types */
import { createContext, useContext, useEffect, useState } from "react";

export const MapContext = createContext();

export const useMapContext = () => {
  return useContext(MapContext);
};

export const MapProvider = ({ children }) => {
  const [map, setMap] = useState();
  const [searchData, setSearchData] = useState();
  const [update, setUpdate] = useState(false);
  const [selectedPositionData, setSelectedPositionData] = useState();
  const [mapPhotoData, setMapPhotoData] = useState();
  const [photo, setPhoto] = useState();
  const [items, setItems] = useState([]);
  const [newItems, setNewItems] = useState([]);
  const [removeItem, setRemoveItem] = useState();
  const [loadingData, setLoadingData] = useState(false);
  const updateSearch = () => {
    console.log(update, "update state");
    setUpdate(!update);
  };
  return (
    <MapContext.Provider
      value={{
        searchData,
        setMap,
        map,
        setSearchData,
        updateSearch,
        mapPhotoData,
        setMapPhotoData,
        photo,
        setPhoto,
        update,
        setSelectedPositionData,
        selectedPositionData,
        items,
        setItems,
        removeItem,
        setRemoveItem,
        loadingData,
        setLoadingData,
        newItems, 
        setNewItems
      }}
    >
      {children}
    </MapContext.Provider>
  );
};
