import { redirect, useNavigate, useParams } from "react-router-dom";
import StudyCasesApi from "../apis/StudyCases";
import { useAuth } from "../Hooks/authContext";
import { useEffect } from "react";

export const Redirect = () => {
  const { auth } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    StudyCasesApi.getCase(auth, id).then((res) => {
      let data = res.data.studyCase;
      navigate("/convert", {
        state: {
          redirect: true,
          apiResponse: data.data,
          id: data._id,
          mapUrl: data.mapImage,
          images: data.images,
          blockProps: {
            blockId: "",
            landId: "",
          },
        },
      });
    });
  }, []);
  return <div>Redirecting...</div>;
};
