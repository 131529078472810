/***************************************************
 *  getImagePrompt.js (unifying your switch-case
 *  + the advanced approach)
 ***************************************************/
const {
  generateBuildingPrompt,
} = require("../UtilityFunctions/advancedPrompt");

/**
 * Generate a final prompt that merges:
 * 1) Basic building context based on `type`, `city`, `landArea`, etc.
 * 2) The advanced floor-count and style logic from `generateBuildingPrompt()`.
 *
 * @param {Object} args
 * @param {string} args.type - e.g. "سكني", "تجاري", ...
 * @param {number} args.floors
 * @param {number} [args.apartmentsPerFloor]
 * @param {number} [args.landArea]
 * @param {string} [args.city]
 * @param {string} [args.neighborhood]
 * @param {string} [args.propertyType]
 * @param {string} [args.customPropertyType]
 * @param {string} [args.stylePreference] - "modern", "traditional", or "mixed"
 * @returns {string} The merged prompt
 */
function getImagePrompt({
  type,
  floors,
  apartmentsPerFloor,
  landArea,
  city,
  neighborhood,
  propertyType,
  customPropertyType,
  stylePreference = "modern",
}) {
  // We'll call the advanced approach to handle floor clarity and style:
  const advancedCorePrompt = generateBuildingPrompt(
    stylePreference,
    floors,
    type
  );

  console.log("advanced Prompt: ", advancedCorePrompt);

  console.log("type: ", type);

  // Basic building context or extra descriptive text:
  let extraDescription = "";

  switch (type) {
    case "سكني": // Residential
      extraDescription = `Type: Residential (سكني)
City: ${city}
Land area: ${landArea} sqm
Description: This building is intended for residential use, with ${floors} floors. 
It may include a parking area, and we want it to integrate seamlessly in the urban environment.
If possible, show Al Riyadh Tower in the background to root it in ${city}.`;
      break;

    case "تجاري": // Commercial
      extraDescription = `Type: Commercial (تجاري)
City: ${city}
Land area: ${landArea} sqm
Description: This building is designed for commerce and offices, with ${floors} floors. 
Highlight a sleek, modern facade, possibly referencing local Saudi design. 
Showing Al Riyadh Tower in the background would help demonstrate the ${city} skyline.`;
      break;

    case "تجاري سكني": // Mixed-use
      extraDescription = `Type: Mixed-use (تجاري سكني)
City: ${city}
Land area: ${landArea} sqm
Description: The ground floor is for retail/commercial use, and the upper floors (for a total of ${floors}) are residential. 
Please reflect both functions in the final render, with clear floor demarcations.`;
      break;

    case "مول تجاري": // Shopping Mall
      extraDescription = `Type: Mall (مول تجاري)
City: ${city}
Land area: ${landArea} sqm
Description: A large, modern shopping mall with ${floors} floors. 
Emphasize open storefronts, glass facades, and robust parking. 
Al Riyadh Tower or local landmarks can anchor this in ${city}.`;
      break;

    case "فلل": // Villas
      extraDescription = `Type: Villas (فلل)
City: ${city}
Land area: ${landArea} sqm
Description: A gated community of luxury villas, each with ${floors} floors. 
Show green areas, some exclusivity, and city references. Possibly highlight a distant Al Riyadh Tower.`;
      break;

    case "إداري": // Administrative
      extraDescription = `Type: Administrative (إداري)
City: ${city}
Land area: ${landArea} sqm
Description: A formal administrative or government building with ${floors} floors. 
Should appear professional and modern, referencing local Saudi design cues.`;
      break;

    case "فندقي": // Hotel
      extraDescription = `Type: Hotel (فندقي)
City: ${city}
Land area: ${landArea} sqm
Description: A luxurious hotel with ${floors} floors. 
Include amenities like a pool or gardens if possible, with city background elements.`;
      break;

    case "أخرى": // Other / Custom
      extraDescription = `Type: ${customPropertyType} (أخرى)
City: ${city}
Land area: ${landArea} sqm
Description: A unique property type: ${customPropertyType}, with ${floors} floors. 
Emphasize any distinctive style or function, anchored within the local ${city} skyline.`;
      break;

    default:
      extraDescription = `Type: Unspecified
City: ${city}
Land area: ${landArea} sqm
Description: A generic building with ${floors} floors, placed in ${city}. 
Please still apply the advanced style and floor-count clarity rules.`;
      break;
  }

  // Merge the advanced (floor/style) prompt with the extra type-based context:
  // You can place the extra context before or after. Up to you!
  const finalPrompt = `${advancedCorePrompt}

ADDITIONAL CONTEXT (BASED ON BUILDING TYPE):
${extraDescription}

NOTE: Incorporate the above context without violating the strict floor-count requirements.`;

  return finalPrompt;
}

module.exports = { getImagePrompt };
